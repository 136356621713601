import React, { useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import EuroIcon from '@mui/icons-material/Euro'
import SportIcon from '@mui/icons-material/Sports'
import PersonIcon from '@mui/icons-material/Person'
import GroupsIcon from '@mui/icons-material/Groups'
import SettingsIcon from '@mui/icons-material/Settings'
import DateRangeIcon from '@mui/icons-material/DateRange'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import { styled } from '@mui/material/styles'

import { REACT_HOST } from '../hooks/env-variables'
import { colours } from '../settings/settings'
import { SET_IS_OPEN_DRAWER } from '../container/home/types'

const drawerWidth = 250

const openedMixin = theme => ({
  width: drawerWidth,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
})

const closedMixin = theme => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const MyDivider = styled(Divider)(() => ({
  background: colours.drawerText,
}))

const MyDrawer = ({
  auth,
  admin,
  societa,
  arbitro,
  designatore,
  isMobile,
  isOpenDrawer,
}) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const location = useLocation()

  useEffect(() => {
    if (REACT_HOST === 'localhost')
      dispatch({ type: SET_IS_OPEN_DRAWER, payload: false })
  }, [dispatch])

  const menuItems0 = [{ label: 'Home', link: '/', icon: <HomeIcon /> }]

  let menuItems1 = []

  if (auth) {
    if (admin)
      menuItems1 = [
        ...menuItems1,
        { label: 'Società', link: '/affiliazioni', icon: <Diversity3Icon /> },
      ]

    if (admin || societa)
      menuItems1 = [
        ...menuItems1,
        { label: 'Iscrizioni', link: '/iscrizioni', icon: 'ISCR' },
        { label: 'Spostamenti', link: '/spostamenti', icon: 'SPO' },
      ]

    if (admin)
      menuItems1 = [
        ...menuItems1,
        { label: 'Provvedimenti', link: '/provvedimenti', icon: 'PRO' },
      ]

    if (admin || societa)
      menuItems1 = [
        ...menuItems1,
        { label: 'Rendiconti', link: '/rendiconti', icon: 'REN' },
      ]

    if (arbitro)
      menuItems1 = [
        ...menuItems1,
        { label: 'Profilo', link: '/arbitro', icon: <PersonIcon /> },
      ]

    if (admin || designatore)
      menuItems1 = [
        ...menuItems1,
        { label: 'Arbitraggi', link: '/arbitraggi', icon: 'ARB' },
      ]

    if (admin || designatore)
      menuItems1 = [
        ...menuItems1,
        { label: 'Arbitri', link: '/arbitri', icon: <GroupsIcon /> },
      ]

    if (admin || arbitro || designatore)
      menuItems1 = [
        ...menuItems1,
        { label: 'Designazioni', link: '/designazioni', icon: <SportIcon /> },
      ]

    if (admin || arbitro)
      menuItems1 = [
        ...menuItems1,
        { label: 'Rimborsi', link: '/rimborsi', icon: <EuroIcon /> },
      ]
  }

  const menuItems2 = [
    {
      label: 'Squadre iscritte',
      link: '/squadre',
      icon: <SportsVolleyballIcon />,
    },
    { label: 'Gironi', link: '/gironi', icon: <CalendarViewMonthIcon /> },
    { label: 'Calendari', link: '/calendari', icon: <DateRangeIcon /> },
    { label: 'Classifiche', link: '/classifiche', icon: <LeaderboardIcon /> },
    {
      label: 'Fasi finali',
      link: '/finali',
      icon: <AccountTreeIcon style={{ transform: 'rotate(180deg)' }} />,
    },
  ]

  let menuItems3 = []
  if (admin)
    menuItems3 = [
      ...menuItems3,
      { label: 'Algoritmi', link: '/algoritmi', icon: <TipsAndUpdatesIcon /> },
      { label: 'Impostazioni', link: '/impostazioni', icon: <SettingsIcon /> },
    ]

  const MyListItem = ({ item }) => (
    <ListItem
      disablePadding
      sx={{
        display: 'block',
        backgroundColor:
          item.link?.split('/')?.[1] === location.pathname?.split('/')?.[1] &&
          colours.blueLight,
        ':hover': { backgroundColor: colours.blue },
      }}
      onClick={() => {
        if (!!item?.func) item.func()
        if (!!item?.link) history.push(item.link)
        if (isMobile) dispatch({ type: SET_IS_OPEN_DRAWER, payload: false })
      }}
    >
      <ListItemButton>
        <ListItemIcon sx={{ color: colours.drawerText }}>
          {item?.icon || <ChevronRightIcon />}
        </ListItemIcon>
        <ListItemText
          primary={item?.label}
          primaryTypographyProps={{
            color: colours.drawerText,
            fontWeight: 'bold',
          }}
        />
      </ListItemButton>
    </ListItem>
  )

  const DrawerContent = (
    <>
      <Toolbar />
      {menuItems0?.length > 0 && (
        <List>
          {menuItems0.map(i => (
            <MyListItem key={i.label} item={i} />
          ))}
        </List>
      )}
      {menuItems1?.length > 0 && (
        <>
          <MyDivider />
          <List>
            {menuItems1.map(i => (
              <MyListItem key={i.label} item={i} />
            ))}
          </List>
        </>
      )}
      {menuItems2?.length > 0 && (
        <>
          <MyDivider />
          <List>
            {menuItems2.map(i => (
              <MyListItem key={i.label} item={i} />
            ))}
          </List>
        </>
      )}
      {menuItems3?.length > 0 && (
        <>
          <MyDivider />
          <List>
            {menuItems3.map(i => (
              <MyListItem key={i.label} item={i} />
            ))}
          </List>
        </>
      )}
      <MyDivider />
    </>
  )

  return isMobile ? (
    <SwipeableDrawer
      open={isOpenDrawer}
      onOpen={() => dispatch({ type: SET_IS_OPEN_DRAWER, payload: true })}
      onClose={() => dispatch({ type: SET_IS_OPEN_DRAWER, payload: false })}
      PaperProps={{ sx: { backgroundColor: colours.blueDark } }}
    >
      {DrawerContent}
    </SwipeableDrawer>
  ) : (
    <StyledDrawer
      variant='permanent'
      open={isOpenDrawer}
      PaperProps={{ sx: { backgroundColor: colours.blueDark } }}
    >
      {DrawerContent}
    </StyledDrawer>
  )
}

const mapStateToProps = state => ({
  auth: state.home.auth,
  admin: state.home.admin,
  societa: state.home.societa,
  arbitro: state.home.arbitro,
  designatore: state.home.designatore,
  isMobile: state.home.isMobile,
  isOpenDrawer: state.home.isOpenDrawer,
})

const ConnectedMyDrawer = connect(mapStateToProps)(MyDrawer)

export default ConnectedMyDrawer
