import { combineReducers } from 'redux'

import { homeReducer } from '../container/home/reducer'
import { iscrizioniReducer } from '../container/iscrizioni/reducer'
import { affiliazioniReducer } from '../container/affiliazioni/reducer'
import { gironiReducer } from '../container/gironi/reducer'
import { calendariReducer } from '../container/calendari/reducer'
import { spostamentiReducer } from '../container/spostamenti/reducer'
import { risultatoReducer } from '../container/risultato/reducer'
import { arbitriReducer } from '../container/arbitri/reducer'
import { designazioneReducer } from '../container/designazione/reducer'
import { designazioniReducer } from '../container/designazioni/reducer'
import { provvedimentiReducer } from '../container/provvedimenti/reducer'
import { rendicontiReducer } from '../container/rendiconti/reducer'
import { rimborsiReducer } from '../container/rimborsi/reducer'
import { arbitraggiReducer } from '../container/arbitraggi/reducer'

export const rootReducers = combineReducers({
  home: homeReducer,
  iscrizioni: iscrizioniReducer,
  affiliazioni: affiliazioniReducer,
  gironi: gironiReducer,
  calendari: calendariReducer,
  spostamenti: spostamentiReducer,
  risultato: risultatoReducer,
  arbitri: arbitriReducer,
  designazione: designazioneReducer,
  designazioni: designazioniReducer,
  provvedimenti: provvedimentiReducer,
  rendiconti: rendicontiReducer,
  rimborsi: rimborsiReducer,
  arbitraggi: arbitraggiReducer,
})
