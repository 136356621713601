import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { sendRequest } from './http-hook'
import {
  RESET_HOME,
  SET_AUTH,
  SET_ADMIN,
  SET_SOCIETA,
  SET_ARBITRO,
  SET_DESIGNATORE,
  SET_OMOLOGATORE,
  SET_CODICE_UTENTE,
  SET_INFO_UTENTE,
  SET_ERROR,
  SET_IS_OPEN_INFO_SOCIETA,
} from '../container/home/types'

export const useAuth = auth => {
  const dispatch = useDispatch()

  const history = useHistory()

  const login = useCallback(
    async (codice, password) => {
      try {
        const authData = await sendRequest(
          `impostazioni/login`,
          'POST',
          JSON.stringify({ codice, password }),
          { 'Content-Type': 'application/json' }
        )

        if (!authData) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile eseguire l'autenticazione.",
          })
        } else {
          localStorage.setItem('codice', JSON.stringify(codice))
          localStorage.setItem('password', JSON.stringify(password))

          dispatch({ type: SET_AUTH, payload: true })
          dispatch({ type: SET_ADMIN, payload: !!authData.data.admin })
          dispatch({ type: SET_SOCIETA, payload: !!authData.data.societa })
          dispatch({ type: SET_ARBITRO, payload: !!authData.data.arbitro })
          dispatch({
            type: SET_DESIGNATORE,
            payload: !!authData.data.designatore,
          })
          dispatch({
            type: SET_OMOLOGATORE,
            payload: !!authData.data.omologatore,
          })
          dispatch({
            type: SET_CODICE_UTENTE,
            payload: authData.data.codiceUtente,
          })
          dispatch({ type: SET_INFO_UTENTE, payload: authData.data })
          dispatch({
            type: SET_IS_OPEN_INFO_SOCIETA,
            payload:
              authData.data.societa && authData.data.status === 'incomplete',
          })
        }
      } catch (err) {
        console.log(err)
        if (err?.status === 401) {
          localStorage.removeItem('codice')
          localStorage.removeItem('password')
        }
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    },
    [dispatch]
  )

  const logout = useCallback(() => {
    localStorage.removeItem('codice')
    localStorage.removeItem('password')

    dispatch({ type: RESET_HOME })

    history.push('/')
  }, [dispatch, history])

  // auto-login
  useEffect(() => {
    if (!auth) {
      try {
        const localCodice = JSON.parse(localStorage.getItem('codice'))
        const localPassword = JSON.parse(localStorage.getItem('password'))
        if (localCodice && localPassword) login(localCodice, localPassword)
      } catch {
        localStorage.removeItem('codice')
        localStorage.removeItem('password')
      }
    }
  }, [auth, login])

  return { login, logout }
}
