import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'

import DatePicker from './DatePicker'
import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { errorNaturale } from '../hooks/error-functions'
import { sortGiornate } from '../hooks/sort-functions'
import { fromDateToString, fromStringToDate } from '../hooks/utils-functions'
import {
  SET_DOMENICHE,
  SET_ERROR,
  SET_DUMMY_UPDATE,
} from '../container/home/types'

const columnsDomeniche = [
  { label: 'Giornata', sortable: false },
  { label: 'Domenica', sortable: false },
  { label: 'Settimana di gara', sortable: false },
  { label: 'Opzioni', sortable: false },
]

const DialogDomeniche = ({ codiceUtente, domeniche, dummyUpdate }) => {
  const dispatch = useDispatch()

  const { setFeedback } = useFeedback()

  // states to manage new zone addition/update
  const [editingDomenica, setEditingDomenica] = useState(false)
  const [addingDomenica, setAddingDomenica] = useState(false)
  const [newGiornata, setNewGiornata] = useState()
  const [newDomenica, setNewDomenica] = useState()

  const weekWindow = domenica => {
    if (!fromStringToDate(domenica)) return ''

    const domDate = fromStringToDate(domenica).getDate()
    const domShift = n =>
      fromDateToString(
        new Date(fromStringToDate(domenica).setDate(domDate + n))
      )
    return `dal ${domShift(-2)} al ${domShift(4)}`
  }

  // fetch domeniche
  useEffect(() => {
    const fetchDomeniche = async () => {
      try {
        const domenicheData = await sendRequest(`domeniche/all`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!domenicheData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le domeniche.',
          })
        } else {
          dispatch({
            type: SET_DOMENICHE,
            payload: domenicheData?.sort((a, b) =>
              sortGiornate(a.giornata, b.giornata)
            ),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    fetchDomeniche()
  }, [codiceUtente, dispatch, dummyUpdate])

  // add domenica
  const addNewDomenica = async () => {
    if (!!newGiornata) {
      try {
        await sendRequest(
          'domeniche/add',
          'POST',
          JSON.stringify({ giornata: newGiornata, domenica: newDomenica }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        clearNewDomenica()
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Giornata non trovata!')
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // clear domenica
  const clearNewDomenica = () => {
    setEditingDomenica(false)
    setAddingDomenica(false)
    setNewGiornata()
    setNewDomenica()
  }

  // edit domenica
  const editDomenica = async id => {
    if (!!id) {
      try {
        await sendRequest(
          `domeniche/${id}`,
          'PUT',
          JSON.stringify({ giornata: newGiornata, domenica: newDomenica }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        clearNewDomenica()
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Giornata non trovata!')
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // delete domenica
  const deleteDomenica = async id => {
    if (!!id) {
      try {
        await sendRequest(`domeniche/${id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      setFeedback('Giornata non trovata!')
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  return (
    <>
      &nbsp;
      {(!domeniche && <h4>Giornate non trovate.</h4>) || (
        <>
          <p style={{ paddingLeft: 20 }}>
            Per ogni giornata indicare la data in cui cade la domenica: verrà
            considerata come settimana di gara quella che va dal venerdì
            precedente al giovedì successivo.
          </p>
          &nbsp;
          <Grid container item xs={12} spacing={3} alignItems='center'>
            {(!!addingDomenica && (
              <>
                <Grid item xs={4} md={2}>
                  <center>
                    <InputField
                      id='giornata'
                      value={newGiornata}
                      onChange={setNewGiornata}
                      autoFocus={true}
                      label={columnsDomeniche[0].label}
                      errorFunc={errorNaturale}
                      type='number'
                    />
                  </center>
                </Grid>
                <Grid item xs={8} md={4}>
                  <center>
                    <DatePicker
                      value={newDomenica}
                      onChange={setNewDomenica}
                      label={columnsDomeniche[1].label}
                    />
                  </center>
                </Grid>
                <Grid item xs={8} md={4}>
                  <center>{weekWindow(newDomenica)}</center>
                </Grid>
                <Grid item xs={2} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.blueDark }}
                      onClick={() => addNewDomenica()}
                    >
                      <Tooltip key='Salva' title='Salva'>
                        <SaveIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
                <Grid item xs={2} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.red }}
                      onClick={() => clearNewDomenica()}
                    >
                      <Tooltip key='Annulla' title='Annulla'>
                        <ClearIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
              </>
            )) || (
              <Grid item xs={12}>
                <center>
                  <IconButton
                    style={{ color: colours.black }}
                    onClick={() => {
                      setEditingDomenica(false)
                      setAddingDomenica(true)
                      setNewGiornata()
                      setNewDomenica()
                    }}
                  >
                    <AddIcon />
                    &nbsp; Nuova giornata
                  </IconButton>
                </center>
              </Grid>
            )}
          </Grid>
          &nbsp;
          {(domeniche.length === 0 && <h4>Nessuna giornata trovata.</h4>) || (
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={domeniche}
                  setTable={t => dispatch({ type: SET_DOMENICHE, payload: t })}
                  columns={columnsDomeniche}
                />
                <TableBody>
                  {domeniche.map((g, index) => {
                    return (
                      <TableRow
                        key={index}
                        style={{ backgroundColor: colours.white }}
                      >
                        {(!!editingDomenica && newGiornata === g.giornata && (
                          <>
                            <TableCell align='center' padding='checkbox'>
                              {g.giornata}
                            </TableCell>
                            <TableCell align='center' padding='checkbox'>
                              <DatePicker
                                value={newDomenica}
                                onChange={setNewDomenica}
                                label={columnsDomeniche[1].label}
                              />
                            </TableCell>
                            <TableCell align='center' padding='checkbox'>
                              {weekWindow(newDomenica)}
                            </TableCell>
                            <TableCell align='center' padding='checkbox'>
                              <Grid container item>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.blueDark }}
                                    onClick={() => editDomenica(g.id)}
                                  >
                                    <Tooltip key='Salva' title='Salva'>
                                      <SaveIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.red }}
                                    onClick={() => clearNewDomenica()}
                                  >
                                    <Tooltip key='Annulla' title='Annulla'>
                                      <ClearIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </>
                        )) || (
                          <>
                            <TableCell align='center'>{g.giornata}</TableCell>
                            <TableCell align='center'>{g.domenica}</TableCell>
                            <TableCell align='center'>
                              {weekWindow(g.domenica)}
                            </TableCell>
                            <TableCell align='center'>
                              <Grid container item>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.blueDark }}
                                    onClick={() => {
                                      setEditingDomenica(true)
                                      setAddingDomenica(false)
                                      setNewGiornata(g.giornata)
                                      setNewDomenica(g.domenica)
                                    }}
                                  >
                                    <Tooltip key='Modifica' title='Modifica'>
                                      <EditIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.red }}
                                    onClick={() => deleteDomenica(g.id)}
                                  >
                                    <Tooltip key='Elimina' title='Elimina'>
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  domeniche: state.home.domeniche,
  dummyUpdate: state.home.dummyUpdate,
})

const ConnectedDialogDomeniche = connect(mapStateToProps)(DialogDomeniche)

export default ConnectedDialogDomeniche
