import {
  SET_RIMBORSI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_RIMBORSO_MODE,
  SET_RIMBORSO,
  SET_RIMBORSO_DATE_PAID,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
}

export const rimborsiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_RIMBORSI:
      return { ...state, rimborsi: action.payload }
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    case SET_RIMBORSO_MODE:
      return { ...state, rimborsoMode: action.payload }
    case SET_RIMBORSO:
      return { ...state, rimborso: action.payload }
    case SET_RIMBORSO_DATE_PAID:
      return { ...state, rimborsoDatePaid: action.payload }
    default:
      return state
  }
}
