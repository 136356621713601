import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'

import InputField from './InputField'
import ButtonGroup from './ButtonGroup'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { errorNaturale } from '../hooks/error-functions'
import {
  SET_TABELLONI,
  SET_ERROR,
  SET_DUMMY_UPDATE,
} from '../container/home/types'

const columnsTabelloni = [
  { label: 'Fase', sortable: false },
  { label: 'Sfida', sortable: false },
  { label: 'Sfida vincente', sortable: false },
  { label: 'Sfida perdente', sortable: false },
  { label: 'Opzioni', sortable: false },
]

const availableNumeroSquadre = [
  4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 48,
]

// error functions
const errorSfida = x => x && errorNaturale(x)

const DialogTabelloni = ({ codiceUtente, tabelloni, dummyUpdate }) => {
  const dispatch = useDispatch()

  const { setFeedback } = useFeedback()

  const [selectedNumeroSquadre, setSelectedNumeroSquadre] = useState()

  // states to manage new tabelloni addition/update
  const [editingTabellone, setEditingTabellone] = useState(false)
  const [addingTabellone, setAddingTabellone] = useState(false)
  const [newTabellone, setNewTabellone] = useState()
  const [newFase, setNewFase] = useState()
  const [newSfida, setNewSfida] = useState()
  const [newSfidaVincente, setNewSfidaVincente] = useState()
  const [newSfidaPerdente, setNewSfidaPerdente] = useState()

  useEffect(() => {
    if (!selectedNumeroSquadre)
      setSelectedNumeroSquadre(availableNumeroSquadre[0])
  }, [selectedNumeroSquadre])

  // fetch tabelloni
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest('tabelloni/all', 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i tabelloni.',
          })
        } else {
          dispatch({
            type: SET_TABELLONI,
            payload: data
              ?.filter(a => a.squadre === selectedNumeroSquadre)
              ?.sort((a, b) => +a.fase - +b.fase || +a.sfida - +b.sfida),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    fetch()
  }, [codiceUtente, dispatch, dummyUpdate, selectedNumeroSquadre])

  // add tabellone
  const addNewTabellone = async () => {
    if (!!selectedNumeroSquadre && !!newFase && !!newSfida) {
      try {
        await sendRequest(
          'tabelloni/add',
          'POST',
          JSON.stringify({
            squadre: selectedNumeroSquadre,
            fase: newFase,
            sfida: newSfida,
            sfidaVincente: newSfidaVincente ? newSfidaVincente : undefined,
            sfidaPerdente: newSfidaPerdente ? newSfidaPerdente : undefined,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
      } catch (err) {
        setFeedback(err.message)
      }
      clearNewTabellone()
    } else {
      setFeedback('Mancano delle informazioni!')
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // clear tabellone
  const clearNewTabellone = () => {
    setEditingTabellone(false)
    setAddingTabellone(false)
    setNewTabellone()
    setNewFase()
    setNewSfida()
    setNewSfidaVincente()
    setNewSfidaPerdente()
  }

  // edit tabellone
  const editTabellone = async id => {
    if (!!id) {
      try {
        await sendRequest(
          `tabelloni/${id}`,
          'POST',
          JSON.stringify({
            squadre: selectedNumeroSquadre,
            fase: newFase,
            sfida: newSfida,
            sfidaVincente: newSfidaVincente ? newSfidaVincente : undefined,
            sfidaPerdente: newSfidaPerdente ? newSfidaPerdente : undefined,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
      } catch (err) {
        setFeedback(err.message)
      }
      clearNewTabellone()
    } else {
      setFeedback('Tabellone non trovato!')
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // delete tabellone
  const deleteTabellone = async id => {
    if (!!id) {
      try {
        await sendRequest(`tabelloni/${id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Tabellone non trovato!')
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  return (
    <>
      &nbsp;
      {(!tabelloni && <h4>Tabelloni non trovati.</h4>) || (
        <>
          <p style={{ paddingLeft: 20 }}>
            <b>Sfida</b>: turno a eliminazione (partita secca o andata/ritorno).
            <br />
            <b>Fase</b>: insieme di sfide dello stesso turno.
            <br />
            <br />
            <b>Esempio</b>: in un tabellone a 8 squadre la fase 1 sono i quarti
            di finale (sfide 1-2-3-4), la fase 2 sono le semifinali (sfide 5-6)
            e la fase 3 sono le finali (sfide 7-8).
            <br />
            <br />
            Per ogni sfida indicare a quale sfida accedono la squadra vincente e
            la squadra perdente.
            <br />
            <br />
            <b>Tabelloni doppi</b>: è possibile creare anche due tabelloni
            separati nella stessa categoria (per esempio, uno OVEST e uno EST).
            Di fatto, è come creare un unico tabellone, con la differenza che le
            ultime due fasi avranno 4 sfide ciascuna invece che 2 (ci saranno
            infatti 2+2 semifinali e 2+2 finali).
          </p>
          &nbsp;
          <Grid container item xs={12} spacing={3} alignItems='center'>
            <Grid item xs={12} md={4}>
              <center>Numero di squadre</center>
            </Grid>
            <Grid item xs={12} md={8}>
              <center>
                <ButtonGroup
                  list={availableNumeroSquadre}
                  value={selectedNumeroSquadre}
                  func={setSelectedNumeroSquadre}
                />
              </center>
            </Grid>
          </Grid>
          &nbsp;
          <Grid container item xs={12} spacing={3} alignItems='center'>
            {(!!addingTabellone && (
              <>
                <Grid item xs={12} md={2}>
                  <center>
                    <InputField
                      id='fase'
                      value={newFase}
                      onChange={setNewFase}
                      autoFocus={true}
                      label={columnsTabelloni[0].label}
                      errorText='La fase deve essere un intero positivo!'
                      errorFunc={errorNaturale}
                      type='number'
                    />
                  </center>
                </Grid>
                <Grid item xs={12} md={2}>
                  <center>
                    <InputField
                      id='sfida'
                      value={newSfida}
                      onChange={setNewSfida}
                      label={columnsTabelloni[1].label}
                      errorText='La sfida deve essere un intero positivo!'
                      errorFunc={errorNaturale}
                      type='number'
                    />
                  </center>
                </Grid>
                <Grid item xs={12} md={3}>
                  <center>
                    <InputField
                      id='sfidaVincente'
                      value={newSfidaVincente}
                      onChange={setNewSfidaVincente}
                      label={columnsTabelloni[2].label}
                      errorText='La sfida vincente deve essere un intero positivo!'
                      errorFunc={errorSfida}
                      type='number'
                    />
                  </center>
                </Grid>
                <Grid item xs={12} md={3}>
                  <center>
                    <InputField
                      id='sfidaPerdente'
                      value={newSfidaPerdente}
                      onChange={setNewSfidaPerdente}
                      label={columnsTabelloni[3].label}
                      errorText='La sfida perdente deve essere un intero positivo!'
                      errorFunc={errorSfida}
                      type='number'
                    />
                  </center>
                </Grid>
                <Grid item xs={6} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.blueDark }}
                      onClick={addNewTabellone}
                    >
                      <Tooltip key='Salva' title='Salva'>
                        <SaveIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
                <Grid item xs={6} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.red }}
                      onClick={clearNewTabellone}
                    >
                      <Tooltip key='Annulla' title='Annulla'>
                        <ClearIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
              </>
            )) || (
              <Grid item xs={12}>
                <center>
                  <IconButton
                    style={{ color: colours.black }}
                    onClick={() => {
                      setEditingTabellone(false)
                      setAddingTabellone(true)
                      setNewTabellone()
                      setNewFase()
                      setNewSfida()
                      setNewSfidaVincente()
                      setNewSfidaPerdente()
                    }}
                  >
                    <AddIcon />
                    &nbsp; Nuova sfida
                  </IconButton>
                </center>
              </Grid>
            )}
          </Grid>
          &nbsp;
          {(tabelloni.length === 0 && <h4>Nessuna sfida trovata.</h4>) || (
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={tabelloni}
                  setTable={t => dispatch({ type: SET_TABELLONI, payload: t })}
                  columns={columnsTabelloni}
                />
                <TableBody>
                  {tabelloni.map((s, index) => {
                    const cellProps = {
                      align: 'center',
                      padding: 'checkbox',
                      style: {
                        borderBottomColor:
                          index < tabelloni.length - 1 &&
                          tabelloni[index].fase !== tabelloni[index + 1].fase &&
                          colours.greyVeryDark,
                      },
                    }

                    return (
                      <TableRow
                        key={index}
                        style={{ backgroundColor: colours.white }}
                      >
                        {(!!editingTabellone && newTabellone === s.id && (
                          <>
                            <TableCell {...cellProps}>
                              <InputField
                                id='fase'
                                value={newFase}
                                onChange={setNewFase}
                                autoFocus={true}
                                label={columnsTabelloni[0].label}
                                errorText='La fase deve essere un intero positivo!'
                                errorFunc={errorNaturale}
                                type='number'
                                mode='update'
                              />
                            </TableCell>
                            <TableCell {...cellProps}>
                              <InputField
                                id='sfida'
                                value={newSfida}
                                onChange={setNewSfida}
                                label={columnsTabelloni[1].label}
                                errorText='La sfida deve essere un intero positivo!'
                                errorFunc={errorNaturale}
                                type='number'
                                mode='update'
                              />
                            </TableCell>
                            <TableCell {...cellProps}>
                              <InputField
                                id='sfidaVincente'
                                value={newSfidaVincente}
                                onChange={setNewSfidaVincente}
                                label={columnsTabelloni[2].label}
                                errorText='La sfida vincente deve essere un intero positivo!'
                                errorFunc={errorSfida}
                                type='number'
                                mode='update'
                              />
                            </TableCell>
                            <TableCell {...cellProps}>
                              <InputField
                                id='sfidaPerdente'
                                value={newSfidaPerdente}
                                onChange={setNewSfidaPerdente}
                                label={columnsTabelloni[3].label}
                                errorText='La sfida perdente deve essere un intero positivo!'
                                errorFunc={errorSfida}
                                type='number'
                                mode='update'
                              />
                            </TableCell>
                            <TableCell {...cellProps}>
                              <Grid container item>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.blueDark }}
                                    onClick={() => editTabellone(s.id)}
                                  >
                                    <Tooltip key='Salva' title='Salva'>
                                      <SaveIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.red }}
                                    onClick={clearNewTabellone}
                                  >
                                    <Tooltip key='Annulla' title='Annulla'>
                                      <ClearIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </>
                        )) || (
                          <>
                            <TableCell {...cellProps}>{s.fase}</TableCell>
                            <TableCell {...cellProps}>{s.sfida}</TableCell>
                            <TableCell {...cellProps}>
                              {s.sfidaVincente}
                            </TableCell>
                            <TableCell {...cellProps}>
                              {s.sfidaPerdente}
                            </TableCell>
                            <TableCell {...cellProps}>
                              <Grid container item>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.blueDark }}
                                    onClick={() => {
                                      setEditingTabellone(true)
                                      setAddingTabellone(false)
                                      setNewTabellone(s.id)
                                      setNewFase(s.fase && +s.fase)
                                      setNewSfida(s.sfida && +s.sfida)
                                      setNewSfidaVincente(
                                        s.sfidaVincente && +s.sfidaVincente
                                      )
                                      setNewSfidaPerdente(
                                        s.sfidaPerdente && +s.sfidaPerdente
                                      )
                                    }}
                                  >
                                    <Tooltip key='Modifica' title='Modifica'>
                                      <EditIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.red }}
                                    onClick={() => deleteTabellone(s.id)}
                                  >
                                    <Tooltip key='Elimina' title='Elimina'>
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  tabelloni: state.home.tabelloni,
  dummyUpdate: state.home.dummyUpdate,
})

const ConnectedDialogTabelloni = connect(mapStateToProps)(DialogTabelloni)

export default ConnectedDialogTabelloni
