import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'

import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import GridCircularProgress from '../components/GridCircularProgress'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR } from '../container/home/types'
import { SET_CALENDARI } from '../container/calendari/types'
import {
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
} from '../container/arbitraggi/types'

const Arbitraggi = ({
  iscrizioniLight,
  loadingArbitraggi,
  availableCategorie,
  selectedCategoria,
  calendari,
  arbitraggi,
  sortingColumn,
  sortingAscending,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Richieste di arbitraggio'

  let columnsArbitraggi = [
    { label: 'Richiesto in data', sortable: true, sortingField: 'timeDomanda' },
    { label: 'Gara', sortable: true, sortingField: 'numeroGara' },
    { label: 'Categoria', sortable: true, sortingField: 'categoria' },
    { label: 'Squadra in casa', sortable: false },
    { label: 'Squadra in trasferta', sortable: false },
  ]

  const [filteredArbitraggi, setFilteredArbitraggi] = useState()

  // filter arbitraggi
  useEffect(() => {
    setFilteredArbitraggi(
      arbitraggi?.filter(
        s =>
          s.categoria === selectedCategoria ||
          selectedCategoria === availableCategorie[0] ||
          !selectedCategoria
      )
    )
  }, [arbitraggi, availableCategorie, selectedCategoria])

  // fetch calendari
  useEffect(() => {
    const fetchCalendari = async () => {
      try {
        const calendariData = await sendRequest(
          'calendari/many',
          'POST',
          JSON.stringify({ ids: arbitraggi?.map(a => a.idGara) }),
          { 'Content-Type': 'application/json' }
        )

        if (!calendariData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le partite.',
          })
        } else {
          dispatch({ type: SET_CALENDARI, payload: calendariData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (arbitraggi) {
      fetchCalendari()
    } else {
      dispatch({ type: SET_CALENDARI })
    }
  }, [arbitraggi, dispatch])

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Richieste di arbitraggio</h2>
        </center>
      </Grid>
      <SelectionBar includeGirone={false} includeTeam={false} />
      {(loadingArbitraggi && <GridCircularProgress />) ||
        (!filteredArbitraggi && (
          <Grid item xs={12}>
            <center>
              <h4>Elenco delle richieste di arbitraggio non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (filteredArbitraggi.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Nessuna richiesta di arbitraggio trovata.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={filteredArbitraggi}
                  setTable={setFilteredArbitraggi}
                  columns={columnsArbitraggi}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {filteredArbitraggi.map((s, index) => {
                    let [sData, sOra] = s.timeDomanda?.split('T')
                    sData = sData?.split('-')?.reverse().join('/')
                    sOra = sOra?.slice(0, 5)

                    const sGara = calendari?.find(c => c._id === s.idGara)
                    const sCasa = iscrizioniLight?.find(
                      i => i.id === sGara?.squadraCasaID
                    )?.nomeSquadra
                    const sTrasferta = iscrizioniLight?.find(
                      i => i.id === sGara?.squadraTrasfertaID
                    )?.nomeSquadra

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          ':hover': { backgroundColor: colours.greyVeryLight },
                        }}
                      >
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {sData}&nbsp;{sOra}
                        </TableCell>
                        <TableCell align='center'>{s.numeroGara}</TableCell>
                        <TableCell align='center'>{s.categoria}</TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {sCasa}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {sTrasferta}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
    </Grid>
  )
}

const mapStateToProps = state => ({
  iscrizioniLight: state.home.iscrizioniLight,
  loadingArbitraggi: state.home.loadingArbitraggi,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  calendari: state.calendari.calendari,
  arbitraggi: state.arbitraggi.arbitraggi,
  sortingColumn: state.arbitraggi.sortingColumn,
  sortingAscending: state.arbitraggi.sortingAscending,
})

const ConnectedArbitraggi = connect(mapStateToProps)(Arbitraggi)

export default ConnectedArbitraggi
