import {
  SET_SPOSTAMENTI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_SELECTED_STATUS,
  SET_SELECTED_GROUP,
  SET_SPOSTAMENTO,
  SET_MODE,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
  mode: 'view',
}

export const spostamentiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SPOSTAMENTI:
      return { ...state, spostamenti: action.payload }
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    case SET_SELECTED_STATUS:
      return { ...state, selectedStatus: action.payload }
    case SET_SELECTED_GROUP:
      return { ...state, selectedGroup: action.payload }
    case SET_SPOSTAMENTO:
      return { ...state, spostamento: action.payload }
    case SET_MODE:
      return { ...state, mode: action.payload }
    default:
      return state
  }
}
