import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { sendRequest } from './http-hook'
import { useFeedback } from './feedback-hook'
import { SET_DUMMY_UPDATE } from '../container/home/types'
import { SET_ISCRIZIONE } from '../container/iscrizioni/types'

export const useIscrizione = (admin, codiceUtente, dummyUpdate) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const { setFeedback } = useFeedback()

  const editIscrizione = async id => {
    if (!id) {
      setFeedback('Iscrizione non trovata.')
      return
    }

    try {
      const data = await sendRequest(`iscrizioni/${id}`)

      if (!data || !data.data) {
        setFeedback('Iscrizione non trovata.')
      } else {
        dispatch({ type: SET_ISCRIZIONE, payload: data.data })
        history.push('/iscrizione')
      }
    } catch (err) {
      setFeedback(err.message)
    }
  }

  const deleteIscrizione = async (id, deleteAlsoGare) => {
    if (!id) {
      setFeedback('Iscrizione non trovata.')
      return
    }

    try {
      if (admin && deleteAlsoGare) {
        await sendRequest(`calendari/team/${id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
      }

      await sendRequest(`iscrizioni/${id}`, 'DELETE', null, {
        Authorization: codiceUtente,
      })
      setFeedback('Iscrizione eliminata correttamente.', false)
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  return { editIscrizione, deleteIscrizione }
}
