import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import Button from '../components/Button'
import DialogMini from '../components/DialogMini'
import ButtonGroup from '../components/ButtonGroup'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import GridCircularProgress from '../components/GridCircularProgress'
import {
  colours,
  titlesIscrizioni as titles,
  phrasesIscrizioni as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_SELECTED_STATUS,
  SET_SELECTED_GROUP,
  SET_MODE,
  SET_SPOSTAMENTO,
} from '../container/spostamenti/types'

// status selection
const STATUSES = ['Tutti', 'In sospeso', 'Accettati', 'Rifiutati']

// group selection (for non-admin)
const GROUPS = ['Ricevuti', 'Inviati']

const Spostamenti = ({
  admin,
  codiceUtente,
  affiliazioniLight,
  iscrizioniLight,
  dummyUpdate,
  loadingSpostamenti,
  availableCategorie,
  selectedCategoria,
  availableGironi,
  selectedGirone,
  selectedTeam,
  selectedNumeroGara,
  sortingColumn,
  sortingAscending,
  selectedStatus,
  selectedGroup,
  spostamenti,
  mode,
  spostamento,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Spostamenti'

  let columnsSpostamenti = [
    { label: 'Stato', sortable: false },
    { label: 'Richiesto in data', sortable: true, sortingField: 'timeDomanda' },
    { label: 'Gara', sortable: true, sortingField: 'numeroGara' },
    { label: 'Categoria', sortable: true, sortingField: 'categoria' },
    { label: 'Squadra in casa', sortable: false },
    { label: 'Squadra in trasferta', sortable: false },
    { label: 'Tipologia', sortable: false },
  ]
  if (admin)
    columnsSpostamenti = [
      ...columnsSpostamenti,
      { label: 'Richiesto da', sortable: false },
    ]

  useEffect(() => {
    if (!selectedStatus)
      dispatch({ type: SET_SELECTED_STATUS, payload: STATUSES[0] })
  }, [dispatch, selectedStatus])

  useEffect(() => {
    if (!selectedGroup)
      dispatch({ type: SET_SELECTED_GROUP, payload: GROUPS[0] })
  }, [dispatch, selectedGroup])

  const [filteredSpostamenti, setFilteredSpostamenti] = useState()

  const [openSpostamentoInfo, setOpenSpostamentoInfo] = useState(false)
  const [openSpostamentoAdmin, setOpenSpostamentoAdmin] = useState(false)

  const { setFeedback } = useFeedback()

  const handleClickOpenSpostamentoInfo = () => setOpenSpostamentoInfo(true)
  const handleClickOpenSpostamentoAdmin = () => setOpenSpostamentoAdmin(true)

  const handleCloseSpostamentoInfo = () => setOpenSpostamentoInfo(false)
  const handleCloseSpostamentoAdmin = () => setOpenSpostamentoAdmin(false)

  // filter spostamenti
  useEffect(() => {
    setFilteredSpostamenti(
      spostamenti?.filter(
        s =>
          (s.categoria === selectedCategoria ||
            selectedCategoria === availableCategorie[0] ||
            !selectedCategoria) &&
          (s.girone === selectedGirone ||
            selectedGirone === availableGironi[0] ||
            !selectedGirone ||
            !admin) &&
          (s.squadraCasaID === selectedTeam ||
            s.squadraTrasfertaID === selectedTeam ||
            !selectedTeam ||
            !admin) &&
          (s.numeroGara === selectedNumeroGara || !selectedNumeroGara)
      )
    )
  }, [
    admin,
    availableCategorie,
    availableGironi,
    selectedCategoria,
    selectedGirone,
    selectedNumeroGara,
    selectedTeam,
    spostamenti,
  ])

  // view/edit spostamento
  const openSpostamento = async (id, mode) => {
    if (!!id) {
      try {
        const spostamentoData = await sendRequest(
          `spostamenti/${id}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!spostamentoData || !spostamentoData.data) {
          setFeedback('Impossibile trovare lo spostamento.')
        } else {
          dispatch({ type: SET_MODE, payload: mode })
          dispatch({ type: SET_SPOSTAMENTO, payload: spostamentoData.data })
          handleClickOpenSpostamentoInfo()
        }
      } catch (err) {
        setFeedback(err.message)
      }
    }
  }

  // reply spostamento
  const replySpostamento = async () => {
    if (!spostamento?.checkAccept && !spostamento?.checkReject) {
      setFeedback('Lo spostamento va accettato o rifiutato.')
      return
    }

    try {
      await sendRequest(
        `spostamenti/${spostamento.id}/${
          spostamento?.checkAccept ? 'accetta' : 'rifiuta'
        }`,
        'PATCH',
        null,
        { Authorization: codiceUtente }
      )
      handleCloseSpostamentoInfo()
      setFeedback(
        spostamento?.checkAccept
          ? phrases.phraseSpostamentoAccetta
          : phrases.phraseSpostamentoRifiuta,
        false,
        spostamento?.checkAccept
          ? titles.titleSpostamentoAccetta
          : titles.titleSpostamentoRifiuta
      )
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      handleCloseSpostamentoInfo()
      setFeedback(err.message)
    }
  }

  // add richiesta spostamento (made by admin on behalf of a team)
  const performSpostamentoAdmin = async () => {
    try {
      await sendRequest(
        'spostamenti/add',
        'POST',
        JSON.stringify({
          codiceUtente: spostamento?.codiceSocietaDomanda,
          idGara: spostamento?.selectedGara,
          tipologia: spostamento?.selectedFunction,
          nuovaData: spostamento?.newData,
          nuovaOra: spostamento?.newOra,
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      handleCloseSpostamentoAdmin()
      setFeedback(
        phrases.phraseSpostamentoDomandaSuccess,
        false,
        titles.titleSpostamentoDomanda
      )
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Spostamenti</h2>
        </center>
      </Grid>
      <SelectionBar
        includeGirone={!!admin}
        includeTeam={!!admin}
        includeNumeroGara={!!admin}
      />
      &nbsp;
      {admin && (
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <center>
              <ButtonGroup
                list={STATUSES}
                value={selectedStatus}
                func={i => dispatch({ type: SET_SELECTED_STATUS, payload: i })}
              />
            </center>
          </Grid>
          <Grid item xs={12} md={6}>
            <center>
              <Button onClick={handleClickOpenSpostamentoAdmin}>
                Nuovo spostamento
              </Button>
            </center>
          </Grid>
        </Grid>
      )}
      {(loadingSpostamenti && <GridCircularProgress />) ||
        (!filteredSpostamenti && (
          <Grid item xs={12}>
            <center>
              <h4>Elenco degli spostamenti non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (filteredSpostamenti.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Nessuno spostamento trovato.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container item xs={12} padding={3}>
            {!admin && (
              <Grid item xs={12}>
                <center>
                  <ButtonGroup
                    list={GROUPS}
                    value={selectedGroup}
                    labels={index =>
                      `${GROUPS[index]} (${
                        filteredSpostamenti?.filter(
                          s =>
                            codiceUtente ===
                            ((index === 0 && s.codiceSocietaRisposta) ||
                              (index === 1 && s.codiceSocietaDomanda))
                        )?.length || 0
                      })`
                    }
                    func={i =>
                      dispatch({ type: SET_SELECTED_GROUP, payload: i })
                    }
                  />
                </center>
              </Grid>
            )}
            {!admin && (
              <Grid item xs={12}>
                <p>
                  <br />
                  Per domande sugli spostamenti scrivere a{' '}
                  <a href='mailto:spostamenti@pgsmilano.org'>
                    spostamenti@pgsmilano.org
                  </a>
                  .
                </p>
              </Grid>
            )}
            {(!admin &&
              filteredSpostamenti?.filter(
                s =>
                  codiceUtente ===
                  ((selectedGroup === GROUPS[0] && s.codiceSocietaRisposta) ||
                    (selectedGroup === GROUPS[1] && s.codiceSocietaDomanda))
              ).length === 0 && (
                <Grid item xs={12}>
                  <center>
                    &nbsp;
                    <h4>
                      Nessuno spostamento{' '}
                      {(selectedGroup === GROUPS[0] && 'ricevuto') ||
                        (selectedGroup === GROUPS[1] && 'inviato')}
                      .
                    </h4>
                  </center>
                </Grid>
              )) || (
              <>
                <p>Cliccare sulle righe per vedere i dettagli.</p>
                &nbsp;
                <TableContainer component={Paper}>
                  <Table size='small'>
                    <SortableTableHead
                      table={filteredSpostamenti}
                      setTable={setFilteredSpostamenti}
                      columns={columnsSpostamenti}
                      sortingColumn={sortingColumn}
                      setSortingColumn={SET_SORTING_COLUMN}
                      sortingAscending={sortingAscending}
                      setSortingAscending={SET_SORTING_ASCENDING}
                    />
                    <TableBody>
                      {filteredSpostamenti
                        ?.filter(s =>
                          admin
                            ? selectedStatus === STATUSES[0]
                              ? true
                              : selectedStatus === STATUSES[1]
                              ? ![true, false].includes(
                                  s.acceptedBySocietaRisposta
                                )
                              : selectedStatus === STATUSES[2]
                              ? s.acceptedBySocietaRisposta === true
                              : selectedStatus === STATUSES[3]
                              ? s.acceptedBySocietaRisposta === false
                              : false
                            : codiceUtente ===
                              ((selectedGroup === GROUPS[0] &&
                                s.codiceSocietaRisposta) ||
                                (selectedGroup === GROUPS[1] &&
                                  s.codiceSocietaDomanda))
                        )
                        .map((s, index) => {
                          const isRisposta =
                            s.codiceSocietaRisposta === codiceUtente
                          const isAccepted =
                            s.acceptedBySocietaRisposta === true
                          const isRejected =
                            s.acceptedBySocietaRisposta === false
                          const isPending = ![true, false].includes(
                            s.acceptedBySocietaRisposta
                          )
                          const isReplyable = isRisposta && isPending

                          let [sData, sOra] = s.timeDomanda?.split('T')
                          sData = sData?.split('-')?.reverse().join('/')
                          sOra = sOra?.slice(0, 5)

                          return (
                            <TableRow
                              key={index}
                              sx={{
                                cursor: 'pointer',
                                backgroundColor: isReplyable
                                  ? colours.highlight
                                  : colours.white,
                                ':hover': {
                                  backgroundColor: colours.greyVeryLight,
                                },
                              }}
                              onClick={() =>
                                openSpostamento(
                                  s.id,
                                  isReplyable ? 'reply' : 'view'
                                )
                              }
                            >
                              <TableCell align='center'>
                                <Grid item>
                                  <Tooltip
                                    placement='right'
                                    title={
                                      (isAccepted && 'Accettato') ||
                                      (isRejected && 'Rifiutato') ||
                                      (isPending && 'In sospeso') ||
                                      'Sconosciuto'
                                    }
                                  >
                                    {(isAccepted && (
                                      <CheckIcon color='success' />
                                    )) ||
                                      (isRejected && (
                                        <ClearIcon color='error' />
                                      )) ||
                                      (isPending && (
                                        <AccessTimeIcon color='warning' />
                                      )) || <>?</>}
                                  </Tooltip>
                                </Grid>
                              </TableCell>
                              <TableCell
                                align='center'
                                sx={{ whiteSpace: 'nowrap' }}
                              >
                                {sData}&nbsp;{sOra}
                              </TableCell>
                              <TableCell align='center'>
                                {s.numeroGara}
                              </TableCell>
                              <TableCell align='center'>
                                {s.categoria}
                              </TableCell>
                              <TableCell
                                align='center'
                                sx={{ whiteSpace: 'nowrap' }}
                              >
                                {
                                  iscrizioniLight?.find(
                                    n => n.id === s.squadraCasaID
                                  )?.nomeSquadra
                                }
                              </TableCell>
                              <TableCell
                                align='center'
                                sx={{ whiteSpace: 'nowrap' }}
                              >
                                {
                                  iscrizioniLight?.find(
                                    n => n.id === s.squadraTrasfertaID
                                  )?.nomeSquadra
                                }
                              </TableCell>
                              <TableCell
                                align='center'
                                sx={{ whiteSpace: 'nowrap' }}
                              >
                                {s.tipologia === 'sposta'
                                  ? 'Cambio data/ora'
                                  : s.tipologia === 'sposta-inverti'
                                  ? 'Cambio data/ora e inversione'
                                  : s.tipologia === 'inverti'
                                  ? 'Inversione'
                                  : s.tipologia === 'rimanda'
                                  ? 'Rinvio'
                                  : 'sconosciuta'}
                              </TableCell>
                              {admin && (
                                <TableCell
                                  align='center'
                                  sx={{ whiteSpace: 'nowrap' }}
                                >
                                  {affiliazioniLight?.find(
                                    a =>
                                      a.codiceSocieta === s.codiceSocietaDomanda
                                  )?.nomeSocieta || s.codiceSocietaDomanda}
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Grid>
        )}
      <DialogMini
        open={openSpostamentoInfo}
        handleClose={handleCloseSpostamentoInfo}
        title={titles.titleSpostamentoInfo}
        textUndo={mode === 'reply' && 'Annulla'}
        textConfirm={mode === 'reply' ? 'Conferma' : 'Chiudi'}
        triggerFunction={
          mode === 'reply' ? replySpostamento : handleCloseSpostamentoInfo
        }
        dialogSpostamentoInfo={true}
      />
      <DialogMini
        open={openSpostamentoAdmin}
        handleClose={handleCloseSpostamentoAdmin}
        title={titles.titleSpostamentoInfo}
        textUndo='Annulla'
        textConfirm='Conferma'
        triggerFunction={performSpostamentoAdmin}
        dialogSpostamento={true}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  affiliazioniLight: state.home.affiliazioniLight,
  iscrizioniLight: state.home.iscrizioniLight,
  dummyUpdate: state.home.dummyUpdate,
  loadingSpostamenti: state.home.loadingSpostamenti,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  availableGironi: state.home.availableGironi,
  selectedGirone: state.home.selectedGirone,
  availableTeams: state.home.availableTeams,
  selectedTeam: state.home.selectedTeam,
  selectedNumeroGara: state.home.selectedNumeroGara,
  sortingColumn: state.spostamenti.sortingColumn,
  sortingAscending: state.spostamenti.sortingAscending,
  selectedStatus: state.spostamenti.selectedStatus,
  selectedGroup: state.spostamenti.selectedGroup,
  spostamenti: state.spostamenti.spostamenti,
  mode: state.spostamenti.mode,
  spostamento: state.spostamenti.spostamento,
})

const ConnectedSpostamenti = connect(mapStateToProps)(Spostamenti)

export default ConnectedSpostamenti
