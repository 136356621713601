import {
  SET_ARBITRI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_DELETE_ALSO_DESIGNAZIONI,
  SET_ARBITRO,
  SET_ARBITRO_FIELD,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
}

export const arbitriReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ARBITRI:
      return { ...state, arbitri: action.payload }
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    case SET_DELETE_ALSO_DESIGNAZIONI:
      return { ...state, deleteAlsoDesignazioni: action.payload }
    case SET_ARBITRO:
      return { ...state, arbitro: action.payload }
    case SET_ARBITRO_FIELD:
      return {
        ...state,
        arbitro: {
          ...state.arbitro,
          [action.payload.field]: action.payload.value,
        },
      }
    default:
      return state
  }
}
