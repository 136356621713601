export const SET_AVAILABLE_GARE = '@@risultato/SET_AVAILABLE_GARE'
export const SET_SELECTED_GARA = '@@risultato/SET_SELECTED_GARA'

export const SET_RISULTATO = '@@risultato/SET_RISULTATO'
export const SET_RISULTATO_FIELD = '@@risultato/SET_RISULTATO_FIELD'

export const SET_PENALITA_CASA = '@@risultato/SET_PENALITA_CASA'
export const SET_PENALITA_TRASFERTA = '@@risultato/SET_PENALITA_TRASFERTA'

export const SET_OMOLOGA = '@@risultato/SET_OMOLOGA'
