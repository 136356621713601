import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'

import AutoComplete from './AutoComplete'
import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { SET_DUMMY_UPDATE } from '../container/home/types'

const columnsZone = [
  { label: 'Zona', sortable: false },
  { label: 'Zone vicine', sortable: false },
  { label: 'Descrizione', sortable: false },
  { label: 'Opzioni', sortable: false },
]

const DialogZone = ({ codiceUtente, zone, dummyUpdate }) => {
  const dispatch = useDispatch()

  const { setFeedback } = useFeedback()

  // states to manage new zone addition/update
  const [editingZona, setEditingZona] = useState(false)
  const [addingZona, setAddingZona] = useState(false)
  const [newZona, setNewZona] = useState()
  const [newVicini, setNewVicini] = useState()
  const [newDescrizione, setNewDescrizione] = useState()

  // add zona
  const addNewZona = async () => {
    if (!!newZona) {
      try {
        await sendRequest(
          'zone/add',
          'POST',
          JSON.stringify({
            zona: newZona,
            vicini: newVicini,
            descrizione: newDescrizione,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        clearNewZona()
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Nessun nome inserito!')
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // clear zona
  const clearNewZona = () => {
    setEditingZona(false)
    setAddingZona(false)
    setNewZona()
    setNewVicini()
    setNewDescrizione()
  }

  // edit zona
  const editZona = async id => {
    if (!!id) {
      try {
        await sendRequest(
          `zone/${id}`,
          'PUT',
          JSON.stringify({
            zona: newZona,
            vicini: newVicini,
            descrizione: newDescrizione,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        clearNewZona()
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Zona non trovata!')
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // delete zona
  const deleteZona = async id => {
    if (!!id) {
      try {
        await sendRequest(`zone/${id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Zona non trovata!')
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  return (
    <>
      &nbsp;
      {(!zone && <h4>Zone non trovate.</h4>) || (
        <>
          <Grid container item xs={12} spacing={3} alignItems='center'>
            {(!!addingZona && (
              <>
                <Grid item xs={4} md={2}>
                  <center>
                    <InputField
                      id='zona'
                      value={newZona}
                      onChange={setNewZona}
                      autoFocus={true}
                      label={columnsZone[0].label}
                    />
                  </center>
                </Grid>
                <Grid item xs={8} md={4}>
                  <center>
                    <AutoComplete
                      id='vicini'
                      value={zone?.filter(z => newVicini?.includes(z.id))}
                      options={zone?.filter(i => i.zona !== newZona)}
                      blurOnSelect={false}
                      multiple={true}
                      label={columnsZone[1].label}
                      getOptionLabel={op => op.zona}
                      onChange={i => setNewVicini(i.map(z => z.id) || [])}
                    />
                  </center>
                </Grid>
                <Grid item xs={8} md={4}>
                  <center>
                    <InputField
                      id='descrizione'
                      value={newDescrizione}
                      onChange={setNewDescrizione}
                      label={columnsZone[2].label}
                    />
                  </center>
                </Grid>
                <Grid item xs={2} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.blueDark }}
                      onClick={() => addNewZona()}
                    >
                      <Tooltip key='Salva' title='Salva'>
                        <SaveIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
                <Grid item xs={2} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.red }}
                      onClick={() => clearNewZona()}
                    >
                      <Tooltip key='Annulla' title='Annulla'>
                        <ClearIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
              </>
            )) || (
              <Grid item xs={12}>
                <center>
                  <IconButton
                    style={{ color: colours.black }}
                    onClick={() => {
                      setEditingZona(false)
                      setAddingZona(true)
                      setNewZona()
                      setNewVicini()
                      setNewDescrizione()
                    }}
                  >
                    <AddIcon />
                    &nbsp; Nuova zona
                  </IconButton>
                </center>
              </Grid>
            )}
          </Grid>
          &nbsp;
          {(zone.length === 0 && <h4>Nessuna zona trovata.</h4>) || (
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead table={zone} columns={columnsZone} />
                <TableBody>
                  {zone.map((z, index) => {
                    return (
                      <TableRow
                        key={index}
                        style={{ backgroundColor: colours.white }}
                      >
                        {(!!editingZona && newZona === z.zona && (
                          <>
                            <TableCell align='center' padding='checkbox'>
                              {z.zona}
                            </TableCell>
                            <TableCell align='center' padding='checkbox'>
                              <AutoComplete
                                id='vicini'
                                value={zone?.filter(z =>
                                  newVicini?.includes(z.id)
                                )}
                                options={zone?.filter(i => i.zona !== newZona)}
                                blurOnSelect={false}
                                multiple={true}
                                label={columnsZone[1].label}
                                getOptionLabel={op => op.zona}
                                onChange={i =>
                                  setNewVicini(i.map(z => z.id) || [])
                                }
                              />
                            </TableCell>
                            <TableCell align='center' padding='checkbox'>
                              <InputField
                                id='descrizione'
                                value={newDescrizione}
                                onChange={setNewDescrizione}
                                autoFocus={true}
                                label={columnsZone[2].label}
                                mode='update'
                              />
                            </TableCell>
                            <TableCell align='center' padding='checkbox'>
                              <Grid container item>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.blueDark }}
                                    onClick={() => editZona(z.id)}
                                  >
                                    <Tooltip key='Salva' title='Salva'>
                                      <SaveIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.red }}
                                    onClick={() => clearNewZona()}
                                  >
                                    <Tooltip key='Annulla' title='Annulla'>
                                      <ClearIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </>
                        )) || (
                          <>
                            <TableCell align='center'>{z.zona}</TableCell>
                            <TableCell align='center'>
                              {zone
                                .filter(i => z.vicini.includes(i.id))
                                ?.map(i => i.zona)
                                .join(', ')}
                            </TableCell>
                            <TableCell align='center'>
                              {z.descrizione}
                            </TableCell>
                            <TableCell align='center'>
                              <Grid container item>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.blueDark }}
                                    onClick={() => {
                                      setEditingZona(true)
                                      setAddingZona(false)
                                      setNewZona(z.zona)
                                      setNewVicini(z.vicini)
                                      setNewDescrizione(z.descrizione)
                                    }}
                                  >
                                    <Tooltip key='Modifica' title='Modifica'>
                                      <EditIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.red }}
                                    onClick={() => deleteZona(z.id)}
                                  >
                                    <Tooltip key='Elimina' title='Elimina'>
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  zone: state.home.zone,
  dummyUpdate: state.home.dummyUpdate,
})

const ConnectedDialogZone = connect(mapStateToProps)(DialogZone)

export default ConnectedDialogZone
