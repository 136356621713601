import React from 'react'
import { connect, useDispatch } from 'react-redux'

import Button from './Button'
import { useFeedback } from '../hooks/feedback-hook'
import { runAlgorithm } from '../hooks/algoritmi-functions'
import { colours } from '../settings/settings'
import { SET_BACKEND_R } from '../container/home/types'

const ButtonRun = ({
  buttonText,
  algorithm,
  codiceUtente,
  backendR,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
}) => {
  const dispatch = useDispatch()

  const { setFeedback } = useFeedback()

  const setBackendR = () =>
    dispatch({
      type: SET_BACKEND_R,
      payload: { ...backendR, status: 'busy', process: algorithm },
    })

  const lastTime = backendR?.[`${algorithm}-lastTime`]

  return (
    <center>
      <Button
        backgroundcolour={
          backendR?.isCallable ? colours.blue : colours.greyLight
        }
        disabled={!backendR?.isCallable}
        onClick={() =>
          runAlgorithm({
            algorithm,
            selectedCampionato,
            availableCategorie,
            selectedCategoria,
            codiceUtente,
            setFeedback,
            setBackendR,
          })
        }
      >
        {buttonText}
      </Button>
      <p style={{ fontSize: 16 }}>Ultima esecuzione:&nbsp;{lastTime || '?'}</p>
    </center>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  backendR: state.home.backendR,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
})

const ConnectedButtonRun = connect(mapStateToProps)(ButtonRun)

export default ConnectedButtonRun
