import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid, Radio } from '@mui/material'

import DatePicker from './DatePicker'
import TimePicker from './TimePicker'
import {
  SET_NEW_DATA,
  SET_NEW_ORA,
  SET_SWAP_TEAMS,
  SET_SELECTED_FUNCTION,
} from '../container/calendari/types'

const availableFunctions = ['sposta', 'inverti', 'rimanda']

const DialogCalendario = ({
  gara,
  iscrizioniLight,
  newData,
  newOra,
  selectedFunction,
}) => {
  const dispatch = useDispatch()

  const [squadraCasaName, setSquadraCasaName] = useState()
  const [squadraTrasfertaName, setSquadraTrasfertaName] = useState()

  // Set team names
  useEffect(() => {
    if (gara?.squadraCasaID)
      setSquadraCasaName(
        iscrizioniLight?.find(i => i.id === gara.squadraCasaID)?.nomeSquadra ||
          gara.squadraCasaID
      )
    if (gara?.squadraTrasfertaID)
      setSquadraTrasfertaName(
        iscrizioniLight?.find(i => i.id === gara.squadraTrasfertaID)
          ?.nomeSquadra || gara.squadraTrasfertaID
      )
  }, [gara, iscrizioniLight])

  if (!selectedFunction)
    dispatch({ type: SET_SELECTED_FUNCTION, payload: availableFunctions[0] })

  useEffect(() => {
    dispatch({ type: SET_NEW_DATA })
    dispatch({ type: SET_NEW_ORA })
    dispatch({
      type: SET_SWAP_TEAMS,
      payload: selectedFunction === availableFunctions[1],
    })
  }, [dispatch, selectedFunction])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_NEW_DATA })
      dispatch({ type: SET_NEW_ORA })
      dispatch({ type: SET_SWAP_TEAMS })
    }
  }, [dispatch])

  return (
    <>
      &nbsp;
      <Grid container spacing={5} direction='column'>
        <Grid container item xs={12} spacing={3} alignItems='center'>
          <Grid item xs={12} md={12}>
            <center>
              <b>{squadraCasaName}</b>&nbsp;-&nbsp;<b>{squadraTrasfertaName}</b>
            </center>
          </Grid>
          <Grid item xs={12} md={4}>
            <center>
              Gara <b>{gara?.numeroGara}</b>
            </center>
          </Grid>
          <Grid item xs={12} md={4}>
            <center>
              Data <b>{gara?.data || 'da definire'}</b>
            </center>
          </Grid>
          <Grid item xs={12} md={4}>
            <center>
              Ora <b>{gara?.ora || 'da definire'}</b>
            </center>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems='center'>
          <Grid container item xs={12} md={5} alignItems='center'>
            <Grid item xs={2}>
              <center>
                <Radio
                  id='sposta'
                  value={availableFunctions[0]}
                  checked={selectedFunction === availableFunctions[0]}
                  onChange={event => {
                    dispatch({
                      type: SET_SELECTED_FUNCTION,
                      payload: event.target.value,
                    })
                  }}
                />
              </center>
            </Grid>
            <Grid item xs>
              Cambia data e ora...
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <center>
              <DatePicker
                label='Data'
                value={newData}
                onChange={i => dispatch({ type: SET_NEW_DATA, payload: i })}
                disabled={selectedFunction !== availableFunctions[0]}
              />
            </center>
          </Grid>
          <Grid item xs={12} md={3}>
            <center>
              <TimePicker
                label='Ora'
                value={newOra}
                onChange={i => dispatch({ type: SET_NEW_ORA, payload: i })}
                disabled={selectedFunction !== availableFunctions[0]}
              />
            </center>
          </Grid>
        </Grid>
        <Grid container item spacing={3} alignItems='center'>
          <Grid container item xs={12} md={5} alignItems='center'>
            <Grid item xs={2}>
              <center>
                <Radio
                  id='inverti'
                  value={availableFunctions[1]}
                  checked={selectedFunction === availableFunctions[1]}
                  onChange={event => {
                    dispatch({
                      type: SET_SELECTED_FUNCTION,
                      payload: event.target.value,
                    })
                  }}
                />
              </center>
            </Grid>
            <Grid item xs>
              Inverti le squadre.
            </Grid>
          </Grid>
        </Grid>
        <Grid container item spacing={3} alignItems='center'>
          <Grid container item xs={12} md={5} alignItems='center'>
            <Grid item xs={2}>
              <center>
                <Radio
                  id='rimanda'
                  value={availableFunctions[2]}
                  checked={selectedFunction === availableFunctions[2]}
                  onChange={event => {
                    dispatch({
                      type: SET_SELECTED_FUNCTION,
                      payload: event.target.value,
                    })
                  }}
                />
              </center>
            </Grid>
            <Grid item xs>
              Rimanda.
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  iscrizioniLight: state.home.iscrizioniLight,
  newData: state.calendari.newData,
  newOra: state.calendari.newOra,
  selectedFunction: state.calendari.selectedFunction,
})

const ConnectedDialogCalendario = connect(mapStateToProps)(DialogCalendario)

export default ConnectedDialogCalendario
