import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import DialogMini from './DialogMini'
import { colours } from '../settings/settings'
import { SET_FEEDBACK } from '../container/home/types'

const DialogFeedback = ({ feedback }) => {
  const dispatch = useDispatch()

  const error = !!feedback?.error
  const title = feedback?.title || (error && 'Errore')
  const phrase = feedback?.phrase
  const triggerPath = feedback?.triggerPath

  const handleClose = () => dispatch({ type: SET_FEEDBACK })

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_FEEDBACK })
  }, [dispatch])

  return (
    <DialogMini
      open={!!feedback}
      handleClose={handleClose}
      title={title}
      textContent={phrase}
      textConfirm='Ok'
      triggerFunction={handleClose}
      triggerPath={triggerPath}
      colourBackground={error ? colours.red : undefined}
      colourHover={error ? colours.redDark : undefined}
    />
  )
}

const mapStateToProps = state => ({
  feedback: state.home.feedback,
})

const ConnectedDialogFeedback = connect(mapStateToProps)(DialogFeedback)

export default ConnectedDialogFeedback
