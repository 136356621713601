import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import EuroIcon from '@mui/icons-material/Euro'

import DialogMini from '../components/DialogMini'
import ButtonGroup from '../components/ButtonGroup'
import ButtonRunDownload from '../components/ButtonRunDownload'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import GridCircularProgress from '../components/GridCircularProgress'
import { colours, titlesProvvedimenti as titles } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { sortDataOra } from '../hooks/sort-functions'
import {
  fromDateToString,
  fromStringsToDateTime,
  DAYS_TO_MILLISECONDS,
} from '../hooks/utils-functions'
import {
  SET_ERROR,
  SET_DUMMY_UPDATE,
  SET_SELECTED_NUMERI_GARA,
} from '../container/home/types'
import {
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_SELECTED_GROUP,
  SET_SELECTED_NEEDS_REFEREE,
} from '../container/provvedimenti/types'

// group selection
const GROUPS = [
  'risultati-mancanti',
  'risultati-ritardo',
  'referti-mancanti',
  'referti-ritardo',
]
const LABELS = [
  'Risultati mancanti',
  'Risultati inviati in ritardo',
  'Referti mancanti',
  'Referti inviati in ritardo',
]

// needsReferee selection
const REFEREES = ['Tutte le gare', 'Con arbitro PGS', 'Senza arbitro PGS']

const Provvedimenti = ({
  codiceUtente,
  iscrizioniLight,
  dummyUpdate,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  sortingColumn,
  sortingAscending,
  selectedGroup,
  selectedNeedsReferee,
  sanzioneReferto,
  sanzioneMail,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Provvedimenti'

  const columns = [
    { label: 'Gara', sortable: true, sortingField: 'numeroGara' },
    { label: 'Data', sortable: false },
    { label: 'Squadra in casa', sortable: false },
    { label: 'Squadra in trasferta', sortable: false },
  ]

  const columnsRisultatiMancanti = [
    ...columns,
    { label: 'Sanzione', sortable: false },
  ]

  const columnsRisultatiRitardo = [
    ...columns,
    { label: 'Inviato', sortable: false },
    { label: 'Ritardo', sortable: true, sortingField: 'daysDelay' },
    { label: 'Sanzione', sortable: false },
  ]

  const columnsRefertiMancanti = [
    ...columns,
    { label: 'Sanzione', sortable: false },
  ]

  const columnsRefertiRitardo = [
    ...columns,
    { label: 'Omologa', sortable: true, sortingField: 'timeHomologation' },
    { label: 'Ritardo', sortable: true, sortingField: 'daysDelay' },
    { label: 'Sanzione', sortable: false },
  ]

  const { setFeedback } = useFeedback()

  const filterByNeedsReferee = useCallback(
    x =>
      x?.filter(f =>
        selectedNeedsReferee === REFEREES[1]
          ? !!f.needsReferee || !!f.requiresReferee
          : selectedNeedsReferee === REFEREES[2]
          ? !f.needsReferee && !f.requiresReferee
          : true
      ),
    [selectedNeedsReferee]
  )

  const [isLoading, setIsLoading] = useState()
  const [risultatiMancanti, setRisultatiMancanti] = useState()
  const [risultatiRitardo, setRisultatiRitardo] = useState()
  const [refertiMancanti, setRefertiMancanti] = useState()
  const [refertiRitardo, setRefertiRitardo] = useState()
  const [filteredRisultatiMancanti, setFilteredRisultatiMancanti] = useState()
  const [filteredRisultatiRitardo, setFilteredRisultatiRitardo] = useState()
  const [filteredRefertiMancanti, setFilteredRefertiMancanti] = useState()
  const [filteredRefertiRitardo, setFilteredRefertiRitardo] = useState()

  // state to manage dialogs
  const [openEditSanzioneReferto, setOpenEditSanzioneReferto] = useState(false)
  const [editSanzioneReferto, setEditSanzioneReferto] = useState()

  const handleClickOpenEditSanzioneReferto = id => {
    setEditSanzioneReferto(
      [...refertiMancanti, ...refertiRitardo].find(i => i.id === id)
    )
    setOpenEditSanzioneReferto(true)
  }

  const handleCloseEditSanzioneReferto = () => {
    setOpenEditSanzioneReferto(false)
  }

  useEffect(() => {
    if (!selectedGroup)
      dispatch({ type: SET_SELECTED_GROUP, payload: GROUPS[0] })
  }, [dispatch, selectedGroup])

  useEffect(() => {
    if (!selectedNeedsReferee)
      dispatch({ type: SET_SELECTED_NEEDS_REFEREE, payload: REFEREES[0] })
  }, [dispatch, selectedNeedsReferee])

  // fetch risultati mancanti
  useEffect(() => {
    const fetchRisultatiMancanti = async () => {
      setIsLoading(true)
      try {
        const data = await sendRequest(
          `calendari/risultati-mancanti/campionato/${selectedCampionato}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i risultati mancanti.',
          })
        } else {
          setRisultatiMancanti(
            data.sort((a, b) => sortDataOra(a.data, a.ora, b.data, b.ora))
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (codiceUtente && selectedCampionato) {
      fetchRisultatiMancanti()
    } else {
      setRisultatiMancanti()
    }
  }, [codiceUtente, dispatch, dummyUpdate, selectedCampionato])

  // fetch risultati arrivati in ritardo
  useEffect(() => {
    const fetchRisultatiRitardo = async () => {
      setIsLoading(true)
      try {
        const data = await sendRequest(
          `calendari/risultati-ritardo/campionato/${selectedCampionato}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i risultati inviati in ritardo.',
          })
        } else {
          setRisultatiRitardo(
            data.sort((a, b) => sortDataOra(a.data, a.ora, b.data, b.ora))
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (codiceUtente && selectedCampionato) {
      fetchRisultatiRitardo()
    } else {
      setRisultatiRitardo()
    }
  }, [codiceUtente, dispatch, dummyUpdate, selectedCampionato])

  // fetch referti mancanti
  useEffect(() => {
    const fetchRefertiMancanti = async () => {
      setIsLoading(true)
      try {
        const data = await sendRequest(
          `calendari/referti-mancanti/campionato/${selectedCampionato}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i referti mancanti.',
          })
        } else {
          setRefertiMancanti(
            data.sort((a, b) => sortDataOra(a.data, a.ora, b.data, b.ora))
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (codiceUtente && selectedCampionato) {
      fetchRefertiMancanti()
    } else {
      setRefertiMancanti()
    }
  }, [codiceUtente, dispatch, dummyUpdate, selectedCampionato])

  // fetch referti arrivati in ritardo
  useEffect(() => {
    const fetchRefertiRitardo = async () => {
      setIsLoading(true)
      try {
        const data = await sendRequest(
          `calendari/referti-ritardo/campionato/${selectedCampionato}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i referti inviati in ritardo.',
          })
        } else {
          setRefertiRitardo(
            data.sort((a, b) => sortDataOra(a.data, a.ora, b.data, b.ora))
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (codiceUtente && selectedCampionato) {
      fetchRefertiRitardo()
    } else {
      setRefertiRitardo()
    }
  }, [codiceUtente, dispatch, dummyUpdate, selectedCampionato])

  // filter risultati mancanti
  useEffect(() => {
    setFilteredRisultatiMancanti(
      risultatiMancanti?.filter(
        c =>
          c.categoria === selectedCategoria ||
          selectedCategoria === availableCategorie[0] ||
          !selectedCategoria
      )
    )
  }, [availableCategorie, risultatiMancanti, selectedCategoria])

  // filter risultati ritardo
  useEffect(() => {
    setFilteredRisultatiRitardo(
      risultatiRitardo?.filter(
        c =>
          c.categoria === selectedCategoria ||
          selectedCategoria === availableCategorie[0] ||
          !selectedCategoria
      )
    )
  }, [availableCategorie, risultatiRitardo, selectedCategoria])

  // filter referti mancanti
  useEffect(() => {
    setFilteredRefertiMancanti(
      refertiMancanti?.filter(
        c =>
          c.categoria === selectedCategoria ||
          selectedCategoria === availableCategorie[0] ||
          !selectedCategoria
      )
    )
  }, [availableCategorie, refertiMancanti, selectedCategoria])

  // filter referti ritardo
  useEffect(() => {
    setFilteredRefertiRitardo(
      refertiRitardo?.filter(
        c =>
          c.categoria === selectedCategoria ||
          selectedCategoria === availableCategorie[0] ||
          !selectedCategoria
      )
    )
  }, [availableCategorie, refertiRitardo, selectedCategoria])

  useEffect(() => {
    if (GROUPS.includes(selectedGroup))
      dispatch({
        type: SET_SELECTED_NUMERI_GARA,
        payload: filterByNeedsReferee(
          selectedGroup === GROUPS[0]
            ? filteredRisultatiMancanti
            : selectedGroup === GROUPS[1]
            ? filteredRisultatiRitardo
            : selectedGroup === GROUPS[2]
            ? filteredRefertiMancanti
            : filteredRefertiRitardo
        )?.map(f => f.numeroGara),
      })
  }, [
    dispatch,
    filterByNeedsReferee,
    filteredRefertiMancanti,
    filteredRefertiRitardo,
    filteredRisultatiMancanti,
    filteredRisultatiRitardo,
    selectedGroup,
  ])

  // update sanzione referto
  const updateSanzioneReferto = async () => {
    if (!!editSanzioneReferto.id) {
      try {
        await sendRequest(
          `calendari/sanzione-referto/${editSanzioneReferto.id}`,
          'PATCH',
          JSON.stringify({
            squadraCasaSanzionataPerReferto: sanzioneReferto,
            sendMail: sanzioneMail,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseEditSanzioneReferto()
        setEditSanzioneReferto()
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Nessuna gara selezionata!')
    }
  }

  const nomeSquadraById = id =>
    iscrizioniLight?.find(n => n.id === id)?.nomeSquadra || (
      <i>Squadra non trovata</i>
    )

  const IconSanzione = isSanzionata => (
    <Tooltip
      title={
        isSanzionata
          ? 'La squadra di casa è stata sanzionata'
          : 'La squadra di casa non è stata sanzionata'
      }
    >
      <EuroIcon
        style={{ color: isSanzionata ? colours.green : colours.greyVeryLight }}
      />
    </Tooltip>
  )

  return (
    <Grid container item marginY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Provvedimenti disciplinari</h2>
        </center>
      </Grid>
      <SelectionBar includeGirone={false} includeTeam={false} />
      &nbsp;
      <Grid item xs={12}>
        <center>
          <ButtonGroup
            list={GROUPS}
            labels={index => LABELS[index]}
            value={selectedGroup}
            func={i => dispatch({ type: SET_SELECTED_GROUP, payload: i })}
          />
        </center>
      </Grid>
      &nbsp;
      <Grid container item xs={12}>
        <Grid item xs={12} md={9}>
          <center>
            <ButtonGroup
              list={REFEREES}
              value={selectedNeedsReferee}
              func={i =>
                dispatch({ type: SET_SELECTED_NEEDS_REFEREE, payload: i })
              }
            />
          </center>
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonRunDownload
            buttonText='Scarica Excel'
            algorithm='09_GenerateExcelProvvedimenti'
            type='XLSX'
          />
        </Grid>
      </Grid>
      {(!GROUPS.includes(selectedGroup) && (
        <Grid item xs={12}>
          <center>Selezionare un tipo di provvedimento.</center>
        </Grid>
      )) ||
        (isLoading && <GridCircularProgress />) ||
        (selectedGroup === GROUPS[0] &&
          (!filteredRisultatiMancanti ||
            filteredRisultatiMancanti.length === 0) && (
            <Grid item xs>
              <center>
                <h4>Nessun risultato mancante.</h4>
              </center>
            </Grid>
          )) ||
        (selectedGroup === GROUPS[1] &&
          (!filteredRisultatiRitardo ||
            filteredRisultatiRitardo.length === 0) && (
            <Grid item xs>
              <center>
                <h4>Nessun risultato inviato in ritardo.</h4>
              </center>
            </Grid>
          )) ||
        (selectedGroup === GROUPS[2] &&
          (!filteredRefertiMancanti ||
            filteredRefertiMancanti.length === 0) && (
            <Grid item xs>
              <center>
                <h4>Nessun referto mancante.</h4>
              </center>
            </Grid>
          )) ||
        (selectedGroup === GROUPS[3] &&
          (!filteredRefertiRitardo || filteredRefertiRitardo.length === 0) && (
            <Grid item xs>
              <center>
                <h4>Nessun referto inviato in ritardo.</h4>
              </center>
            </Grid>
          )) ||
        (selectedGroup === GROUPS[0] && (
          <Grid container padding={3}>
            <p>
              <b>
                {filterByNeedsReferee(filteredRisultatiMancanti)?.length || 0}{' '}
                risultati{' '}
              </b>
              non sono ancora stati inviati.
              <br />
              Le gare evidenziate risalgono a più di 5 giorni fa e saranno
              sanzionate una volta inviato il risultato e aggiornato il
              rendiconto della società.
            </p>
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={filteredRisultatiMancanti}
                  setTable={setFilteredRisultatiMancanti}
                  columns={columnsRisultatiMancanti}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {filterByNeedsReferee(filteredRisultatiMancanti)?.map(
                    (c, index) => {
                      const mDate = fromStringsToDateTime(c.data, c.ora)
                      const nDate = new Date()
                      const isLate = nDate - mDate > 5 * DAYS_TO_MILLISECONDS

                      return (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor: isLate
                              ? colours.redError
                              : colours.white,
                          }}
                        >
                          <TableCell align='center'>{c.numeroGara}</TableCell>
                          <TableCell align='center'>{c.data}</TableCell>
                          <TableCell align='center'>
                            {nomeSquadraById(c.squadraCasaID)}
                          </TableCell>
                          <TableCell align='center'>
                            {nomeSquadraById(c.squadraTrasfertaID)}
                          </TableCell>
                          <TableCell align='center'>
                            {IconSanzione(c.squadraCasaSanzionataPerRisultato)}
                          </TableCell>
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )) ||
        (selectedGroup === GROUPS[1] && (
          <Grid container padding={3}>
            <p>
              <b>
                {filterByNeedsReferee(filteredRisultatiRitardo)?.length || 0}{' '}
                risultati{' '}
              </b>
              sono stati inviati più di 5 giorni dopo l'orario di inizio gara e
              sono da sanzionare.
              <br />
              Finora{' '}
              {filterByNeedsReferee(filteredRisultatiRitardo)?.filter(
                r => r.squadraCasaSanzionataPerRisultato === true
              )?.length || 0}{' '}
              sono stati sanzionati e inseriti nel rendiconto della società in
              casa.
            </p>
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={filteredRisultatiRitardo}
                  setTable={setFilteredRisultatiRitardo}
                  columns={columnsRisultatiRitardo}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {filterByNeedsReferee(filteredRisultatiRitardo)?.map(
                    (c, index) => (
                      <TableRow key={index}>
                        <TableCell align='center'>{c.numeroGara}</TableCell>
                        <TableCell align='center'>{c.data}</TableCell>
                        <TableCell align='center'>
                          {nomeSquadraById(c.squadraCasaID)}
                        </TableCell>
                        <TableCell align='center'>
                          {nomeSquadraById(c.squadraTrasfertaID)}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {fromDateToString(
                            new Date(
                              c.timeResultInsertedByCasa ||
                                c.timeResultInsertedByTrasferta ||
                                c.timeResultUpdatedByAdmin
                            ),
                            true
                          )}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {Math.floor(c.daysDelay)} giorni
                        </TableCell>
                        <TableCell align='center'>
                          {IconSanzione(c.squadraCasaSanzionataPerRisultato)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )) ||
        (selectedGroup === GROUPS[2] && (
          <Grid container padding={3}>
            <p>
              <b>
                {filterByNeedsReferee(filteredRefertiMancanti)?.length || 0}{' '}
                referti{' '}
              </b>
              non sono ancora stati consegnati. Le gare evidenziate risalgono a
              più di 20 giorni fa e sono da sanzionare.
            </p>
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={filteredRefertiMancanti}
                  setTable={setFilteredRefertiMancanti}
                  columns={columnsRefertiMancanti}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {filterByNeedsReferee(filteredRefertiMancanti)?.map(
                    (c, index) => {
                      const mDate = fromStringsToDateTime(c.data, c.ora)
                      const nDate = new Date()
                      const isLate = nDate - mDate > 20 * DAYS_TO_MILLISECONDS

                      return (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor: isLate
                              ? colours.redError
                              : colours.white,
                          }}
                        >
                          <TableCell align='center'>{c.numeroGara}</TableCell>
                          <TableCell align='center'>{c.data}</TableCell>
                          <TableCell align='center'>
                            {nomeSquadraById(c.squadraCasaID)}
                          </TableCell>
                          <TableCell align='center'>
                            {nomeSquadraById(c.squadraTrasfertaID)}
                          </TableCell>
                          <TableCell align='center'>
                            <IconButton
                              onClick={() =>
                                handleClickOpenEditSanzioneReferto(c.id)
                              }
                            >
                              {IconSanzione(c.squadraCasaSanzionataPerReferto)}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )) ||
        (selectedGroup === GROUPS[3] && (
          <Grid container padding={3}>
            <p>
              <b>
                {filterByNeedsReferee(filteredRefertiRitardo)?.length || 0}{' '}
                referti{' '}
              </b>
              sono stati inviati più di 20 giorni dopo l'orario di inizio gara e
              sono da sanzionare. Finora{' '}
              {filterByNeedsReferee(filteredRefertiRitardo)?.filter(
                r => r.squadraCasaSanzionataPerReferto === true
              )?.length || 0}{' '}
              sono stati sanzionati.
            </p>
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={filteredRefertiRitardo}
                  setTable={setFilteredRefertiRitardo}
                  columns={columnsRefertiRitardo}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {filterByNeedsReferee(filteredRefertiRitardo)?.map(
                    (c, index) => (
                      <TableRow key={index}>
                        <TableCell align='center'>{c.numeroGara}</TableCell>
                        <TableCell align='center'>{c.data}</TableCell>
                        <TableCell align='center'>
                          {nomeSquadraById(c.squadraCasaID)}
                        </TableCell>
                        <TableCell align='center'>
                          {nomeSquadraById(c.squadraTrasfertaID)}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {fromDateToString(new Date(c.timeHomologation), true)}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {Math.floor(c.daysDelay)} giorni
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            onClick={() =>
                              handleClickOpenEditSanzioneReferto(c.id)
                            }
                          >
                            {IconSanzione(c.squadraCasaSanzionataPerReferto)}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      <DialogMini
        open={openEditSanzioneReferto}
        handleClose={handleCloseEditSanzioneReferto}
        title={titles.titleEditSanzioneReferto}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={updateSanzioneReferto}
        dialogSanzioneReferto={true}
        gara={editSanzioneReferto}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  iscrizioniLight: state.home.iscrizioniLight,
  dummyUpdate: state.home.dummyUpdate,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  sortingColumn: state.provvedimenti.sortingColumn,
  sortingAscending: state.provvedimenti.sortingAscending,
  selectedGroup: state.provvedimenti.selectedGroup,
  selectedNeedsReferee: state.provvedimenti.selectedNeedsReferee,
  sanzioneReferto: state.calendari.sanzioneReferto,
  sanzioneMail: state.calendari.sanzioneMail,
})

const ConnectedProvvedimenti = connect(mapStateToProps)(Provvedimenti)

export default ConnectedProvvedimenti
