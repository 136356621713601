import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Checkbox,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'

import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { SET_DUMMY_UPDATE } from '../container/home/types'

const columnsCampionati = [
  { label: 'Indice' },
  { label: 'Codice' },
  { label: 'Campionato' },
  { label: 'Stagione' },
  { label: 'Iscriz. aperte' },
  { label: 'Gironi pubbl.' },
  { label: 'Gironi provv.' },
  { label: 'Calend. pubbl.' },
  { label: 'Calend. provv.' },
  { label: 'Fine spost. gratis' },
  { label: 'Operazioni' },
]

const DialogCampionati = ({ codiceUtente, campionati, dummyUpdate }) => {
  const dispatch = useDispatch()

  const { setFeedback } = useFeedback()

  // states to manage addition/update
  const [editingItem, setEditingItem] = useState(false)
  const [addingItem, setAddingItem] = useState(false)
  const [newIndice, setNewIndice] = useState()
  const [newCodice, setNewCodice] = useState()
  const [newNome, setNewNome] = useState()
  const [newStagione, setNewStagione] = useState()
  const [newIscrizioniAperte, setNewIscrizioniAperte] = useState()
  const [newGironiPubblicati, setNewGironiPubblicati] = useState()
  const [newGironiProvvisori, setNewGironiProvvisori] = useState()
  const [newCalendariPubblicati, setNewCalendariPubblicati] = useState()
  const [newCalendariProvvisori, setNewCalendariProvvisori] = useState()
  const [newTermineSpostamentiGratuiti, setNewTermineSpostamentiGratuiti] =
    useState()

  // clear campionato
  const clearNewItem = () => {
    setNewIndice()
    setNewCodice()
    setNewNome()
    setNewStagione()
    setNewIscrizioniAperte()
    setNewGironiPubblicati()
    setNewGironiProvvisori()
    setNewCalendariPubblicati()
    setNewCalendariProvvisori()
    setNewTermineSpostamentiGratuiti()
  }

  // add new campionato
  const addNewItem = async () => {
    if (newCodice) {
      try {
        await sendRequest(
          'campionati/add',
          'POST',
          JSON.stringify({
            indice: newIndice,
            codice: newCodice,
            nome: newNome,
            stagione: newStagione,
            iscrizioniAperte: newIscrizioniAperte,
            gironiPubblicati: newGironiPubblicati,
            gironiProvvisori: newGironiProvvisori,
            calendariPubblicati: newCalendariPubblicati,
            calendariProvvisori: newCalendariProvvisori,
            termineSpostamentiGratuiti: newTermineSpostamentiGratuiti,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        setAddingItem(false)
        clearNewItem()
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Codice campionato non trovato!')
    }
  }

  // edit campionato
  const editItem = async id => {
    if (id) {
      try {
        await sendRequest(
          `campionati/${id}`,
          'PUT',
          JSON.stringify({
            indice: newIndice,
            codice: newCodice,
            nome: newNome,
            stagione: newStagione,
            iscrizioniAperte: newIscrizioniAperte,
            gironiPubblicati: newGironiPubblicati,
            gironiProvvisori: newGironiProvvisori,
            calendariPubblicati: newCalendariPubblicati,
            calendariProvvisori: newCalendariProvvisori,
            termineSpostamentiGratuiti: newTermineSpostamentiGratuiti,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        setEditingItem(false)
        clearNewItem()
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Campionato non trovato!')
    }
  }

  // delete campionato
  const deleteItem = async id => {
    if (id) {
      try {
        await sendRequest(`campionati/${id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Campionato non trovato!')
    }
  }

  return (
    (!campionati && <h4>Campionati non trovati.</h4>) || (
      <>
        <Grid container item xs={12} spacing={3} alignItems='center'>
          {(addingItem && (
            <>
              <Grid item xs={4} md={2}>
                <center>
                  <InputField
                    id='indice'
                    label={columnsCampionati?.[0]?.label}
                    value={newIndice}
                    onChange={setNewIndice}
                    autoFocus={true}
                    type='number'
                  />
                </center>
              </Grid>
              <Grid item xs={8} md={3}>
                <center>
                  <InputField
                    id='codice'
                    label={columnsCampionati?.[1]?.label}
                    value={newCodice}
                    onChange={setNewCodice}
                  />
                </center>
              </Grid>
              <Grid item xs={12} md={3}>
                <center>
                  <InputField
                    id='nome'
                    label={columnsCampionati?.[2]?.label}
                    value={newNome}
                    onChange={setNewNome}
                  />
                </center>
              </Grid>
              <Grid item xs={8} md={2}>
                <center>
                  <InputField
                    id='stagione'
                    label={columnsCampionati?.[3]?.label}
                    value={newStagione}
                    onChange={setNewStagione}
                  />
                </center>
              </Grid>
              <Grid item xs={2} md={1}>
                <center>
                  <IconButton
                    style={{ color: colours.blueDark }}
                    onClick={addNewItem}
                  >
                    <Tooltip key='Salva' title='Salva'>
                      <SaveIcon />
                    </Tooltip>
                  </IconButton>
                </center>
              </Grid>
              <Grid item xs={2} md={1}>
                <center>
                  <IconButton
                    style={{ color: colours.red }}
                    onClick={() => {
                      setAddingItem(false)
                      clearNewItem()
                    }}
                  >
                    <Tooltip key='Annulla' title='Annulla'>
                      <ClearIcon />
                    </Tooltip>
                  </IconButton>
                </center>
              </Grid>
            </>
          )) || (
            <Grid item xs={12}>
              <center>
                <IconButton
                  style={{ color: colours.black }}
                  onClick={() => {
                    setEditingItem(false)
                    clearNewItem()
                    setAddingItem(true)
                  }}
                >
                  <AddIcon />
                  &nbsp; Nuovo campionato
                </IconButton>
              </center>
            </Grid>
          )}
        </Grid>
        &nbsp;
        {(campionati.length === 0 && <h4>Nessun campionato trovato.</h4>) || (
          <TableContainer component={Paper}>
            <Table size='small'>
              <SortableTableHead
                table={campionati}
                columns={columnsCampionati}
              />
              <TableBody>
                {campionati.map((c, index) => {
                  const propsCell = {
                    align: 'center',
                    sx: { whiteSpace: 'nowrap' },
                  }

                  const TableCellCheckbox = ({ id, checked, onChange }) => (
                    <TableCell align='center'>
                      <Checkbox
                        id={id}
                        checked={!!checked}
                        onChange={event => onChange(event.target.checked)}
                      />
                    </TableCell>
                  )

                  const TableCellBoolean = ({ field }) => (
                    <TableCell {...propsCell}>
                      {typeof c[field] === 'boolean'
                        ? (c[field] && 'SÌ') || 'NO'
                        : '?'}
                    </TableCell>
                  )

                  return (
                    <TableRow
                      key={index}
                      style={{ backgroundColor: colours.white }}
                    >
                      {(editingItem && newCodice === c.codice && (
                        <>
                          <TableCell align='center'>
                            <InputField
                              id='indice'
                              value={newIndice}
                              onChange={setNewIndice}
                              mode='update'
                              type='number'
                            />
                          </TableCell>
                          <TableCell align='center'>{c.codice}</TableCell>
                          <TableCell align='center'>
                            <InputField
                              id='nome'
                              value={newNome}
                              onChange={setNewNome}
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center'>
                            <InputField
                              id='stagione'
                              value={newStagione}
                              onChange={setNewStagione}
                              mode='update'
                            />
                          </TableCell>
                          <TableCellCheckbox
                            id='iscrizioniAperte'
                            checked={newIscrizioniAperte}
                            onChange={setNewIscrizioniAperte}
                          />
                          <TableCellCheckbox
                            id='gironiPubblicati'
                            checked={newGironiPubblicati}
                            onChange={setNewGironiPubblicati}
                          />
                          <TableCellCheckbox
                            id='gironiProvvisori'
                            checked={newGironiProvvisori}
                            onChange={setNewGironiProvvisori}
                          />
                          <TableCellCheckbox
                            id='calendariPubblicati'
                            checked={newCalendariPubblicati}
                            onChange={setNewCalendariPubblicati}
                          />
                          <TableCellCheckbox
                            id='calendariProvvisori'
                            checked={newCalendariProvvisori}
                            onChange={setNewCalendariProvvisori}
                          />
                          <TableCell align='center'>
                            <InputField
                              id='termineSpostamentiGratuiti'
                              value={newTermineSpostamentiGratuiti}
                              onChange={setNewTermineSpostamentiGratuiti}
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center'>
                            <Grid container item>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.blueDark }}
                                  onClick={() => editItem(c._id)}
                                >
                                  <Tooltip key='Salva' title='Salva'>
                                    <SaveIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={() => {
                                    setEditingItem(false)
                                    clearNewItem()
                                  }}
                                >
                                  <Tooltip key='Annulla' title='Annulla'>
                                    <ClearIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </>
                      )) || (
                        <>
                          <TableCell {...propsCell}>{c.indice}</TableCell>
                          <TableCell {...propsCell}>{c.codice}</TableCell>
                          <TableCell {...propsCell}>{c.nome}</TableCell>
                          <TableCell {...propsCell}>{c.stagione}</TableCell>
                          <TableCellBoolean field='iscrizioniAperte' />
                          <TableCellBoolean field='gironiPubblicati' />
                          <TableCellBoolean field='gironiProvvisori' />
                          <TableCellBoolean field='calendariPubblicati' />
                          <TableCellBoolean field='calendariProvvisori' />
                          <TableCell {...propsCell}>
                            {c?.termineSpostamentiGratuiti}
                          </TableCell>
                          <TableCell {...propsCell}>
                            <Grid container item>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.blueDark }}
                                  onClick={() => {
                                    setEditingItem(true)
                                    setAddingItem(false)
                                    setNewIndice(c.indice)
                                    setNewCodice(c.codice)
                                    setNewNome(c.nome)
                                    setNewStagione(c.stagione)
                                    setNewIscrizioniAperte(c.iscrizioniAperte)
                                    setNewGironiPubblicati(c.gironiPubblicati)
                                    setNewGironiProvvisori(c.gironiProvvisori)
                                    setNewCalendariPubblicati(
                                      c.calendariPubblicati
                                    )
                                    setNewCalendariProvvisori(
                                      c.calendariProvvisori
                                    )
                                    setNewTermineSpostamentiGratuiti(
                                      c.termineSpostamentiGratuiti
                                    )
                                  }}
                                >
                                  <Tooltip key='Modifica' title='Modifica'>
                                    <EditIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={() => deleteItem(c._id)}
                                >
                                  <Tooltip key='Elimina' title='Elimina'>
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    )
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  campionati: state.home.campionati,
  dummyUpdate: state.home.dummyUpdate,
})

const ConnectedDialogCampionati = connect(mapStateToProps)(DialogCampionati)

export default ConnectedDialogCampionati
