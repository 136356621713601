import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import AutoComplete from './AutoComplete'
import { sendRequest } from '../hooks/http-hook'
import { sortNumerically, sortGironi } from '../hooks/sort-functions'
import { SET_ERROR } from '../container/home/types'
import { SET_RIPESCAGGIO_ID } from '../container/calendari/types'

const DialogRipescaggio = ({ gara, ripescaggioID }) => {
  const dispatch = useDispatch()

  const [squadre, setSquadre] = useState()

  // fetch squadre
  useEffect(() => {
    const fetchSquadre = async () => {
      try {
        const squadreData = await sendRequest(
          `iscrizioni/campionato/${gara.codiceCampionato}/categoria/${gara.categoria}`
        )

        if (!squadreData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le squadre.',
          })
        } else {
          setSquadre(
            squadreData?.sort(
              (a, b) =>
                sortGironi(a.girone, b.girone) ||
                sortNumerically(a.posizione, b.posizione)
            )
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (gara?.codiceCampionato && gara?.categoria) {
      fetchSquadre()
    } else {
      setSquadre()
    }
  }, [dispatch, gara])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_RIPESCAGGIO_ID })
  }, [dispatch])

  return (
    <>
      &nbsp;
      {((!gara || gara.length === 0) && (
        <Grid item xs={12}>
          Nessuna partita selezionata.
        </Grid>
      )) || (
        <Grid container spacing={3} direction='column'>
          <Grid item xs={12}>
            Il presente modulo permette di scegliere la squadra da ripescare.
          </Grid>
          &nbsp;
          {!!gara && (
            <Grid item xs={12}>
              <center>
                <AutoComplete
                  id='ripescaggio'
                  value={ripescaggioID}
                  label='Squadra da ripescare'
                  options={squadre}
                  groupBy={op =>
                    !!op.girone ? `Girone ${+op.girone}` : 'Senza girone'
                  }
                  getOptionLabel={op =>
                    squadre?.filter(i => i?.id === (op?.id || op))?.[0]
                      ?.nomeSquadra
                  }
                  isOptionEqualToValue={(op, value) => op?.id === value}
                  onChange={value =>
                    dispatch({ type: SET_RIPESCAGGIO_ID, payload: value?.id })
                  }
                />
              </center>
            </Grid>
          )}
          &nbsp;
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  ripescaggioID: state.calendari.ripescaggioID,
})

const ConnectedDialogRipescaggio = connect(mapStateToProps)(DialogRipescaggio)

export default ConnectedDialogRipescaggio
