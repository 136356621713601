import React, { useState } from 'react'
import { connect } from 'react-redux'

import Button from './Button'
import GridCircularProgress from './GridCircularProgress'
import { useFeedback } from '../hooks/feedback-hook'
import { runAlgorithm } from '../hooks/algoritmi-functions'
import { colours } from '../settings/settings'

const ButtonRunDownload = ({
  buttonText,
  algorithm,
  type,
  admin,
  codiceUtente,
  backendR,
  availableStagioni,
  selectedStagione,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  availableGironi,
  selectedGirone,
  selectedTeam,
  selectedTeams,
  selectedNumeroGara,
  selectedGroup,
  selectedNumeriGara,
}) => {
  const { setFeedback } = useFeedback()

  const [loading, setLoading] = useState()

  const isTxt = type === 'TXT'
  const isPdf = type === 'PDF'
  const isExcel = type === 'XLSX'

  return (
    <center>
      <Button
        disabled={
          loading ||
          !backendR?.isCallable ||
          (algorithm === '07_GenerateExcelGironi'
            ? !selectedCampionato ||
              !selectedCategoria ||
              selectedCategoria === availableCategorie[0]
            : algorithm === '08_GenerateExcelCalendari'
            ? !selectedCampionato ||
              !selectedCategoria ||
              !selectedGirone ||
              selectedCategoria === availableCategorie[0] ||
              (!admin && selectedGirone === availableGironi[0]) ||
              !!selectedNumeroGara
            : algorithm === '09_GenerateExcelProvvedimenti'
            ? !selectedCampionato ||
              !selectedGroup ||
              !selectedNumeriGara?.length
            : algorithm === '12_GenerateExcelDesignazioni'
            ? !selectedCampionato || !selectedNumeriGara?.length
            : algorithm === '15_GenerateExcelRendiconto'
            ? !selectedStagione || selectedStagione === availableStagioni[0]
            : false)
        }
        backgroundcolour={
          (isTxt && colours.greyLight) ||
          (isPdf && colours.red) ||
          (isExcel && colours.greenExcel) ||
          colours.blueDark
        }
        hovercolour={
          (isTxt && colours.grey) ||
          (isPdf && colours.redDark) ||
          (isExcel && colours.greenExcelHover) ||
          colours.blueVeryDark
        }
        onClick={async () => {
          setLoading(true)
          await runAlgorithm({
            algorithm,
            triggerDownload: true,
            type,
            selectedStagione,
            selectedCampionato,
            availableCategorie,
            selectedCategoria,
            availableGironi,
            selectedGirone,
            selectedGroup,
            selectedTeam,
            selectedTeams,
            selectedNumeriGara,
            codiceUtente,
            setFeedback,
          })
          setLoading()
        }}
      >
        {(loading && <GridCircularProgress color='inherit' size='1rem' />) ||
          buttonText}
      </Button>
    </center>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  backendR: state.home.backendR,
  availableStagioni: state.home.availableStagioni,
  selectedStagione: state.home.selectedStagione,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  availableGironi: state.home.availableGironi,
  selectedGirone: state.home.selectedGirone,
  selectedTeam: state.home.selectedTeam,
  selectedTeams: state.home.selectedTeams,
  selectedNumeroGara: state.home.selectedNumeroGara,
  selectedGroup: state.provvedimenti.selectedGroup,
  selectedNumeriGara: state.home.selectedNumeriGara,
})

const ConnectedButtonRunDownload = connect(mapStateToProps)(ButtonRunDownload)

export default ConnectedButtonRunDownload
