import React, { useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import GridCard from '../components/GridCard'
import SelectionBar from '../components/SelectionBar'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR, SET_DASHBOARD_STATS } from '../container/home/types'

const DashboardDesignatore = ({
  codiceUtente,
  selectedCampionato,
  dashboardStats,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Home'

  const getRequest = useCallback(
    async url =>
      await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
    [codiceUtente]
  )

  const dispatchError = useCallback(
    text =>
      dispatch({
        type: SET_ERROR,
        payload: `Impossibile trovare le statistiche ${text}.`,
      }),
    [dispatch]
  )

  const dispatchStats = useCallback(
    x => dispatch({ type: SET_DASHBOARD_STATS, payload: x }),
    [dispatch]
  )

  // fetch arbitri stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest('arbitri/stats')

        if (!data) {
          dispatchError('sugli arbitri')
        } else {
          dispatchStats({ arbitriStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) fetch()
  }, [codiceUtente, dispatch, dispatchError, dispatchStats, getRequest])

  // fetch designazioni stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `designazioni/stats/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatchError('sulle designazioni')
        } else {
          dispatchStats({ designazioniStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  // fetch arbitraggi stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `arbitraggi/stats/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatchError('sugli arbitraggi')
        } else {
          dispatchStats({ arbitraggiStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  return (
    <Grid
      container
      item
      xs={12}
      marginY={2}
      rowSpacing={5}
      alignItems='stretch'
    >
      <SelectionBar
        includeCategoria={false}
        includeGirone={false}
        includeTeam={false}
      />
      <p style={{ margin: 20, width: '100%' }}>
        Benvenuto nel profilo di designazione di PGS Milano.
        <br />
        Dalla pagina <b>Arbitri</b> puoi controllare le disponibilità e altre
        informazioni utili.
        <br />
        Dalla pagina <b>Designazioni</b> puoi scegliere gli arbitri per le
        prossime partite.
        <br />
        Dalla pagina <b>Arbitraggi</b> puoi controllare per quali gare le
        società hanno chiesto l'arbitraggio ufficiale.
        <br />
        Buon lavoro!
      </p>
      <GridCard
        title='Arbitri'
        subtitle1={`${dashboardStats?.arbitriStats?.complete || 0} ${
          dashboardStats?.arbitriStats?.complete === 1 ? 'completo' : 'completi'
        }`}
        subtitle2={`${dashboardStats?.arbitriStats?.incomplete || 0} ${
          dashboardStats?.arbitriStats?.incomplete === 1
            ? 'da completare'
            : 'da completare'
        }`}
        linkTo='/arbitri'
        isError={dashboardStats?.arbitriStats?.incomplete > 0}
      />
      <GridCard
        title='Designazioni'
        subtitle1={`${dashboardStats?.designazioniStats?.missing10days || 0} ${
          dashboardStats?.designazioniStats?.missing10days === 1
            ? 'mancante (10 giorni)'
            : 'mancanti (10 giorni)'
        }`}
        subtitle2={`${dashboardStats?.designazioniStats?.waiting || 0} ${
          dashboardStats?.designazioniStats?.waiting === 1
            ? 'in sospeso'
            : 'in sospeso'
        }`}
        linkTo='/designazioni'
        isError={
          dashboardStats?.designazioniStats?.waiting +
            dashboardStats?.designazioniStats?.missing10days >
          0
        }
      />
      <GridCard
        title='Arbitraggi'
        subtitle1={`${dashboardStats?.arbitraggiStats?.nRequired || 0} ${
          dashboardStats?.arbitraggiStats?.nRequired === 1
            ? 'richiesta'
            : 'richieste'
        }`}
        subtitle2='-'
        linkTo='/arbitraggi'
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  selectedCampionato: state.home.selectedCampionato,
  dashboardStats: state.home.dashboardStats,
})

const ConnectedDashboardDesignatore =
  connect(mapStateToProps)(DashboardDesignatore)

export default ConnectedDashboardDesignatore
