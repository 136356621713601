import { fromStringsToDateTime } from './utils-functions'

const sortNumerically = (a, b) => a - b
const sortAlphabetically = (a, b) => (a === b ? 0 : a > b ? 1 : -1)
const sortChronologically = (a, b) => new Date(a) - new Date(b)

const sortSfide = (a, b) => sortNumerically(a, b)
const sortGiornate = (a, b) => sortNumerically(a, b)
const sortCategorie = (a, b) => sortAlphabetically(a, b)
const sortCodiceSocieta = (a, b) => sortNumerically(a, b)
const sortCampionati = (a, b) => sortNumerically(a, b)
const sortStagioni = (a, b) => -sortAlphabetically(a, b)

const sortGironi = (a, b) =>
  a === b
    ? 0
    : (a === null && 1) ||
      (b === null && -1) ||
      (a === 'finali' && -1) ||
      (b === 'finali' && 1) ||
      (a === 'avulsa' && -1) ||
      (b === 'avulsa' && 1) ||
      sortNumerically(a, b)

const sortNumeriGara = (a, b) =>
  a === b
    ? 0
    : (isNaN(+a) && isNaN(+b) && sortAlphabetically(a, b)) ||
      (isNaN(+a) && 1) ||
      (isNaN(+b) && -1) ||
      sortNumerically(a, b)

const sortDataOra = (aData, aOra, bData, bOra) =>
  aData === bData && aOra === bOra
    ? 0
    : ((!aData || !aOra) && 1) ||
      ((!bData || !bOra) && -1) ||
      fromStringsToDateTime(aData, aOra) - fromStringsToDateTime(bData, bOra)

export {
  sortNumerically,
  sortAlphabetically,
  sortChronologically,
  sortSfide,
  sortGiornate,
  sortCategorie,
  sortCodiceSocieta,
  sortCampionati,
  sortStagioni,
  sortGironi,
  sortNumeriGara,
  sortDataOra,
}
