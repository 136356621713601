import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { SET_SPOSTAMENTO } from '../container/spostamenti/types'

const DialogSpostamentoInfo = ({ iscrizioniLight, mode, spostamento }) => {
  const dispatch = useDispatch()

  const [squadraCasaName, setSquadraCasaName] = useState()
  const [squadraTrasfertaName, setSquadraTrasfertaName] = useState()

  // Set team names
  useEffect(() => {
    if (spostamento?.squadraCasaID)
      setSquadraCasaName(
        iscrizioniLight?.find(i => i.id === spostamento.squadraCasaID)
          ?.nomeSquadra || spostamento.squadraCasaID
      )
    if (spostamento?.squadraTrasfertaID)
      setSquadraTrasfertaName(
        iscrizioniLight?.find(i => i.id === spostamento.squadraTrasfertaID)
          ?.nomeSquadra || spostamento.squadraTrasfertaID
      )
  }, [spostamento, iscrizioniLight])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_SPOSTAMENTO })
  }, [dispatch])

  const GridElement = ({ label, field, mapping, large = false }) => {
    return (
      <Grid item xs={12} md={large ? 12 : 6}>
        <center>
          {label}&nbsp;
          <b>
            {mapping
              ? mapping(spostamento[field])
              : spostamento[field] ||
                (['data', 'nuovaData'].includes(field) && 'non definita') ||
                (['ora', 'nuovaOra'].includes(field) && 'non definito') ||
                '?'}
          </b>
        </center>
      </Grid>
    )
  }

  return (
    <>
      &nbsp;
      {(!spostamento && (
        <Grid item xs={12}>
          Spostamento non trovato.
        </Grid>
      )) || (
        <Grid container spacing={3} alignItems='center'>
          {mode === 'reply' && (
            <Grid item xs={12}>
              Il presente modulo permette di accettare o rifiutare le richieste
              di spostamento ricevute. La compilazione del modulo genera una
              mail di conferma alla squadra avversaria e l'aggiornamento
              immediato del calendario.
            </Grid>
          )}
          <GridElement label='Gara' field='numeroGara' />
          <GridElement label='Categoria' field='categoria' />
          <GridElement
            label='Squadra in casa'
            field='squadraCasaID'
            mapping={() => squadraCasaName}
          />
          <GridElement
            label='Squadra in trasferta'
            field='squadraTrasfertaID'
            mapping={() => squadraTrasfertaName}
          />
          <GridElement
            label={
              ['sposta', 'sposta-inverti'].includes(spostamento.tipologia)
                ? 'Vecchia data'
                : 'Data'
            }
            field='data'
          />
          <GridElement
            label={
              ['sposta', 'sposta-inverti'].includes(spostamento.tipologia)
                ? 'Vecchio orario'
                : 'Orario'
            }
            field='ora'
          />
          <GridElement
            label=''
            field='tipologia'
            mapping={x =>
              x === 'sposta'
                ? 'Cambio data/ora'
                : x === 'sposta-inverti'
                ? 'Cambio data/ora e inversione di campo'
                : x === 'inverti'
                ? 'Inversione di campo'
                : x === 'rimanda'
                ? 'Rinvio a data da destinarsi'
                : 'sconosciuta'
            }
            large
          />
          {['sposta', 'sposta-inverti'].includes(spostamento.tipologia) && (
            <GridElement label='Nuova data' field='nuovaData' />
          )}
          {['sposta', 'sposta-inverti'].includes(spostamento.tipologia) && (
            <GridElement label='Nuovo orario' field='nuovaOra' />
          )}
          {!!spostamento.note && (
            <>
              &nbsp;
              <GridElement
                label='Note della squadra richiedente'
                field='note'
                large
              />
            </>
          )}
          &nbsp;
          {mode === 'view' && (
            <GridElement
              label='Stato'
              field='acceptedBySocietaRisposta'
              mapping={x =>
                x === true
                  ? 'Accettato'
                  : x === false
                  ? 'Rifiutato'
                  : 'In sospeso'
              }
              large
            />
          )}
          {mode === 'reply' && (
            <>
              <Grid container item spacing={3} alignItems='center'>
                <Grid item xs={2} md={1}>
                  <center>
                    <Checkbox
                      id='check-accept'
                      checked={!!spostamento?.checkAccept}
                      onChange={event =>
                        dispatch({
                          type: SET_SPOSTAMENTO,
                          payload: {
                            ...spostamento,
                            checkAccept: event.target.checked,
                            checkReject: undefined,
                          },
                        })
                      }
                    />
                  </center>
                </Grid>
                <Grid item xs={10} md={11}>
                  <b>Accetto lo spostamento.</b>
                </Grid>
              </Grid>
              <Grid container item spacing={3} alignItems='center'>
                <Grid item xs={2} md={1}>
                  <center>
                    <Checkbox
                      id='check-reject'
                      checked={!!spostamento?.checkReject}
                      onChange={event =>
                        dispatch({
                          type: SET_SPOSTAMENTO,
                          payload: {
                            ...spostamento,
                            checkReject: event.target.checked,
                            checkAccept: undefined,
                          },
                        })
                      }
                    />
                  </center>
                </Grid>
                <Grid item xs={10} md={11}>
                  <b>Rifiuto lo spostamento.</b>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  iscrizioniLight: state.home.iscrizioniLight,
  mode: state.spostamenti.mode,
  spostamento: state.spostamenti.spostamento,
})

const ConnectedDialogSpostamentoInfo = connect(mapStateToProps)(
  DialogSpostamentoInfo
)

export default ConnectedDialogSpostamentoInfo
