import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'

import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import GridCircularProgress from './GridCircularProgress'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorNumber0, errorNaturale0 } from '../hooks/error-functions'
import { sortDataOra } from '../hooks/sort-functions'
import { sumFun, formatEuro, formatIndirizzo } from '../hooks/utils-functions'
import { SET_ERROR, SET_SELECTED_DESIGNAZIONI } from '../container/home/types'
import { SET_RIMBORSO } from '../container/rimborsi/types'

// error functions
const errorKmPercorsi = x => x && errorNaturale0(x)
const errorSpese = x => x && errorNumber0(x)
const errorImportoLordoAnnuo = x => x && errorNumber0(x)

const DialogRimborso = ({
  arbitro,
  codiceUtente,
  infoUtente,
  selectedDesignazioni,
  rimborsoMode,
  rimborso,
}) => {
  const dispatch = useDispatch()

  const isSelect = rimborsoMode === 'select'
  const isAdd = rimborsoMode === 'add'
  const isUpdate = rimborsoMode === 'update'
  const isView = rimborsoMode === 'view'

  let columnsRimborsoDesignazioni = []
  if (isSelect)
    columnsRimborsoDesignazioni = [
      ...columnsRimborsoDesignazioni,
      { label: 'Selezione', sortable: false },
    ]
  columnsRimborsoDesignazioni = [
    ...columnsRimborsoDesignazioni,
    { label: 'Data e Ora', sortable: false },
    { label: 'Categoria', sortable: false },
    { label: 'Gara', sortable: false },
    { label: 'Indirizzo', sortable: false },
  ]
  if (!isSelect)
    columnsRimborsoDesignazioni = [
      ...columnsRimborsoDesignazioni,
      { label: 'Diaria', sortable: false },
      { label: 'km percorsi', sortable: false },
      { label: 'Spese', sortable: false },
      { label: 'Totale', sortable: false },
    ]

  const MAX_GARE = 12

  // initialize selectedDesignazioni
  useEffect(() => {
    if (isUpdate || isView)
      dispatch({
        type: SET_SELECTED_DESIGNAZIONI,
        payload: rimborso?.designazioni?.map(d => d.idDesignazione),
      })
  }, [dispatch, isUpdate, isView, rimborso])

  // initialize rimborso
  useEffect(() => {
    if (rimborso) {
      if (!rimborso.idArbitro)
        dispatch({
          type: SET_RIMBORSO,
          payload: { ...rimborso, idArbitro: infoUtente?.id },
        })
      if (!rimborso.costoKm)
        dispatch({
          type: SET_RIMBORSO,
          payload: {
            ...rimborso,
            costoKm: rimborso.costoKm || infoUtente?.costoKm || 0.4,
          },
        })
    }
  }, [dispatch, infoUtente, rimborso])

  const [loadingDesignazioni, setLoadingDesignazioni] = useState(false)
  const [availableDesignazioni, setAvailableDesignazioni] = useState()
  const [squadreCasa, setSquadreCasa] = useState()

  const totGara = id =>
    (rimborso?.designazioni?.find(i => i.idDesignazione === id)?.diaria || 0) +
    (rimborso?.designazioni?.find(i => i.idDesignazione === id)?.kmPercorsi ||
      0) *
      rimborso?.costoKm +
    (rimborso?.designazioni?.find(i => i.idDesignazione === id)?.spese || 0)

  const totDiaria = () =>
    sumFun(rimborso?.designazioni?.map(i => i.diaria)) || 0
  const totKmPercorsi = () =>
    sumFun(rimborso?.designazioni?.map(i => i.kmPercorsi)) || 0
  const totSpese = () => sumFun(rimborso?.designazioni?.map(i => i.spese)) || 0

  const totCompenso = () => totDiaria()
  const totRimborsoSpese = () =>
    totKmPercorsi() * rimborso?.costoKm + totSpese()
  const totTotale = () => totCompenso() + totRimborsoSpese()

  // fetch designazioni for rimborso
  useEffect(() => {
    const fetchRimborsoDesignazioni = async () => {
      setLoadingDesignazioni(true)
      try {
        const rimborsoDesignazioniData = await sendRequest(
          `designazioni/arbitro/${
            arbitro ? infoUtente.id : rimborso?.idArbitro
          }/rimborso${!isSelect ? '/all' : ''}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!rimborsoDesignazioniData) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'elenco delle gare.",
          })
        } else {
          setAvailableDesignazioni(
            rimborsoDesignazioniData?.sort((a, b) =>
              sortDataOra(a.data, a.ora, b.data, b.ora)
            )
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingDesignazioni(false)
    }
    if (!availableDesignazioni) {
      if (
        ((arbitro && infoUtente?.id) || rimborso?.idArbitro) &&
        codiceUtente
      ) {
        fetchRimborsoDesignazioni()
      } else {
        setAvailableDesignazioni()
      }
    }
  }, [
    arbitro,
    availableDesignazioni,
    codiceUtente,
    dispatch,
    infoUtente,
    isSelect,
    rimborso,
  ])

  // fetch squadreCasa
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(
          'iscrizioni/many',
          'POST',
          JSON.stringify({
            ids: availableDesignazioni?.map(d => d.squadraCasaID),
          }),
          { 'Content-Type': 'application/json' }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le squadre.',
          })
        } else {
          setSquadreCasa(data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (availableDesignazioni) {
      fetch()
    } else {
      setSquadreCasa()
    }
  }, [availableDesignazioni, dispatch])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_RIMBORSO })
      dispatch({ type: SET_SELECTED_DESIGNAZIONI })
    }
  }, [dispatch])

  return (
    <Grid container spacing={3} direction='column'>
      <Grid item xs={12}>
        {(isSelect || isAdd) && (
          <>
            Il presente modulo permette di generare il file per i rimborsi
            arbitrali.
            <ul>
              {isSelect && (
                <li>
                  Selezionare le gare per cui si desidera fare richiesta di
                  rimborso (massimo {MAX_GARE}) e cliccare su CONFERMA.
                </li>
              )}
              {isAdd && (
                <>
                  <li>
                    Inserire l'importo lordo ricevuto dal 1° Gennaio per le
                    prestazioni di lavoro sportivo dilettantistico ai sensi
                    dell'Art. 25 e ss. (D.Lgs 36/2021), suddividendo tra PGS e
                    altri.
                  </li>
                  <li>
                    Inserire i km percorsi e le eventuali spese di viaggio
                    sostenute per ciascuna gara.
                  </li>
                </>
              )}
            </ul>
            {isAdd &&
              'Cliccare su CONFERMA per terminare e salvare i dati in piattaforma. Dalla pagina RIMBORSI sarà poi possibile ' +
                'scaricare il file Excel per la richiesta del rimborso e modificare i dati inseriti (finché non verrà effettuato il bonifico).'}
          </>
        )}
        {isUpdate &&
          'I dati inseriti potranno essere modificati finché non verrà effettuato il bonifico.'}
        {isView &&
          'I dati inseriti non possono più essere modificati perché è già stato effettuato il bonifico.'}
      </Grid>
      {(loadingDesignazioni && <GridCircularProgress />) ||
        (!availableDesignazioni && (
          <Grid item>
            <center>
              <h4>Elenco delle gare non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (availableDesignazioni.length === 0 && (
          <Grid item xs>
            <center>
              <h4>Nessuna gara da rimborsare.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container item alignItems='center' padding={3}>
            {isSelect && (
              <Grid item xs={12}>
                <center>
                  <b>
                    {selectedDesignazioni?.length === 1
                      ? '1 gara selezionata'
                      : `${selectedDesignazioni?.length || 0} gare selezionate`}
                  </b>
                </center>
              </Grid>
            )}
            {!isSelect && (
              <>
                <Grid container item paddingY={2} spacing={3}>
                  <Grid item>
                    <center>
                      <b>Importo lordo già ricevuto da P.G.S. dal 1° Gennaio</b>
                      &nbsp;ai sensi dell'Art. 25 e ss. (D.Lgs 36/2021):
                    </center>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <center>
                      {isView ? (
                        formatEuro(rimborso?.importoLordoAnnuoPGS)
                      ) : (
                        <InputField
                          id='importoLordoAnnuoPGS'
                          align='center'
                          variant='standard'
                          placeholder='0.00'
                          value={rimborso?.importoLordoAnnuoPGS}
                          onChange={x =>
                            dispatch({
                              type: SET_RIMBORSO,
                              payload: {
                                ...rimborso,
                                importoLordoAnnuoPGS: +x?.toFixed(2),
                              },
                            })
                          }
                          errorFunc={errorImportoLordoAnnuo}
                          type='number'
                        />
                      )}
                    </center>
                  </Grid>
                </Grid>
                <Grid container item paddingY={2} spacing={3}>
                  <Grid item>
                    <center>
                      <b>Importo lordo già ricevuto da altri dal 1° Gennaio</b>
                      &nbsp;ai sensi dell'Art. 25 e ss. (D.Lgs 36/2021):
                    </center>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <center>
                      {isView ? (
                        formatEuro(rimborso?.importoLordoAnnuoAltri)
                      ) : (
                        <InputField
                          id='importoLordoAnnuoAltri'
                          align='center'
                          variant='standard'
                          placeholder='0.00'
                          value={rimborso?.importoLordoAnnuoAltri}
                          onChange={x =>
                            dispatch({
                              type: SET_RIMBORSO,
                              payload: {
                                ...rimborso,
                                importoLordoAnnuoAltri: +x?.toFixed(2),
                              },
                            })
                          }
                          errorFunc={errorImportoLordoAnnuo}
                          type='number'
                        />
                      )}
                    </center>
                  </Grid>
                </Grid>
              </>
            )}
            {!isSelect &&
              [
                `Totale compenso: ${formatEuro(totCompenso() || 0)}`,
                `Totale spese: ${formatEuro(totRimborsoSpese() || 0)}`,
                `Totale: ${formatEuro(totTotale() || 0)}`,
              ].map((t, index) => (
                <Grid key={index} item xs padding={3}>
                  <center>
                    <b>{t}</b>
                  </center>
                </Grid>
              ))}
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={availableDesignazioni}
                  columns={columnsRimborsoDesignazioni}
                />
                <TableBody>
                  {availableDesignazioni
                    ?.filter(
                      a => isSelect || selectedDesignazioni?.includes(a._id)
                    )
                    ?.map((a, index) => {
                      const isSelected = selectedDesignazioni?.includes(a._id)

                      const aSquadraCasa = squadreCasa?.find(
                        i => i._id === a.squadraCasaID
                      )

                      const cellProps = {
                        align: 'center',
                        sx: {
                          whiteSpace: 'nowrap',
                          borderBottomColor:
                            isSelect &&
                            index < availableDesignazioni.length - 1 &&
                            availableDesignazioni[index]?.data?.split(
                              '/'
                            )?.[1] !==
                              availableDesignazioni[index + 1]?.data?.split(
                                '/'
                              )?.[1] &&
                            colours.greyDark,
                        },
                      }

                      return (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              isSelect && isSelected
                                ? colours.highlightGreen
                                : colours.white,
                          }}
                        >
                          {isSelect && (
                            <TableCell {...cellProps}>
                              <Checkbox
                                id={`${a._id}-checkbox`}
                                color='success'
                                checked={!!isSelected}
                                onChange={() =>
                                  dispatch({
                                    type: SET_SELECTED_DESIGNAZIONI,
                                    payload: isSelected
                                      ? selectedDesignazioni?.filter(
                                          g => g !== a._id
                                        )
                                      : !selectedDesignazioni
                                      ? [a._id]
                                      : selectedDesignazioni?.length < MAX_GARE
                                      ? [...selectedDesignazioni, a._id]
                                      : selectedDesignazioni,
                                  })
                                }
                              />
                            </TableCell>
                          )}
                          <TableCell {...cellProps}>
                            {a?.data}&nbsp;{a?.ora}
                          </TableCell>
                          <TableCell {...cellProps}>{a?.categoria}</TableCell>
                          <TableCell {...cellProps}>{a?.numeroGara}</TableCell>
                          <TableCell {...cellProps}>
                            {formatIndirizzo(aSquadraCasa, a, false) || (
                              <i>Indirizzo non trovato</i>
                            )}
                          </TableCell>
                          {!isSelect && (
                            <TableCell
                              {...cellProps}
                              sx={{
                                ...cellProps.sx,
                                whiteSpace: 'nowrap',
                                borderLeftWidth: 1,
                                borderLeftStyle: 'solid',
                                borderLeftColor: colours.greyDark,
                              }}
                              width={window.innerWidth * 0.1}
                            >
                              {formatEuro(
                                rimborso?.designazioni?.find(
                                  i => i.idDesignazione === a._id
                                )?.diaria
                              )}
                            </TableCell>
                          )}
                          {!isSelect && (
                            <TableCell
                              {...cellProps}
                              sx={{ ...cellProps.sx, whiteSpace: 'nowrap' }}
                              width={window.innerWidth * 0.1}
                            >
                              {isView ? (
                                rimborso?.designazioni?.find(
                                  i => i.idDesignazione === a._id
                                )?.kmPercorsi
                              ) : (
                                <InputField
                                  id={`${a._id}-kmPercorsi`}
                                  align='center'
                                  variant='standard'
                                  placeholder='0'
                                  value={
                                    rimborso?.designazioni?.find(
                                      i => i.idDesignazione === a._id
                                    )?.kmPercorsi || undefined
                                  }
                                  onChange={x =>
                                    (!x || x < 1000) &&
                                    dispatch({
                                      type: SET_RIMBORSO,
                                      payload: {
                                        ...rimborso,
                                        designazioni:
                                          rimborso?.designazioni?.map(i =>
                                            i.idDesignazione === a._id
                                              ? {
                                                  ...i,
                                                  kmPercorsi: +x?.toFixed(0),
                                                }
                                              : i
                                          ),
                                      },
                                    })
                                  }
                                  errorFunc={errorKmPercorsi}
                                  type='number'
                                />
                              )}
                            </TableCell>
                          )}
                          {!isSelect && (
                            <TableCell
                              {...cellProps}
                              sx={{ ...cellProps.sx, whiteSpace: 'nowrap' }}
                              width={window.innerWidth * 0.1}
                            >
                              {isView ? (
                                formatEuro(
                                  rimborso?.designazioni?.find(
                                    i => i.idDesignazione === a._id
                                  )?.spese
                                )
                              ) : (
                                <InputField
                                  id={`${a._id}-spese`}
                                  align='center'
                                  variant='standard'
                                  placeholder='0.00'
                                  value={
                                    rimborso?.designazioni?.find(
                                      i => i.idDesignazione === a._id
                                    )?.spese || undefined
                                  }
                                  onChange={x =>
                                    (!x || x < 1000) &&
                                    dispatch({
                                      type: SET_RIMBORSO,
                                      payload: {
                                        ...rimborso,
                                        designazioni:
                                          rimborso?.designazioni?.map(i =>
                                            i.idDesignazione === a._id
                                              ? { ...i, spese: +x?.toFixed(2) }
                                              : i
                                          ),
                                      },
                                    })
                                  }
                                  errorFunc={errorSpese}
                                  type='number'
                                />
                              )}
                            </TableCell>
                          )}
                          {!isSelect && (
                            <TableCell
                              {...cellProps}
                              sx={{ ...cellProps.sx, whiteSpace: 'nowrap' }}
                              width={window.innerWidth * 0.1}
                            >
                              {formatEuro(totGara(a._id) || 0)}
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
    </Grid>
  )
}

const mapStateToProps = state => ({
  arbitro: state.home.arbitro,
  codiceUtente: state.home.codiceUtente,
  infoUtente: state.home.infoUtente,
  selectedDesignazioni: state.home.selectedDesignazioni,
  rimborsoMode: state.rimborsi.rimborsoMode,
  rimborso: state.rimborsi.rimborso,
})

const ConnectedDialogRimborso = connect(mapStateToProps)(DialogRimborso)

export default ConnectedDialogRimborso
