import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { SET_OMOLOGA } from '../container/risultato/types'

const DialogOmologa = ({ gara, iscrizioniLight, omologa }) => {
  const dispatch = useDispatch()

  const [squadraCasaName, setSquadraCasaName] = useState()
  const [squadraTrasfertaName, setSquadraTrasfertaName] = useState()

  // Set team names
  useEffect(() => {
    if (gara?.squadraCasaID)
      setSquadraCasaName(
        iscrizioniLight?.find(i => i.id === gara.squadraCasaID)?.nomeSquadra ||
          gara.squadraCasaID
      )
    if (gara?.squadraTrasfertaID)
      setSquadraTrasfertaName(
        iscrizioniLight?.find(i => i.id === gara.squadraTrasfertaID)
          ?.nomeSquadra || gara.squadraTrasfertaID
      )
  }, [gara, iscrizioniLight])

  // Set omologa
  useEffect(() => {
    dispatch({
      type: SET_OMOLOGA,
      payload: gara?.validHomologation,
    })
  }, [dispatch, gara])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_OMOLOGA })
  }, [dispatch])

  return (
    <>
      &nbsp;
      {((!gara || gara.length === 0) && (
        <Grid item xs={12}>
          Nessuna partita selezionata.
        </Grid>
      )) || (
        <Grid container spacing={3} direction='column'>
          <Grid item xs={12}>
            Il presente modulo permette di omologare il risultato della gara,
            dandogli così valore ufficiale.
          </Grid>
          &nbsp;
          {!!gara && (
            <Grid container item xs={12} spacing={3} alignItems='center'>
              <Grid item xs={12} md={6}>
                <center>
                  <b>{squadraCasaName}</b>
                  &nbsp;-&nbsp;
                  <b>{squadraTrasfertaName}</b>
                </center>
              </Grid>
              <Grid item xs={6} md={3}>
                <center>{gara.data || 'Data non definita'}</center>
              </Grid>
              <Grid item xs={6} md={3}>
                <center>{gara.ora || 'Ora non definita'}</center>
              </Grid>
            </Grid>
          )}
          &nbsp;
          {!!gara && (
            <Grid
              container
              item
              xs={12}
              spacing={3}
              alignItems='center'
              justifyContent='center'
            >
              <Grid item>
                <center>Gara omologata:</center>
              </Grid>
              <Grid item>
                <center>
                  <Checkbox
                    id='omologa'
                    checked={!!omologa}
                    onChange={event =>
                      dispatch({
                        type: SET_OMOLOGA,
                        payload: event.target.checked,
                      })
                    }
                  />
                </center>
              </Grid>
            </Grid>
          )}
          &nbsp;
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  iscrizioniLight: state.home.iscrizioniLight,
  omologa: state.risultato.omologa,
})

const ConnectedDialogOmologa = connect(mapStateToProps)(DialogOmologa)

export default ConnectedDialogOmologa
