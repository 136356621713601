import React, { useRef } from 'react'
import { connect, useDispatch } from 'react-redux'

import Button from './Button'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { SET_BACKEND_R } from '../container/home/types'

const ButtonUpload = ({
  buttonText,
  algorithm,
  fileName,
  runScript = false,
  uploadCampionato,
  codiceUtente,
  backendR,
}) => {
  const dispatch = useDispatch()

  const uploadFileRef = useRef()

  const { setFeedback } = useFeedback()

  const uploadFile = async (algorithm, file, fileName, runScript) => {
    if (file.type !== 'text/csv') {
      setFeedback('Il file deve essere in formato CSV.')
      return
    }

    if (!algorithm) {
      setFeedback('Algoritmo non definito.')
      return
    }

    const formData = new FormData()
    formData.append('File', file)
    try {
      await sendRequest(`algoritmi/checkfolder/${algorithm}`)
      await sendRequest(
        `algoritmi/upload/${algorithm}${fileName ? `/${fileName}` : ''}`,
        'POST',
        formData,
        { 'Content-Type': 'text/csv', Authorization: codiceUtente }
      )

      setFeedback('File caricato correttamente!', false)

      if (runScript) {
        await sendRequest(
          `algoritmi/run/${algorithm}${
            algorithm === '05_UpdateCalendari' ? `/${uploadCampionato}` : ''
          }`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )
        dispatch({
          type: SET_BACKEND_R,
          payload: { ...backendR, status: 'busy', process: algorithm },
        })
      }
    } catch (err) {
      console.log(err)
      setFeedback(err.message)
    }
  }

  return (
    <center>
      <input
        id={`upload-file-${algorithm}`}
        ref={uploadFileRef}
        type='file'
        accept='text/csv'
        style={{ display: 'none' }}
        onChange={event =>
          uploadFile(algorithm, event.target.files[0], fileName, runScript)
        }
      />
      <Button
        light={1}
        disabled={
          !backendR?.isCallable ||
          (algorithm === '05_UpdateCalendari' && !uploadCampionato)
        }
        onClick={() => uploadFileRef.current.click()}
      >
        {buttonText}
      </Button>
    </center>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  backendR: state.home.backendR,
})

const ConnectedButtonUpload = connect(mapStateToProps)(ButtonUpload)

export default ConnectedButtonUpload
