import { setWinningScore } from './utils-functions'

const errorNumber = x => typeof x !== 'number'
const errorNumber0 = x => errorNumber(x) || x < 0
const errorIntero = x => errorNumber(x) || x !== Math.round(x)
const errorNaturale = x => errorIntero(x) || x < 1
const errorNaturale0 = x => errorIntero(x) || x < 0

const errorPunteggioSet = (a, b, win = 25, diff = 2) =>
  errorNaturale0(a) ||
  errorNaturale0(b) ||
  !(
    (a === win && b <= win - diff) ||
    (b === win && a <= win - diff) ||
    (a > win && b === a - diff) ||
    (b > win && a === b - diff)
  )

const errorPunteggioSetN = (
  a,
  b,
  n,
  setVintiCasa,
  setVintiTrasferta,
  isPropaganda,
  isPriFin2223
) =>
  !['', null, undefined].includes(a?.[n - 1]) &&
  !['', null, undefined].includes(b?.[n - 1]) &&
  (errorPunteggioSet(
    a?.[n - 1],
    b?.[n - 1],
    setWinningScore(n, isPropaganda, isPriFin2223)
  ) ||
    errorLastSet(
      a,
      b,
      n,
      setVintiCasa,
      setVintiTrasferta,
      isPropaganda,
      isPriFin2223
    ))

const errorPunteggioGoldenSet = (a, b, isPropaganda, isPriFin2223) =>
  !['', null, undefined].includes(a) &&
  !['', null, undefined].includes(b) &&
  errorPunteggioSet(a, b, isPropaganda ? (isPriFin2223 ? 20 : 17) : 15)

const errorPunteggioMini = (a, b, n = 1) =>
  !['', null, undefined].includes(a?.[n - 1]) &&
  !['', null, undefined].includes(b?.[n - 1]) &&
  (errorNaturale0(a?.[n - 1]) || errorNaturale0(b?.[n - 1]))

const errorSetMini = (a, b) =>
  !['', null, undefined].includes(a) &&
  !['', null, undefined].includes(b) &&
  (errorNaturale0(a) || errorNaturale0(b))

const errorLastSet = (
  a,
  b,
  n,
  setVintiCasa,
  setVintiTrasferta,
  isPropaganda,
  isPriFin2223
) => {
  if (!a || !b || a.length !== b.length || n !== a.length) return false

  if (isPropaganda && !isPriFin2223) return false

  const N = a.length - 1
  if (
    (setVintiCasa > setVintiTrasferta && a[N] <= b[N]) ||
    (setVintiCasa < setVintiTrasferta && a[N] >= b[N])
  )
    return true
  return false
}

export {
  errorNumber,
  errorNumber0,
  errorIntero,
  errorNaturale,
  errorNaturale0,
  errorPunteggioSet,
  errorPunteggioSetN,
  errorPunteggioGoldenSet,
  errorPunteggioMini,
  errorSetMini,
  errorLastSet,
}
