import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { Bracket, Seed, SeedItem } from 'react-brackets'

import SelectionBar from '../components/SelectionBar'
import ButtonRunDownload from '../components/ButtonRunDownload'
import GridCircularProgress from '../components/GridCircularProgress'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import {
  sortSfide,
  sortGiornate,
  sortNumeriGara,
} from '../hooks/sort-functions'
import { rangeFun } from '../hooks/utils-functions'
import { SET_ERROR, SET_SELECTED_TEAMS } from '../container/home/types'

const Tabelloni = ({
  iscrizioniLight,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  isMobile,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Fasi finali'

  const [isLoading, setIsLoading] = useState()
  const [calendariT, setCalendariT] = useState()
  const [fasi, setFasi] = useState()
  const [uniqueSfide, setUniqueSfide] = useState()
  const [rounds, setRounds] = useState()
  const [isDouble, setIsDouble] = useState()

  // fetch calendari
  useEffect(() => {
    const fetchCalendari = async () => {
      setIsLoading(true)
      try {
        const calendariData = await sendRequest(
          `calendari/campionato/${selectedCampionato}/categoria/${selectedCategoria}/girone/finali`
        )

        if (!calendariData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i calendari.',
          })
        } else {
          setCalendariT(
            calendariData.sort((a, b) =>
              sortSfide(a.sfidaTabellone, b.sfidaTabellone)
            )
          )
        }
      } catch (err) {
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (
      !!selectedCampionato &&
      !!selectedCategoria &&
      selectedCategoria !== availableCategorie[0]
    ) {
      fetchCalendari()
    } else {
      setCalendariT()
    }
  }, [availableCategorie, dispatch, selectedCampionato, selectedCategoria])

  useEffect(() => {
    dispatch({
      type: SET_SELECTED_TEAMS,
      payload: calendariT
        ?.map(c => [c.squadraCasaID, c.squadraTrasfertaID])
        ?.flat()
        ?.filter((v, i, s) => s.indexOf(v) === i),
    })
  }, [calendariT, dispatch])

  useEffect(() => {
    setFasi(
      calendariT
        ?.map(c => c.giornata)
        ?.filter((v, i, s) => s.indexOf(v) === i)
        ?.sort((a, b) => sortGiornate(a, b))
    )
  }, [calendariT])

  useEffect(() => {
    setUniqueSfide(
      calendariT
        ?.map(c => {
          return {
            fase: c.giornata,
            sfidaTabellone: c.sfidaTabellone,
            sfidaVincente: c.sfidaVincente,
          }
        })
        ?.filter(
          (v, i, s) =>
            i ===
            s.findIndex(
              f =>
                f.sfidaTabellone === v.sfidaTabellone &&
                f.sfidaVincente === v.sfidaVincente
            )
        )
        ?.sort((a, b) => sortSfide(a.sfidaTabellone, b.sfidaTabellone))
    )
  }, [calendariT])

  const getSfideByFase = useCallback(
    fase =>
      uniqueSfide
        ?.filter(u => u.fase === fase)
        ?.sort((a, b) => sortSfide(a.sfidaTabellone, b.sfidaTabellone)),
    [uniqueSfide]
  )

  const getGareBySfida = useCallback(
    sfida =>
      calendariT
        ?.filter(c => +c.sfidaTabellone === sfida)
        ?.sort((a, b) => sortNumeriGara(a.numeroGara, b.numeroGara)),
    [calendariT]
  )

  // if there are 4 semifinals, it's double
  useEffect(() => {
    setIsDouble(getSfideByFase(fasi?.[fasi?.length - 2])?.length === 4)
  }, [fasi, getSfideByFase])

  const getTeamNameById = useCallback(
    id => iscrizioniLight?.find(i => i.id === id)?.nomeSquadra || id,
    [iscrizioniLight]
  )

  const getTitleByFase = useCallback(
    fase => {
      let N = getSfideByFase(fase)?.length
      let N_1 = getSfideByFase((+fase + 1).toString())?.length

      if (N % 2 !== 0) N += 1
      if (N_1 % 2 !== 0) N += 2

      if (isDouble) N /= 2

      return (
        (N <= 4 && fase === fasi?.[fasi?.length - 1] && 'Finali') ||
        (N <= 4 && fase === fasi?.[fasi?.length - 2] && 'Semifinali') ||
        (N === 4 && 'Quarti') ||
        (N === 8 && 'Ottavi') ||
        (N === 16 && 'Sedicesimi') ||
        (N === 32 && 'Trentaduesimi') ||
        `Fase ${fase}`
      )
    },
    [fasi, getSfideByFase, isDouble]
  )

  const getSubtitleByFase = useCallback(
    fase => {
      const nGareBySfida = getSfideByFase(fase)?.map(
        sfida => getGareBySfida(+sfida.sfidaTabellone)?.length
      )

      return nGareBySfida?.every(n => n === 1)
        ? 'Gara secca'
        : nGareBySfida?.every(n => n === 2)
        ? 'Andata e ritorno'
        : '-'
    },
    [getGareBySfida, getSfideByFase]
  )

  useEffect(() => {
    if (fasi?.length > 0 && calendariT?.length > 0)
      setRounds(
        fasi?.map(fase => {
          const seeds = getSfideByFase(fase)?.map(sfida => {
            const nSfida = +sfida.sfidaTabellone
            const gare = getGareBySfida(nSfida)
            return {
              id: nSfida,
              fase,
              sfida: nSfida,
              gare,
              teams: [
                { name: getTeamNameById(gare?.[0]?.squadraCasaID) },
                { name: getTeamNameById(gare?.[0]?.squadraTrasfertaID) },
              ],
            }
          })

          // manage ripescaggi
          for (let f of fasi?.filter(x => x >= fase)) {
            const lonelySfide = getSfideByFase(f)?.filter(
              sfida =>
                getSfideByFase(f)?.filter(
                  s => s.sfidaVincente === sfida.sfidaVincente
                )?.length === 1
            )

            const getInsertIndex = (ss, ff) =>
              ff === fase
                ? ss
                : getInsertIndex(
                    +getSfideByFase((ff - 1).toString())
                      ?.filter(x => +x.sfidaVincente === ss)
                      ?.sort(
                        (a, b) => +b.sfidaTabellone - +a.sfidaTabellone
                      )?.[0]?.sfidaTabellone,
                    (ff - 1).toString()
                  )

            for (let lonelySfida of lonelySfide) {
              const lonelySfidaSfida = +lonelySfida.sfidaTabellone

              const lonelySfidaIndex =
                seeds
                  ?.map(seed => seed.sfida)
                  ?.indexOf(getInsertIndex(+lonelySfidaSfida, f)) + 1

              const ripescaggioSeed = {
                id: lonelySfidaSfida,
                fase,
                isRipescaggio: true,
                isRipescaggioPlaceholder: f !== fase,
              }

              seeds.splice(
                lonelySfidaIndex,
                0,
                ...rangeFun(2 ** (f - fase))?.map(n => {
                  return {
                    ...ripescaggioSeed,
                    id: ripescaggioSeed.id + n / (2 ** (f - fase) + 1),
                  }
                })
              )
            }
          }

          return {
            title: getTitleByFase(fase) + '|' + getSubtitleByFase(fase),
            seeds: seeds,
          }
        })
      )
  }, [
    calendariT,
    fasi,
    getGareBySfida,
    getSfideByFase,
    getSubtitleByFase,
    getTeamNameById,
    getTitleByFase,
  ])

  const MyTitle = title => {
    const [Title, Subtitle] = title.split('|')
    return (
      <>
        <div
          style={{
            padding: 5,
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'center',
          }}
        >
          <b>{Title}</b>
        </div>
        <div
          style={{
            padding: 5,
            display: 'flex',
            fontSize: '0.75em',
            fontStyle: 'italic',
            justifyContent: 'center',
          }}
        >
          {Subtitle}
        </div>
      </>
    )
  }

  const MySeed = ({ seed }) => {
    const GaraStyle = {
      paddingTop: 5,
      paddingBottom: 5,
      color: colours.black,
      backgroundColor: colours.greyLight,
    }

    const TeamStyle = {
      paddingTop: 10,
      paddingBottom: 10,
      color: colours.black,
      backgroundColor: colours.greyVeryLight,
    }

    const MySeedItem = ({ seed, isFinal12, isFinal34 }) => {
      const { isRipescaggio, isRipescaggioPlaceholder } = seed
      return (
        <SeedItem>
          <div
            style={
              isRipescaggio
                ? {
                    ...GaraStyle,
                    color: colours.greyVeryLight,
                    backgroundColor: colours.greyVeryLight,
                  }
                : isFinal12
                ? {
                    ...GaraStyle,
                    fontWeight: 'bold',
                    backgroundColor: colours.gold,
                  }
                : isFinal34
                ? {
                    ...GaraStyle,
                    fontWeight: 'bold',
                    backgroundColor: colours.bronze,
                  }
                : GaraStyle
            }
          >
            {isRipescaggio
              ? '-'
              : isFinal12
              ? 'Finale 1°/2° posto'
              : isFinal34
              ? 'Finale 3°/4° posto'
              : seed.gare?.length > 1
              ? `Gare ${seed.gare
                  .map(gara => gara.numeroGara || '??')
                  ?.join(' e ')}`
              : seed.gare?.length === 1
              ? `Gara ${seed.gare[0]?.numeroGara}`
              : ''}
          </div>
          <div
            style={
              isRipescaggioPlaceholder
                ? { ...TeamStyle, color: colours.greyVeryLight }
                : TeamStyle
            }
          >
            {isRipescaggioPlaceholder
              ? '-'
              : isRipescaggio
              ? 'RIPESCAGGIO'
              : seed.teams[0]?.name}
          </div>
          <div
            style={
              isRipescaggio
                ? { ...TeamStyle, color: colours.greyVeryLight }
                : TeamStyle
            }
          >
            {isRipescaggio ? '-' : seed.teams[1]?.name}
          </div>
          <div
            style={
              isRipescaggio
                ? {
                    ...GaraStyle,
                    color: colours.greyVeryLight,
                    backgroundColor: colours.greyVeryLight,
                  }
                : isFinal12
                ? { ...GaraStyle, backgroundColor: colours.gold }
                : isFinal34
                ? { ...GaraStyle, backgroundColor: colours.bronze }
                : GaraStyle
            }
          >
            {isRipescaggio
              ? '-'
              : seed.gare?.length > 1
              ? seed.gare.map(gara => gara.data || '??')?.join(' e ')
              : seed.gare?.length === 1
              ? seed.gare[0]?.data || '??'
              : ''}
          </div>
        </SeedItem>
      )
    }

    const roundsWidth = window.innerWidth / 5

    if (
      seed.fase === fasi?.[fasi?.length - 1] &&
      getSfideByFase(seed.fase)?.length <= 4
    ) {
      const sfidePrecedenti = calendariT?.filter(
        c => +c.sfidaVincente === seed.sfida
      )

      // skip 3°/4° posto
      if (sfidePrecedenti?.length === 0) return

      // return 1°/2° posto and 3°/4° posto
      if (sfidePrecedenti?.length > 0) {
        const sfidePerdenti = sfidePrecedenti
          ?.map(c => +c.sfidaPerdente)
          ?.filter((v, i, s) => s.indexOf(v) === i)

        const seed34 =
          sfidePerdenti?.length === 1 &&
          rounds
            ?.map(r => r.seeds)
            ?.flat()
            ?.find(s => s.fase === seed.fase && s.sfida === sfidePerdenti[0])

        return (
          <Seed style={{ width: roundsWidth }} mobileBreakpoint={0}>
            <MySeedItem seed={seed} isFinal12 />
            &nbsp;
            {seed34 && <MySeedItem seed={seed34} isFinal34 />}
          </Seed>
        )
      }
    } else {
      return (
        <Seed style={{ width: roundsWidth }} mobileBreakpoint={0}>
          <MySeedItem seed={seed} />
        </Seed>
      )
    }
  }

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Fasi finali</h2>
        </center>
      </Grid>
      <SelectionBar includeGirone={false} includeTeam={false} />
      &nbsp;
      <Grid container item xs={12}>
        <Grid item xs md />
        <Grid item xs={12} md={4}>
          <ButtonRunDownload
            buttonText='Scarica contatti'
            algorithm='07_GenerateExcelGironi'
            type='XLSX'
          />
        </Grid>
      </Grid>
      &nbsp;
      {(isLoading && <GridCircularProgress />) ||
        (!calendariT?.length && (
          <Grid item xs={12}>
            <center>
              <h4>Nessun tabellone da visualizzare.</h4>
            </center>
          </Grid>
        )) ||
        (!!iscrizioniLight && !!rounds && (
          <>
            {isMobile && (
              <>
                <Grid item xs={12} paddingX={3}>
                  Trascina a sinistra o a destra per vedere le altre fasi.
                </Grid>
                &nbsp;
              </>
            )}
            <Grid item xs={12}>
              <Bracket
                rounds={rounds}
                roundTitleComponent={MyTitle}
                renderSeedComponent={MySeed}
              />
            </Grid>
          </>
        ))}
    </Grid>
  )
}

const mapStateToProps = state => ({
  iscrizioniLight: state.home.iscrizioniLight,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  isMobile: state.home.isMobile,
})

const ConnectedTabelloni = connect(mapStateToProps)(Tabelloni)

export default ConnectedTabelloni
