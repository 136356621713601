import React, { useState } from 'react'

import Button from './Button'
import GridCircularProgress from './GridCircularProgress'
import { useFeedback } from '../hooks/feedback-hook'
import { downloadFile } from '../hooks/download-functions'
import { colours } from '../settings/settings'

const ButtonDownload = ({ algorithm, type }) => {
  const { setFeedback } = useFeedback()

  const [loading, setLoading] = useState()

  const isTxt = type === 'TXT'
  const isPdf = type === 'PDF'
  const isExcel = type === 'XLSX'

  return (
    <center>
      <Button
        disabled={loading}
        backgroundcolour={
          (isTxt && colours.greyLight) ||
          (isPdf && colours.red) ||
          (isExcel && colours.greenExcel) ||
          colours.blueDark
        }
        hovercolour={
          (isTxt && colours.grey) ||
          (isPdf && colours.redDark) ||
          (isExcel && colours.greenExcelHover) ||
          colours.blueVeryDark
        }
        onClick={async () => {
          setLoading(true)
          await downloadFile(algorithm, type, setFeedback)
          setLoading()
        }}
      >
        {(loading && <GridCircularProgress color='inherit' size='1rem' />) ||
          (type === 'TXT' && 'Scarica LOG') ||
          (type === 'CSV' && 'Scarica CSV') ||
          (type === 'PDF' &&
            ((algorithm === '02_GenerateGironi' && 'Scarica PDF / categoria') ||
              (algorithm === '03_GenerateCalendari' &&
                'Scarica PDF / girone'))) ||
          (type === 'XLSX' &&
            algorithm === '03_GenerateCalendari' &&
            'Scarica XLSX / girone')}
      </Button>
    </center>
  )
}

export default ButtonDownload
