import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import Button from './Button'
import InputField from './InputField'
import {
  titlesIscrizioni as titles,
  phrasesIscrizioni as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { formatIndirizzo, formatBodyField } from '../hooks/utils-functions'
import { SET_DUMMY_UPDATE } from '../container/home/types'

const DialogIndirizzo = ({
  admin,
  codiceUtente,
  dummyUpdate,
  garaIndirizzo,
  iscrizioneIndirizzo,
}) => {
  const dispatch = useDispatch()

  const [presso, setPresso] = useState('')
  const [indirizzo, setIndirizzo] = useState('')
  const [citta, setCitta] = useState('')

  // utils functions
  const fIndirizzo = formatIndirizzo(iscrizioneIndirizzo, garaIndirizzo)

  const { setFeedback } = useFeedback()

  // update address
  const updateIndirizzo = async () => {
    if (!codiceUtente) {
      setFeedback('Utente non trovato!')
    } else if (!garaIndirizzo?._id) {
      setFeedback('Nessuna gara selezionata!')
    } else if (!indirizzo) {
      setFeedback('Indirizzo non trovato!')
    } else if (!citta) {
      setFeedback('Città non trovata!')
    } else {
      try {
        await sendRequest(
          `calendari/${garaIndirizzo._id}/indirizzo`,
          'PATCH',
          JSON.stringify({ presso, indirizzo, citta }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
        setFeedback(
          phrases.phrasesIndirizzoSuccess,
          false,
          titles.titleIndirizzo
        )
      } catch (err) {
        setFeedback(err.message)
      }
    }
  }

  return (
    (!garaIndirizzo && 'Nessuna partita selezionata.') || (
      <>
        {(!fIndirizzo && <i>Indirizzo non trovato.</i>) || (
          <>
            <b>{fIndirizzo}</b>
            <br />
            <br />
            <a
              target='_blank'
              rel='noreferrer noopener'
              style={{ textDecoration: 'none', margin: 5 }}
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                fIndirizzo
              )}`}
            >
              Visualizza su Google Maps
            </a>
          </>
        )}
        {admin && (
          <Grid container item xs={12} paddingTop={5} spacing={2}>
            <Grid item xs={12}>
              <b>Aggiorna indirizzo</b>
            </Grid>
            <Grid item xs={12}>
              <InputField
                id='presso'
                value={presso}
                onChange={i => setPresso(formatBodyField(i, 'upper'))}
                label='Istituto o centro sportivo'
                adornment='place'
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                id='indirizzo'
                value={indirizzo}
                onChange={i => setIndirizzo(formatBodyField(i, 'upper'))}
                label='Indirizzo'
                adornment='zone'
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                id='città'
                value={citta}
                onChange={i => setCitta(formatBodyField(i, 'upper'))}
                label='Città'
                adornment='city'
              />
            </Grid>
            <Grid item xs={12}>
              <center>
                <Button onClick={updateIndirizzo}>Aggiorna</Button>
              </center>
            </Grid>
          </Grid>
        )}
      </>
    )
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  garaIndirizzo: state.calendari.garaIndirizzo,
  iscrizioneIndirizzo: state.calendari.iscrizioneIndirizzo,
})

const ConnectedDialogIndirizzo = connect(mapStateToProps)(DialogIndirizzo)

export default ConnectedDialogIndirizzo
