import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import SelectionBar from '../components/SelectionBar'
import Button from '../components/Button'
import ButtonRun from '../components/ButtonRun'
import ButtonUpload from '../components/ButtonUpload'
import ButtonDownload from '../components/ButtonDownload'
import GridCircularProgress from '../components/GridCircularProgress'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR } from '../container/home/types'
import { SET_ISCRIZIONI } from '../container/iscrizioni/types'

const Algoritmi = ({
  codiceUtente,
  iscrizioniLight,
  backendR,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Algoritmi'

  // fetch iscrizioni
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(
          `iscrizioni/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le squadre iscritte.',
          })
        } else {
          dispatch({ type: SET_ISCRIZIONI, payload: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (!!codiceUtente && !!selectedCampionato) {
      fetch()
    } else {
      dispatch({ type: SET_ISCRIZIONI })
    }
  }, [codiceUtente, dispatch, selectedCampionato])

  const Arrow = () => {
    return (
      <Grid item xs>
        <center>
          <ArrowDownwardIcon />
        </center>
      </Grid>
    )
  }

  const GridColumn = ({ algorithm, title }) => {
    return (
      <Grid
        container
        item
        xs={12}
        md={4}
        rowSpacing={1}
        direction='column'
        alignItems='center'
      >
        &nbsp;
        <Grid item>
          <center>
            <h3>{title}</h3>
          </center>
        </Grid>
        {['01_GenerateTable'].includes(algorithm) && (
          <Grid item>
            <center>
              <Button transparent={1}>
                {iscrizioniLight?.filter(i =>
                  selectedCategoria &&
                  selectedCategoria !== availableCategorie[0]
                    ? i.categoria === selectedCategoria
                    : true
                )?.length || 0}{' '}
                squadre iscritte
              </Button>
            </center>
          </Grid>
        )}
        {['02_GenerateGironi', '03_GenerateCalendari'].includes(algorithm) && (
          <Grid item>
            <ButtonUpload
              buttonText='Carica CSV'
              algorithm={algorithm}
              fileName='Input'
            />
          </Grid>
        )}
        <Arrow />
        <Grid item>
          <ButtonRun
            buttonText={`Genera ${
              (algorithm === '01_GenerateTable' && 'tabella iscrizioni') ||
              (algorithm === '02_GenerateGironi' && 'gironi') ||
              (algorithm === '03_GenerateCalendari' && 'calendari') ||
              algorithm
            }`}
            algorithm={algorithm}
          />
        </Grid>
        <Arrow />
        {[
          '01_GenerateTable',
          '02_GenerateGironi',
          '03_GenerateCalendari',
        ].includes(algorithm) && (
          <Grid item>
            <ButtonDownload algorithm={algorithm} type='TXT' />
          </Grid>
        )}
        <Grid item>
          <ButtonDownload algorithm={algorithm} type='CSV' />
        </Grid>
        {['02_GenerateGironi', '03_GenerateCalendari'].includes(algorithm) && (
          <Grid item>
            <ButtonDownload algorithm={algorithm} type='PDF' />
          </Grid>
        )}
        {['03_GenerateCalendari'].includes(algorithm) && (
          <Grid item>
            <ButtonDownload algorithm={algorithm} type='XLSX' />
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Algoritmi</h2>
        </center>
      </Grid>
      <SelectionBar includeGirone={false} includeTeam={false} />
      <Grid item xs={12}>
        <center>
          <h4>
            {backendR?.status === 'available'
              ? 'Gli algoritmi sono pronti a partire!'
              : backendR?.status === 'busy'
              ? `È in esecuzione l'algoritmo ${backendR?.process}`
              : 'Lo stato degli algoritmi è sconosciuto.'}
          </h4>
        </center>
      </Grid>
      {backendR?.status === 'busy' && <GridCircularProgress />}
      <Grid container alignItems='flex-start'>
        <GridColumn algorithm='01_GenerateTable' title='Iscrizioni' />
        <GridColumn algorithm='02_GenerateGironi' title='Gironi' />
        <GridColumn algorithm='03_GenerateCalendari' title='Calendari' />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  iscrizioniLight: state.home.iscrizioniLight,
  backendR: state.home.backendR,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
})

const ConnectedAlgoritmi = connect(mapStateToProps)(Algoritmi)

export default ConnectedAlgoritmi
