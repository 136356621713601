import {
  SET_AFFILIAZIONI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_DELETE_ALSO_ISCRIZIONI,
  SET_AFFILIAZIONE,
  SET_AFFILIAZIONE_FIELD,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
}

export const affiliazioniReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AFFILIAZIONI:
      return { ...state, affiliazioni: action.payload }
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    case SET_DELETE_ALSO_ISCRIZIONI:
      return { ...state, deleteAlsoIscrizioni: action.payload }
    case SET_AFFILIAZIONE:
      return { ...state, affiliazione: action.payload }
    case SET_AFFILIAZIONE_FIELD:
      return {
        ...state,
        affiliazione: {
          ...state.affiliazione,
          [action.payload.field]: action.payload.value,
        },
      }
    default:
      return state
  }
}
