import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

import Button from '../components/Button'
import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import ButtonGroup from '../components/ButtonGroup'
import SortableTableHead from '../components/SortableTableHead'
import GridCircularProgress from '../components/GridCircularProgress'
import { colours, titlesIscrizioni as titles } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sortCodiceSocieta } from '../hooks/sort-functions'
import { useIscrizione } from '../hooks/iscrizione-functions'
import { SET_ERROR } from '../container/home/types'
import {
  SET_ISCRIZIONI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_GROUPING,
  SET_ISCRIZIONE,
} from '../container/iscrizioni/types'

const availableGroupings = ['tutte', 'per società', 'con deroga']

const Iscrizioni = ({
  admin,
  codiceUtente,
  affiliazioniLight,
  selectedCampionatoIscrizioniAperte,
  availableCategorie,
  selectedCategoria,
  selectedCampionato,
  dummyUpdate,
  iscrizioni,
  sortingColumn,
  sortingAscending,
  grouping,
  deleteAlsoGare,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Iscrizioni'

  let columns = []

  if (admin && grouping !== 'per società')
    columns = [
      ...columns,
      { label: 'Codice', sortable: true, sortingField: 'codiceSocieta' },
      { label: 'Società', sortable: false },
    ]

  if (
    !selectedCategoria ||
    selectedCategoria === availableCategorie[0] ||
    !admin
  )
    columns = [
      ...columns,
      { label: 'Categoria', sortable: true, sortingField: 'categoria' },
    ]

  columns = [...columns, { label: 'Nome squadra', sortable: false }]

  if (!admin)
    columns = [
      ...columns,
      { label: 'Giorno di gara', sortable: false },
      { label: 'Orario di gara', sortable: false },
    ]

  if (admin && grouping === 'con deroga')
    columns = [
      ...columns,
      { label: 'Motivo deroga', sortable: false },
      { label: 'Data deroga', sortable: false },
    ]

  if (!admin || grouping !== 'con deroga')
    columns = [
      ...columns,
      { label: 'Stato', sortable: true, sortingField: 'status' },
      { label: '', sortable: false },
    ]

  const [isLoading, setIsLoading] = useState()

  const { editIscrizione, deleteIscrizione } = useIscrizione(
    admin,
    codiceUtente,
    dummyUpdate
  )

  // state to manage the delete dialog
  const [openDelete, setOpenDelete] = useState(false)
  const [deletingIscrizione, setDeletingIscrizione] = useState()

  const handleClickOpenDelete = i => {
    setDeletingIscrizione(i)
    setOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const confirmDelete = async () => {
    await deleteIscrizione(deletingIscrizione?.id, deleteAlsoGare)
    setDeletingIscrizione()
    handleCloseDelete()
  }

  // set default function
  if (!grouping)
    dispatch({ type: SET_GROUPING, payload: availableGroupings[0] })

  // fetch iscrizioni
  useEffect(() => {
    const fetchIscrizioni = async () => {
      setIsLoading(true)
      try {
        const iscrizioniData = await sendRequest(
          `iscrizioni/campionato/${selectedCampionato}${
            admin ? '' : `/societa/${codiceUtente}`
          }`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!iscrizioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le squadre iscritte.',
          })
        } else {
          dispatch({ type: SET_ISCRIZIONI, payload: iscrizioniData.reverse() })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (!!codiceUtente && !!selectedCampionato) {
      fetchIscrizioni()
    } else {
      dispatch({ type: SET_ISCRIZIONI })
    }
  }, [admin, codiceUtente, dispatch, dummyUpdate, selectedCampionato])

  const IscrizioniTable = ({ codiceSocieta }) => {
    if (
      iscrizioni
        ?.filter(
          i =>
            i.categoria === selectedCategoria ||
            selectedCategoria === availableCategorie[0] ||
            !admin
        )
        ?.filter(i => i.codiceSocieta === codiceSocieta || !codiceSocieta)
        ?.filter(i => (grouping === 'con deroga' ? i.hasDeroga : true))
        ?.length === 0
    )
      return null

    return (
      <>
        &nbsp;
        {!!codiceSocieta && (
          <Grid item paddingX={3}>
            <h4>
              Società {codiceSocieta} -{' '}
              {
                affiliazioniLight?.find(
                  a => a.codiceSocieta === codiceSocieta.toString()
                )?.nomeSocieta
              }{' '}
              -{' '}
              {
                iscrizioni
                  ?.filter(
                    i =>
                      i.categoria === selectedCategoria ||
                      selectedCategoria === availableCategorie[0] ||
                      !admin
                  )
                  ?.filter(
                    i => i.codiceSocieta === codiceSocieta || !codiceSocieta
                  )
                  ?.filter(i =>
                    grouping === 'con deroga' ? i.hasDeroga : true
                  )?.length
              }{' '}
              squadre iscritte
            </h4>
          </Grid>
        )}
        <TableContainer component={Paper}>
          <Table size='small'>
            <SortableTableHead
              table={iscrizioni.map(i => {
                return { ...i, codiceSocieta: +i.codiceSocieta }
              })}
              setTable={t => dispatch({ type: SET_ISCRIZIONI, payload: t })}
              columns={columns}
              sortingColumn={sortingColumn}
              setSortingColumn={SET_SORTING_COLUMN}
              sortingAscending={sortingAscending}
              setSortingAscending={SET_SORTING_ASCENDING}
            />
            <TableBody>
              {iscrizioni
                ?.filter(
                  i =>
                    i.categoria === selectedCategoria ||
                    selectedCategoria === availableCategorie[0] ||
                    !admin
                )
                ?.filter(
                  i => i.codiceSocieta === codiceSocieta || !codiceSocieta
                )
                ?.filter(i => (grouping === 'con deroga' ? i.hasDeroga : true))
                ?.map((i, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: colours.white,
                        ':hover': { backgroundColor: colours.greyVeryLight },
                      }}
                      onClick={() => editIscrizione(i.id)}
                    >
                      {admin && grouping !== 'per società' && (
                        <TableCell align='center'>{i.codiceSocieta}</TableCell>
                      )}
                      {admin && grouping !== 'per società' && (
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {
                            affiliazioniLight?.find(
                              a =>
                                a.codiceSocieta === i.codiceSocieta.toString()
                            )?.nomeSocieta
                          }
                        </TableCell>
                      )}
                      {(selectedCategoria === availableCategorie[0] ||
                        !admin) && (
                        <TableCell align='center'>
                          <Chip variant='outlined' label={i.categoria} />
                        </TableCell>
                      )}
                      <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                        {i.hasDeroga ? (
                          <Grid
                            container
                            item
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Grid item>{i.nomeSquadra}</Grid>
                            <Grid item>
                              <Tooltip title='Deroga'>
                                <PriorityHighIcon color='error' />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        ) : (
                          i.nomeSquadra
                        )}
                      </TableCell>
                      {!admin && (
                        <TableCell align='center'>
                          {!!i.senzaPalestra
                            ? 'senza palestra'
                            : i.giorno && (
                                <Chip variant='outlined' label={i.giorno} />
                              )}
                        </TableCell>
                      )}
                      {!admin && (
                        <TableCell align='center'>
                          {!!i.senzaPalestra
                            ? 'senza palestra'
                            : i.orario && (
                                <Chip variant='outlined' label={i.orario} />
                              )}
                        </TableCell>
                      )}
                      {admin && grouping === 'con deroga' && (
                        <TableCell align='center'>{i.motivoDeroga}</TableCell>
                      )}
                      {admin && grouping === 'con deroga' && (
                        <TableCell align='center'>
                          {i.timeDeroga
                            ?.split('T')?.[0]
                            ?.split('-')
                            ?.reverse()
                            ?.join('/')}
                        </TableCell>
                      )}
                      {(!admin || grouping !== 'con deroga') && (
                        <TableCell align='center'>
                          <Chip
                            variant={
                              i.status === 'complete' ? 'outlined' : 'filled'
                            }
                            label={
                              i.status === 'complete'
                                ? 'completa'
                                : i.status === 'incomplete'
                                ? 'da completare'
                                : 'sconosciuto'
                            }
                            color={
                              i.status === 'complete'
                                ? 'success'
                                : i.status === 'incomplete'
                                ? 'warning'
                                : 'default'
                            }
                          />
                        </TableCell>
                      )}
                      {(!admin || grouping !== 'con deroga') && (
                        <TableCell align='center'>
                          {(admin ||
                            (i.codiceCampionato === selectedCampionato &&
                              selectedCampionatoIscrizioniAperte)) && (
                            <Grid item xs>
                              <IconButton
                                style={{ color: colours.red }}
                                onClick={e => {
                                  e.stopPropagation()
                                  handleClickOpenDelete(i)
                                }}
                              >
                                <Tooltip title='Elimina'>
                                  <DeleteIcon />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Iscrizioni</h2>
        </center>
      </Grid>
      {admin && (
        <>
          <Grid item xs={12}>
            <center>
              <Link
                to='/iscrizione'
                style={{ textDecoration: 'none' }}
                onClick={() => dispatch({ type: SET_ISCRIZIONE })}
              >
                <Button>Nuova iscrizione</Button>
              </Link>
            </center>
          </Grid>
          &nbsp;
        </>
      )}
      <SelectionBar
        includeCategoria={!!admin}
        includeGirone={false}
        includeTeam={false}
      />
      {(isLoading && <GridCircularProgress />) ||
        (!iscrizioni && (
          <Grid item xs={12}>
            <center>
              <h4>Elenco delle iscrizioni non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (iscrizioni.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Non è ancora stata iscritta nessuna squadra.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            {!admin && (
              <Grid item xs={12}>
                <center>
                  {(!selectedCampionatoIscrizioniAperte && (
                    <h4 style={{ color: colours.red }}>
                      Le iscrizioni a questo campionato sono chiuse.
                      <br />
                      <br />
                      È comunque possibile modificare i contatti dei referenti e
                      gli indirizzi delle palestre.
                      <br />
                      In caso di modifiche, si consiglia di avvisare le squadre
                      avversarie.
                      <br />
                      <br />
                      Per altre richieste scrivere a{' '}
                      <a href='mailto:iscrizioni@pgsmilano.org'>
                        iscrizioni@pgsmilano.org
                      </a>
                      .
                    </h4>
                  )) || (
                    <p>
                      Per domande sulle iscrizioni ai campionati scrivere a{' '}
                      <a href='mailto:iscrizioni@pgsmilano.org'>
                        iscrizioni@pgsmilano.org
                      </a>
                      .
                    </p>
                  )}
                </center>
              </Grid>
            )}
            {admin && (
              <Grid item xs={12}>
                <center>
                  <ButtonGroup
                    list={availableGroupings}
                    value={grouping}
                    func={i => dispatch({ type: SET_GROUPING, payload: i })}
                  />
                </center>
              </Grid>
            )}
            {admin && (
              <Grid container item>
                {(selectedCampionato && iscrizioni?.length === 0 && (
                  <Grid item xs={12}>
                    <center>
                      <h4>Nessuna squadra iscritta a questo campionato.</h4>
                    </center>
                  </Grid>
                )) ||
                  (grouping === 'tutte' && <IscrizioniTable />) ||
                  (grouping === 'per società' &&
                    iscrizioni
                      .map(i => i.codiceSocieta)
                      .filter((v, i, s) => s.indexOf(v) === i).length > 0 &&
                    iscrizioni
                      .map(i => i.codiceSocieta)
                      .filter((v, i, s) => s.indexOf(v) === i)
                      .sort((a, b) => sortCodiceSocieta(a, b))
                      .map(codiceSocieta => (
                        <IscrizioniTable
                          key={codiceSocieta}
                          codiceSocieta={codiceSocieta}
                        />
                      ))) ||
                  (grouping === 'con deroga' && <IscrizioniTable />)}
              </Grid>
            )}
            {!admin && <IscrizioniTable />}
          </Grid>
        )}
      <DialogMini
        open={openDelete}
        handleClose={handleCloseDelete}
        title={titles.titleDelete}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmDelete}
        colourBackground={colours.red}
        colourHover={colours.redDark}
        dialogDeleteIscrizione={true}
        team={deletingIscrizione}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  affiliazioniLight: state.home.affiliazioniLight,
  selectedCampionatoIscrizioniAperte:
    state.home.selectedCampionatoIscrizioniAperte,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  selectedCampionato: state.home.selectedCampionato,
  dummyUpdate: state.home.dummyUpdate,
  iscrizioni: state.iscrizioni.iscrizioni,
  sortingColumn: state.iscrizioni.sortingColumn,
  sortingAscending: state.iscrizioni.sortingAscending,
  grouping: state.iscrizioni.grouping,
  deleteAlsoGare: state.iscrizioni.deleteAlsoGare,
})

const ConnectedIscrizioni = connect(mapStateToProps)(Iscrizioni)

export default ConnectedIscrizioni
