import { useDispatch } from 'react-redux'

import { SET_FEEDBACK } from '../container/home/types'

export const useFeedback = () => {
  const dispatch = useDispatch()

  const setFeedback = (phrase, error = true, title, triggerPath) =>
    dispatch({
      type: SET_FEEDBACK,
      payload: { error, phrase, title, triggerPath },
    })

  return { setFeedback }
}
