import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import InputField from './InputField'
import { errorNaturale0 } from '../hooks/error-functions'
import {
  SET_PENALITA_CASA,
  SET_PENALITA_TRASFERTA,
} from '../container/risultato/types'

// error functions
const errorPenalita = x => x !== undefined && x !== null && errorNaturale0(x)

const DialogPenalita = ({
  gara,
  iscrizioniLight,
  penalitaCasa,
  penalitaTrasferta,
}) => {
  const dispatch = useDispatch()

  const [squadraCasaName, setSquadraCasaName] = useState()
  const [squadraTrasfertaName, setSquadraTrasfertaName] = useState()

  // Set team names
  useEffect(() => {
    if (gara?.squadraCasaID)
      setSquadraCasaName(
        iscrizioniLight?.find(i => i.id === gara.squadraCasaID)?.nomeSquadra ||
          gara.squadraCasaID
      )
    if (gara?.squadraTrasfertaID)
      setSquadraTrasfertaName(
        iscrizioniLight?.find(i => i.id === gara.squadraTrasfertaID)
          ?.nomeSquadra || gara.squadraTrasfertaID
      )
  }, [gara, iscrizioniLight])

  // Reset inputs
  useEffect(() => {
    dispatch({
      type: SET_PENALITA_CASA,
      payload: gara?.penalitaCasa,
    })
    dispatch({
      type: SET_PENALITA_TRASFERTA,
      payload: gara?.penalitaTrasferta,
    })
  }, [dispatch, gara])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_PENALITA_CASA })
      dispatch({ type: SET_PENALITA_TRASFERTA })
    }
  }, [dispatch])

  return (
    <>
      &nbsp;
      {((!gara || gara.length === 0) && (
        <Grid item xs={12}>
          Nessuna partita selezionata.
        </Grid>
      )) || (
        <Grid container spacing={3} direction='column'>
          <Grid item xs={12}>
            Il presente modulo permette di assegnare alle squadre dei punti di
            penalità, che verranno sottratti dal totale dei punti ottenuti in
            classifica.
          </Grid>
          &nbsp;
          {!!gara && (
            <Grid container item xs={12} spacing={3} alignItems='center'>
              <Grid item xs={12} md={6}>
                <center>
                  <b>{squadraCasaName}</b>&nbsp;-&nbsp;
                  <b>{squadraTrasfertaName}</b>
                </center>
              </Grid>
              <Grid item xs={6} md={3}>
                <center>{gara.data || 'Data non definita'}</center>
              </Grid>
              <Grid item xs={6} md={3}>
                <center>{gara.ora || 'Ora non definita'}</center>
              </Grid>
            </Grid>
          )}
          &nbsp;
          {!!gara && (
            <Grid container item xs={12} spacing={3} alignItems='center'>
              <Grid item xs={12} md={6}>
                <center>
                  <InputField
                    id='penalitaCasa'
                    value={penalitaCasa}
                    onChange={i =>
                      dispatch({ type: SET_PENALITA_CASA, payload: i })
                    }
                    autoFocus={true}
                    label={`Penalità per ${squadraCasaName}`}
                    errorFunc={errorPenalita}
                    type='number'
                  />
                </center>
              </Grid>
              <Grid item xs={12} md={6}>
                <center>
                  <InputField
                    id='penalitaTrasferta'
                    value={penalitaTrasferta}
                    onChange={i =>
                      dispatch({ type: SET_PENALITA_TRASFERTA, payload: i })
                    }
                    label={`Penalità per ${squadraTrasfertaName}`}
                    errorFunc={errorPenalita}
                    type='number'
                  />
                </center>
              </Grid>
            </Grid>
          )}
          &nbsp;
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  iscrizioniLight: state.home.iscrizioniLight,
  penalitaCasa: state.risultato.penalitaCasa,
  penalitaTrasferta: state.risultato.penalitaTrasferta,
})

const ConnectedDialogPenalita = connect(mapStateToProps)(DialogPenalita)

export default ConnectedDialogPenalita
