import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid, Tooltip } from '@mui/material'
import BlockIcon from '@mui/icons-material/Block'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import AutoComplete from './AutoComplete'
import { colours, GIORNI } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sortChronologically } from '../hooks/sort-functions'
import {
  fromStringsToDateTime,
  fromStringToDecimalHours,
  formatIndirizzo,
  isObjectId,
} from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import {
  RESET_DESIGNAZIONE,
  SET_SELECTED_GARA,
  SET_SELECTED_ARBITRO_1,
  SET_SELECTED_ARBITRO_2,
  SET_CHECK_ACCEPT,
  SET_CHECK_REJECT,
} from '../container/designazione/types'

const groupsOrder = [
  'Disponibili',
  'Fuori disponibilità settimanale',
  'Società incompatibile',
  'Altra designazione',
  'Indisponibili',
]

const DialogDesignazione = ({
  admin,
  arbitro,
  designatore,
  codiceUtente,
  iscrizioniLight,
  affiliazioniLight,
  arbitriLight,
  arbitri,
  mode,
  designazione,
  selectedGara,
  selectedArbitro1,
  selectedArbitro2,
  checkAccept,
  checkReject,
  designazioni,
}) => {
  const dispatch = useDispatch()

  const [gara, setGara] = useState()
  const [squadraCasa, setSquadraCasa] = useState()
  const [squadraTrasferta, setSquadraTrasferta] = useState()

  const [matchDay, setMatchDay] = useState()
  const [matchTime, setMatchTime] = useState()
  const [garaDesignazioni1, setGaraDesignazioni1] = useState()
  const [garaDesignazioni2, setGaraDesignazioni2] = useState()
  const [orariGareOdierne, setOrariGareOdierne] = useState()
  const [codiciSocietaGara, setCodiciSocietaGara] = useState()
  const [otherDesignazione, setOtherDesignazione] = useState()
  const [otherArbitro, setOtherArbitro] = useState()

  // map designazione stato
  const desStatus = designazione =>
    designazione?.isValid === false
      ? designazione?.acceptedByArbitro === false
        ? 'Rifiutata'
        : 'Annullata'
      : designazione?.acceptedByArbitro === true
      ? 'Accettata'
      : 'In sospeso'

  // update match date and time
  useEffect(() => {
    if (selectedGara) {
      setMatchDay(
        fromStringsToDateTime(selectedGara?.data, selectedGara?.ora)?.getDay()
      )
      setMatchTime(fromStringToDecimalHours(selectedGara?.ora))
    }
  }, [selectedGara])

  // update codiciSocieta gara
  useEffect(() => {
    if (selectedGara) {
      setCodiciSocietaGara(
        iscrizioniLight
          ?.filter(i =>
            [
              selectedGara?.squadraCasaID,
              selectedGara?.squadraTrasfertaID,
            ]?.includes(i.id)
          )
          ?.map(i => i.codiceSocieta)
      )
    }
  }, [iscrizioniLight, selectedGara])

  // update other arbitro
  useEffect(() => {
    if (arbitriLight && otherDesignazione)
      setOtherArbitro(
        arbitriLight?.find(a => a.id === otherDesignazione?.idArbitro)
      )
  }, [arbitriLight, otherDesignazione])

  // filter, group and sort arbitri
  const fArbitri =
    (selectedGara &&
      arbitri &&
      designazioni &&
      affiliazioniLight &&
      GIORNI &&
      groupsOrder &&
      orariGareOdierne &&
      codiciSocietaGara &&
      typeof matchDay === 'number' &&
      typeof matchTime === 'number' &&
      arbitri
        ?.filter(a => a?.isActive)
        ?.map(a => {
          const dispSettimanale = a?.disponibilitaSettimanali?.some(
            d =>
              (matchDay - 1 + GIORNI.length) % GIORNI.length ===
                GIORNI.findIndex(g => g === d?.giorno) &&
              matchTime >= fromStringToDecimalHours(d?.dalle) &&
              matchTime <= fromStringToDecimalHours(d?.alle)
          )
          const altreDesignazioni = orariGareOdierne
            ?.filter(o =>
              designazioni
                ?.filter(d => d.isValid && d.idArbitro === a.id)
                ?.map(d => d.idGara)
                ?.includes(o.id)
            )
            ?.some(
              o =>
                Math.abs(
                  fromStringToDecimalHours(o.ora) -
                    fromStringToDecimalHours(selectedGara?.ora)
                ) <= 3
            )
          const socIncompatibili = a?.codiciSocietaIncompatibili
            ?.map(s => affiliazioniLight?.find(i => i.id === s)?.codiceSocieta)
            ?.some(s => codiciSocietaGara?.includes(s))
          const indispPuntuali = a?.indisponibilitaPuntuali?.some(
            i => i === selectedGara?.data
          )
          return {
            ...a,
            gruppo: indispPuntuali
              ? 'Indisponibili'
              : altreDesignazioni
              ? 'Altra designazione'
              : socIncompatibili
              ? 'Società incompatibile'
              : !dispSettimanale
              ? 'Fuori disponibilità settimanale'
              : 'Disponibili',
          }
        })
        ?.sort(
          (a, b) =>
            groupsOrder.indexOf(a.gruppo) - groupsOrder.indexOf(b.gruppo) ||
            (a.cognome > b.cognome ? 1 : -1)
        )) ||
    []

  // fetch calendario
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(`calendari/${selectedGara._id}`)

        if (!data || !data.data) {
          dispatch({ type: SET_ERROR, payload: 'Impossibile trovare la gara.' })
        } else {
          setGara(data.data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedGara?._id) {
      fetch()
    } else {
      setGara()
    }
  }, [dispatch, selectedGara])

  // fetch squadra casa
  useEffect(() => {
    const fetchSquadraCasa = async () => {
      try {
        const squadraCasaData = await sendRequest(
          `iscrizioni/${selectedGara.squadraCasaID}`
        )

        if (!squadraCasaData || !squadraCasaData.data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare la squadra in casa.',
          })
        } else {
          setSquadraCasa(squadraCasaData.data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedGara?.squadraCasaID) {
      if (isObjectId(selectedGara.squadraCasaID)) {
        fetchSquadraCasa()
      } else {
        setSquadraCasa({ nomeSquadra: selectedGara.squadraCasaID }) // it is probably a placeholder
      }
    } else {
      setSquadraCasa()
    }
  }, [dispatch, selectedGara])

  // fetch squadra trasferta
  useEffect(() => {
    const fetchSquadraTrasferta = async () => {
      try {
        const squadraTrasfertaData = await sendRequest(
          `iscrizioni/${selectedGara.squadraTrasfertaID}`
        )

        if (!squadraTrasfertaData || !squadraTrasfertaData.data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare la squadra in trasferta.',
          })
        } else {
          setSquadraTrasferta(squadraTrasfertaData.data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedGara?.squadraTrasfertaID) {
      if (isObjectId(selectedGara.squadraTrasfertaID)) {
        fetchSquadraTrasferta()
      } else {
        setSquadraTrasferta({ nomeSquadra: selectedGara.squadraTrasfertaID }) // it is probably a placeholder
      }
    } else {
      setSquadraTrasferta()
    }
  }, [dispatch, selectedGara])

  // fetch gara designazioni
  useEffect(() => {
    const fetchGaraDesignazioni = async () => {
      try {
        const garaDesignazioniData = await sendRequest(
          `designazioni/gara/${selectedGara.id}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!garaDesignazioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le designazioni della gara.',
          })
        } else {
          setGaraDesignazioni1(
            garaDesignazioniData
              ?.filter(x => !x.isSecondoArbitro)
              ?.sort(
                (a, b) => -sortChronologically(a.timeDomanda, b.timeDomanda)
              )
          )
          setGaraDesignazioni2(
            garaDesignazioniData
              ?.filter(x => x.isSecondoArbitro)
              ?.sort(
                (a, b) => -sortChronologically(a.timeDomanda, b.timeDomanda)
              )
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if ((admin || designatore) && !!codiceUtente && !!selectedGara?.id) {
      fetchGaraDesignazioni()
    } else {
      setGaraDesignazioni1()
      setGaraDesignazioni2()
    }
  }, [admin, codiceUtente, designatore, dispatch, selectedGara])

  // fetch designazione calendario
  useEffect(() => {
    const fetchDesignazioneCalendario = async () => {
      try {
        const designazioneCalendarioData = await sendRequest(
          `calendari/${designazione.idGara}`
        )

        if (!designazioneCalendarioData) {
          dispatch({ type: SET_ERROR, payload: 'Impossibile trovare la gara.' })
        } else {
          dispatch({
            type: SET_SELECTED_GARA,
            payload: designazioneCalendarioData.data,
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (arbitro) {
      if (!!designazione?.idGara) {
        fetchDesignazioneCalendario()
      } else {
        dispatch({ type: SET_SELECTED_GARA })
      }
    }
  }, [arbitro, designazione, dispatch])

  // fetch orari gare designate
  useEffect(() => {
    const fetchOrariGareDesignate = async () => {
      try {
        const orariGareOdierneData = await sendRequest(
          `calendari/referee/data`,
          'POST',
          JSON.stringify({ data: selectedGara?.data }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )

        if (!orariGareOdierneData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare gli orari delle altre gare.',
          })
        } else {
          setOrariGareOdierne(orariGareOdierneData)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if ((admin || designatore) && codiceUtente && selectedGara?.data) {
      fetchOrariGareDesignate()
    } else {
      setOrariGareOdierne()
    }
  }, [admin, codiceUtente, designatore, dispatch, selectedGara])

  // fetch other designazione
  useEffect(() => {
    const fetchOtherDesignazione = async () => {
      try {
        const otherDesignazioneData = await sendRequest(
          `designazioni/gara/${selectedGara.id}/other/${designazione.idArbitro}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!otherDesignazioneData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le altre designazioni.',
          })
        } else {
          if (otherDesignazioneData?.length === 1) {
            setOtherDesignazione(otherDesignazioneData[0])
          } else {
            setOtherDesignazione()
          }
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (
      arbitro &&
      codiceUtente &&
      selectedGara?.id &&
      designazione?.idArbitro &&
      designazione?.isValid === true
    ) {
      fetchOtherDesignazione()
    } else {
      setOtherDesignazione()
    }
  }, [arbitro, codiceUtente, designazione, dispatch, selectedGara])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: RESET_DESIGNAZIONE })
  }, [dispatch])

  const GridElement = ({ label, field, mapping }) => {
    return (
      <Grid item xs={12} sm={6} md={3}>
        <center>
          {label}&nbsp;
          <b>
            {mapping
              ? mapping(selectedGara[field])
              : selectedGara[field] ||
                (field === 'data' && 'non definita') ||
                (field === 'ora' && 'non definito') ||
                '?'}
          </b>
        </center>
      </Grid>
    )
  }

  const ListDesignazioni = ({ n, garaDesignazioni }) => {
    return garaDesignazioni?.length > 0 ? (
      garaDesignazioni.map(x => {
        const xArbitro = arbitri?.find(a => a.id === x?.idArbitro)
        const isValid = x?.isValid === true
        const isAccepted = x?.acceptedByArbitro === true
        const isRejected = x?.acceptedByArbitro === false
        const isPending = ![true, false].includes(x?.acceptedByArbitro)
        return (
          <Grid key={x.id} container item alignItems='center'>
            <Grid item paddingX={2}>
              <center>
                {isAccepted && (
                  <Tooltip title='Accettata'>
                    <CheckIcon color='success' />
                  </Tooltip>
                )}
                {isRejected && (
                  <Tooltip title='Rifiutata'>
                    <ClearIcon color='error' />
                  </Tooltip>
                )}
                {isValid && isPending && (
                  <Tooltip title='In sospeso'>
                    <AccessTimeIcon color='warning' />
                  </Tooltip>
                )}
                &nbsp;
                {!isValid && !isRejected && (
                  <Tooltip title='Annullata dal designatore'>
                    <BlockIcon color='error' />
                  </Tooltip>
                )}
              </center>
            </Grid>
            <Grid item>
              {xArbitro?.nome && xArbitro?.cognome ? (
                <p>
                  {xArbitro.nome} {xArbitro.cognome}
                </p>
              ) : (
                <i>Nome non trovato</i>
              )}
            </Grid>
          </Grid>
        )
      })
    ) : (
      <Grid item>
        <center>
          <p style={{ color: colours.grey }}>Nessun {n}° arbitro designato.</p>
        </center>
      </Grid>
    )
  }

  return (
    <>
      &nbsp;
      {(!selectedGara && (
        <Grid item xs={12}>
          Gara non trovata.
        </Grid>
      )) || (
        <Grid container spacing={3} alignItems='center'>
          {arbitro && mode === 'reply' && (
            <Grid item xs={12}>
              Il presente modulo permette di accettare o rifiutare la
              designazione. La compilazione del modulo genera una mail
              automatica al designatore.
            </Grid>
          )}
          <GridElement label='Gara' field='numeroGara' />
          <GridElement label='Categoria' field='categoria' />
          <GridElement label='Data' field='data' />
          <GridElement label='Orario' field='ora' />
          <Grid item xs={12}>
            <center>
              <b>{squadraCasa?.nomeSquadra}</b>
              &nbsp;vs&nbsp;
              <b>{squadraTrasferta?.nomeSquadra}</b>
            </center>
          </Grid>
          <Grid item xs={12}>
            <center>
              Campo di gioco:&nbsp;
              {formatIndirizzo(squadraCasa, gara) || (
                <i>Indirizzo non trovato</i>
              )}
            </center>
          </Grid>
          {arbitro && otherDesignazione && otherArbitro && (
            <Grid item xs={12}>
              <center>
                {otherDesignazione?.isSecondoArbitro ? '2° ' : '1° '}
                arbitro&nbsp;
                <b>
                  {otherArbitro?.nome} {otherArbitro?.cognome}
                </b>
                &nbsp;({desStatus(otherDesignazione)})
              </center>
            </Grid>
          )}
          &nbsp;
          {(admin || designatore) && mode === 'update' && (
            <Grid
              container
              paddingX={3}
              columnSpacing={3}
              alignItems='flex-start'
            >
              <Grid container item xs={12} md={6} paddingY={3}>
                <Grid item xs={12}>
                  <center>
                    <AutoComplete
                      id='nuovo-arbitro-1'
                      label='Nuovo 1° arbitro'
                      value={selectedArbitro1?.id}
                      options={fArbitri}
                      groupBy={op => op?.gruppo}
                      getOptionLabel={op =>
                        fArbitri
                          ?.filter(i => i?.id === (op?.id || op))
                          ?.map(
                            op =>
                              `${op?.nome} ${op?.cognome} (${op?.cittaDomicilio})`
                          )?.[0]
                      }
                      isOptionEqualToValue={(op, value) => op?.id === value}
                      onChange={value =>
                        dispatch({
                          type: SET_SELECTED_ARBITRO_1,
                          payload: value,
                        })
                      }
                    />
                  </center>
                </Grid>
                <ListDesignazioni n={1} garaDesignazioni={garaDesignazioni1} />
              </Grid>
              <Grid container item xs={12} md={6} paddingY={3}>
                <Grid item xs={12}>
                  <center>
                    <AutoComplete
                      id='nuovo-arbitro-2'
                      label='Nuovo 2° arbitro'
                      value={selectedArbitro2?.id}
                      options={fArbitri}
                      groupBy={op => op?.gruppo}
                      getOptionLabel={op =>
                        fArbitri
                          ?.filter(i => i?.id === (op?.id || op))
                          ?.map(
                            op =>
                              `${op?.nome} ${op?.cognome} (${op?.cittaDomicilio})`
                          )?.[0]
                      }
                      isOptionEqualToValue={(op, value) => op?.id === value}
                      onChange={value =>
                        dispatch({
                          type: SET_SELECTED_ARBITRO_2,
                          payload: value,
                        })
                      }
                    />
                  </center>
                </Grid>
                <ListDesignazioni n={2} garaDesignazioni={garaDesignazioni2} />
              </Grid>
            </Grid>
          )}
          {arbitro && mode === 'view' && (
            <Grid item xs={12}>
              <center>
                Stato&nbsp;
                <b>{desStatus(designazione)}</b>
              </center>
            </Grid>
          )}
          {arbitro && mode === 'reply' && (
            <>
              <Grid container item spacing={3} alignItems='center'>
                <Grid item xs={2} md={1}>
                  <center>
                    <Checkbox
                      id='check-accept'
                      checked={!!checkAccept}
                      onChange={event => {
                        dispatch({
                          type: SET_CHECK_ACCEPT,
                          payload: event.target.checked,
                        })
                        if (!!event.target.checked)
                          dispatch({
                            type: SET_CHECK_REJECT,
                            payload: !event.target.checked,
                          })
                      }}
                    />
                  </center>
                </Grid>
                <Grid item xs={10} md={11}>
                  <b>Accetto la designazione.</b>
                </Grid>
              </Grid>
              <Grid container item spacing={3} alignItems='center'>
                <Grid item xs={2} md={1}>
                  <center>
                    <Checkbox
                      id='check-reject'
                      checked={!!checkReject}
                      onChange={event => {
                        dispatch({
                          type: SET_CHECK_REJECT,
                          payload: event.target.checked,
                        })
                        if (!!event.target.checked)
                          dispatch({
                            type: SET_CHECK_ACCEPT,
                            payload: !event.target.checked,
                          })
                      }}
                    />
                  </center>
                </Grid>
                <Grid item xs={10} md={11}>
                  <b>Rifiuto la designazione.</b>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  arbitro: state.home.arbitro,
  designatore: state.home.designatore,
  codiceUtente: state.home.codiceUtente,
  iscrizioniLight: state.home.iscrizioniLight,
  affiliazioniLight: state.home.affiliazioniLight,
  arbitriLight: state.home.arbitriLight,
  arbitri: state.arbitri.arbitri,
  mode: state.designazione.mode,
  designazione: state.designazione.designazione,
  selectedGara: state.designazione.selectedGara,
  selectedArbitro1: state.designazione.selectedArbitro1,
  selectedArbitro2: state.designazione.selectedArbitro2,
  checkAccept: state.designazione.checkAccept,
  checkReject: state.designazione.checkReject,
  designazioni: state.designazioni.designazioni,
})

const ConnectedDialogDesignazione = connect(mapStateToProps)(DialogDesignazione)

export default ConnectedDialogDesignazione
