import React from 'react'
import { useDispatch, connect } from 'react-redux'
import { IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { SET_ERROR } from '../container/home/types'

const SnackBar = ({ error }) => {
  const dispatch = useDispatch()

  const errorHandler = () => dispatch({ type: SET_ERROR })

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!!error}
      onClose={errorHandler}
      message={error}
      action={
        <IconButton size='small' onClick={errorHandler}>
          <CloseIcon fontSize='small' />
        </IconButton>
      }
    />
  )
}

const mapStateToProps = state => ({
  error: state.home.error,
})

const ConnectedSnackBar = connect(mapStateToProps)(SnackBar)

export default ConnectedSnackBar
