import fileDownload from 'js-file-download'

import { sendRequest } from './http-hook'
import { sendRequestBlob } from './http-hook-blob'

const downloadFile = async (algorithm, type, setFeedback, exactlyOne) => {
  if (!setFeedback) {
    setFeedback = (phrase, error = true) =>
      error ? console.error(phrase) : console.log(phrase)
  }

  if (!algorithm) {
    setFeedback('Algoritmo non definito!')
    return
  }

  if (!type) {
    setFeedback('Tipo di file non definito!')
    return
  }

  try {
    const fileName = type === 'CSV' ? 'Output' : 'all'
    const checkFileData = await sendRequest(
      `algoritmi/checkfile/${algorithm}/${type}/${fileName}`
    )
    const fileNames = checkFileData.fileNames

    if (!fileNames?.length || (exactlyOne && fileNames.length !== 1)) {
      setFeedback('Nessun file trovato.')
      return
    }

    // add some delay because some browsers do not download more than 10 files simultaneously
    const delayedDownload = () => {
      setTimeout(async () => {
        let fileName = fileNames[i]
        const fileData = await sendRequestBlob(
          `algoritmi/download/${algorithm}/${fileName}`
        )
        fileDownload(fileData, fileName)
        i++
        if (i < fileNames.length) delayedDownload()
      }, 500)
    }

    let i = 0
    delayedDownload()
    setFeedback(
      `File scaricat${fileNames.length === 1 ? 'o' : 'i'} correttamente.`,
      false,
      'Download completato'
    )
  } catch (err) {
    console.log(err)
    setFeedback(err.message)
  }
}

export { downloadFile }
