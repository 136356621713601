import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'

import Button from '../components/Button'
import InputField from '../components/InputField'
import { useAuth } from '../hooks/auth-hook'

const Home = ({ auth }) => {
  const [codiceUtente, setCodiceUtente] = useState()
  const [password, setPassword] = useState()

  const { login } = useAuth(auth)

  document.title = 'PGS Milano - Home'

  return (
    <Grid container paddingY={2}>
      <form
        style={{ width: '100%' }}
        onSubmit={e => {
          e.preventDefault() // prevents page reload
          login(codiceUtente, password)
        }}
      >
        <Grid container item xs={12} direction='column' spacing={2}>
          <Grid item xs={12}>
            <center>
              <h2>Login</h2>
            </center>
          </Grid>
          <Grid container item>
            <Grid item xs={2} md={4} />
            <Grid item xs={8} md={4}>
              <InputField
                id='codiceSocieta'
                value={codiceUtente}
                onChange={setCodiceUtente}
                autoFocus={true}
                label='Codice'
                type='text'
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={2} md={4} />
            <Grid item xs={8} md={4}>
              <InputField
                id='password'
                value={password}
                onChange={setPassword}
                label='Password'
                type='password'
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <center>
              <Button type='submit' disabled={!codiceUtente || !password}>
                Vai
              </Button>
            </center>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

const mapStateToProps = state => ({
  auth: state.home.auth,
})

const ConnectedHome = connect(mapStateToProps)(Home)

export default ConnectedHome
