import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import GridCard from '../components/GridCard'
import SelectionBar from '../components/SelectionBar'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR, SET_DASHBOARD_STATS } from '../container/home/types'

const DashboardOmologatore = ({
  codiceUtente,
  selectedCampionatoCalendariPubblicati,
  selectedCampionato,
  dashboardStats,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Home'

  // fetch partite stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(
          `calendari/stats/campionato/${selectedCampionato}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le statistiche sulle partite.',
          })
        } else {
          dispatch({
            type: SET_DASHBOARD_STATS,
            payload: { partiteStats: data },
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [codiceUtente, dispatch, selectedCampionato])

  return (
    <Grid
      container
      item
      xs={12}
      marginY={2}
      rowSpacing={5}
      alignItems='stretch'
    >
      <SelectionBar
        includeCategoria={false}
        includeGirone={false}
        includeTeam={false}
      />
      <p style={{ margin: 20, width: '100%' }}>
        Benvenuto nel profilo di omologazione di PGS Milano.
        <br />
        Dalla pagina <b>Calendari</b> puoi omologare e correggere i risultati
        delle gare.
        <br />
        Buon lavoro!
      </p>
      <GridCard
        title='Calendari'
        subtitle1={`${dashboardStats?.partiteStats?.all || 0} ${
          dashboardStats?.partiteStats?.all === 1 ? 'partita' : 'partite'
        }`}
        subtitle2={`${
          selectedCampionatoCalendariPubblicati
            ? `${dashboardStats?.partiteStats?.withHomologation || 0} ${
                dashboardStats?.partiteStats?.withHomologation === 1
                  ? 'omologata'
                  : 'omologate'
              }`
            : 'Calendari non ancora pubblicati'
        }`}
        linkTo='/calendari'
        isError={
          !selectedCampionatoCalendariPubblicati ||
          dashboardStats?.partiteStats?.withHomologation
        }
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  selectedCampionatoCalendariPubblicati:
    state.home.selectedCampionatoCalendariPubblicati,
  selectedCampionato: state.home.selectedCampionato,
  dashboardStats: state.home.dashboardStats,
})

const ConnectedDashboardOmologatore =
  connect(mapStateToProps)(DashboardOmologatore)

export default ConnectedDashboardOmologatore
