import {
  SET_AVAILABLE_GARE,
  SET_SELECTED_GARA,
  SET_RISULTATO,
  SET_RISULTATO_FIELD,
  SET_PENALITA_CASA,
  SET_PENALITA_TRASFERTA,
  SET_OMOLOGA,
} from './types'

const INIT_STATE = {}

export const risultatoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AVAILABLE_GARE:
      return { ...state, availableGare: action.payload }
    case SET_SELECTED_GARA:
      return { ...state, selectedGara: action.payload }
    case SET_RISULTATO:
      return { ...state, risultato: action.payload }
    case SET_RISULTATO_FIELD:
      return {
        ...state,
        risultato: {
          ...state.risultato,
          [action.payload.field]: action.payload.value,
        },
      }
    case SET_PENALITA_CASA:
      return { ...state, penalitaCasa: action.payload }
    case SET_PENALITA_TRASFERTA:
      return { ...state, penalitaTrasferta: action.payload }
    case SET_OMOLOGA:
      return { ...state, omologa: action.payload }
    default:
      return state
  }
}
