import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import GridCard from '../components/GridCard'
import SelectionBar from '../components/SelectionBar'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR, SET_DASHBOARD_STATS } from '../container/home/types'

const DashboardArbitro = ({
  codiceUtente,
  infoUtente,
  availableStagioni,
  selectedStagione,
  dashboardStats,
  designazioni,
}) => {
  document.title = 'PGS Milano - Home'

  const dispatch = useDispatch()

  const [filteredDesignazioni, setFilteredDesignazioni] = useState()

  useEffect(() => {
    setFilteredDesignazioni(designazioni?.filter(d => d.isValid))
  }, [designazioni])

  // fetch rimborsi stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const fStagione =
          selectedStagione !== availableStagioni[0]
            ? `/stagione/${selectedStagione}`
            : ''
        const data = await sendRequest(
          `rimborsi/stats/arbitro/${infoUtente.id}${fStagione}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le statistiche sui rimborsi.',
          })
        } else {
          dispatch({
            type: SET_DASHBOARD_STATS,
            payload: { rimborsiStats: data },
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && infoUtente?.id) {
      if (selectedStagione) {
        fetch()
      } else {
        dispatch({ type: SET_DASHBOARD_STATS, payload: { rimborsiStats: {} } })
      }
    }
  }, [availableStagioni, codiceUtente, dispatch, infoUtente, selectedStagione])

  return (
    <Grid
      container
      item
      xs={12}
      marginY={2}
      rowSpacing={5}
      alignItems='stretch'
    >
      <SelectionBar
        includeStagione={true}
        includeCampionato={false}
        includeCategoria={false}
        includeGirone={false}
        includeTeam={false}
      />
      <GridCard
        title='Designazioni'
        subtitle1={`${
          filteredDesignazioni?.filter(d => d.acceptedByArbitro)?.length || 0
        } ${
          filteredDesignazioni?.filter(d => d.acceptedByArbitro)?.length === 1
            ? 'accettata'
            : 'accettate'
        }`}
        subtitle2={`${
          filteredDesignazioni?.filter(
            d => ![true, false].includes(d?.acceptedByArbitro)
          )?.length || 0
        } ${
          filteredDesignazioni?.filter(
            d => ![true, false].includes(d?.acceptedByArbitro)
          )?.length === 1
            ? 'da confermare'
            : 'da confermare'
        }`}
        linkTo='/designazioni'
        isError={
          filteredDesignazioni?.filter(
            d => ![true, false].includes(d?.acceptedByArbitro)
          )?.length > 0
        }
      />
      <GridCard
        title='Rimborsi'
        subtitle1={`${dashboardStats?.rimborsiStats?.pagati || 0} ${
          dashboardStats?.rimborsiStats?.pagati === 1 ? 'ricevuto' : 'ricevuti'
        }`}
        subtitle2={`${dashboardStats?.rimborsiStats?.nonPagati || 0} ${
          dashboardStats?.rimborsiStats?.nonPagati === 1
            ? 'da ricevere'
            : 'da ricevere'
        }`}
        linkTo='/rimborsi'
        isError={dashboardStats?.rimborsiStats?.nonPagati > 0}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  infoUtente: state.home.infoUtente,
  availableStagioni: state.home.availableStagioni,
  selectedStagione: state.home.selectedStagione,
  dashboardStats: state.home.dashboardStats,
  designazioni: state.designazioni.designazioni,
})

const ConnectedDashboardArbitro = connect(mapStateToProps)(DashboardArbitro)

export default ConnectedDashboardArbitro
