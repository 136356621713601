import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'

import { colours } from '../settings/settings'

const MySelect = ({
  id,
  value,
  options = [],
  optionLabels,
  label = 'Label',
  variant = 'filled',
  onChange = () => {},
  helperText = '',
  errorText = '',
  disabled = false,
  isMobile = false,
  readOnly = false,
  mode,
}) => {
  const [hasBlurred, setHasBlurred] = useState(false)

  const isNumeric = options.every(op => typeof op === 'number')

  const isError =
    !disabled &&
    !options.includes(value) &&
    (mode === 'update' || ((!mode || mode === 'add') && hasBlurred))

  const isReadOnly = readOnly || mode === 'view'

  const selectProps = {
    id: id,
    name: id,
    value: value || '',
    label: label,
    variant: variant,
    disabled: !!disabled,
    style: {
      backgroundColor:
        (!!disabled && 'transparent') || (!!isError && colours.redError),
    },
    onBlur: () => setHasBlurred(true),
    onChange: event =>
      !isReadOnly &&
      onChange(!!isNumeric ? +event.target.value : event.target.value || null),
  }

  const emptyOptionProps = {
    key: 'empty',
    value: null,
  }

  return (
    <FormControl fullWidth={true} error={isError}>
      <InputLabel id={id} disabled={!!disabled}>
        {label}
      </InputLabel>
      {!!isMobile ? (
        <Select {...selectProps} native>
          <option {...emptyOptionProps} />
          {options.map((op, i) => (
            <option key={i} value={op}>
              {optionLabels?.[i] || op}
            </option>
          ))}
        </Select>
      ) : (
        <Select {...selectProps} labelId={id} readOnly={isReadOnly}>
          <MenuItem {...emptyOptionProps}>&nbsp;</MenuItem>
          {options.map((op, i) => (
            <MenuItem key={i} value={op} selected={op === value}>
              {optionLabels?.[i] || op}
            </MenuItem>
          ))}
        </Select>
      )}
      <FormHelperText>{isError ? errorText : helperText}</FormHelperText>
    </FormControl>
  )
}

const mapStateToProps = state => ({
  isMobile: state.home.isMobile,
})

const ConnectedMySelect = connect(mapStateToProps)(MySelect)

export default ConnectedMySelect
