import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

import { colours } from '../settings/settings'

const MyButton = styled(Button)(
  ({
    error,
    dark,
    light,
    excel,
    transparent,
    textbold = 'bold',
    textcolour = colours.white,
    backgroundcolour = colours.blue,
    hovercolour = colours.blueDark,
    bordercolour = colours.black,
  }) => {
    if (error) {
      textbold = ''
      backgroundcolour = colours.red
      hovercolour = colours.redDark
    }

    if (dark) {
      backgroundcolour = colours.blueDark
      hovercolour = colours.blueVeryDark
    }

    if (light) {
      backgroundcolour = colours.blueLight
      hovercolour = colours.blue
    }

    if (excel) {
      backgroundcolour = colours.greenExcel
      hovercolour = colours.greenExcelHover
    }

    if (transparent) {
      textbold = ''
      textcolour = colours.black
      backgroundcolour = 'transparent'
      hovercolour = colours.greyVeryLight
      bordercolour = 'transparent'
    }

    return {
      padding: '8px',
      color: textcolour,
      fontSize: '1em',
      fontWeight: textbold,
      border: `1px solid ${bordercolour}`,
      background: backgroundcolour,
      ':hover': { backgroundColor: hovercolour },
    }
  }
)

export default MyButton
