import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import EuroIcon from '@mui/icons-material/Euro'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'

import Button from '../components/Button'
import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import GridCircularProgress from '../components/GridCircularProgress'
import {
  colours,
  titlesRimborso as titles,
  phrasesRimborso as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { runAlgorithm } from '../hooks/algoritmi-functions'
import {
  fromDateTimeToString,
  sumFun,
  formatEuro,
} from '../hooks/utils-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_RIMBORSI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_RIMBORSO_MODE,
  SET_RIMBORSO,
} from '../container/rimborsi/types'

const Rimborsi = ({
  admin,
  arbitro,
  codiceUtente,
  infoUtente,
  dummyUpdate,
  backendR,
  availableStagioni,
  selectedStagione,
  selectedArbitro,
  selectedDesignazioni,
  rimborsi,
  sortingColumn,
  sortingAscending,
  rimborsoMode,
  rimborso,
  rimborsoDatePaid,
  arbitri,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Rimborsi'

  let columnsRimborsi = [
    { label: 'Creazione', sortable: true, sortingField: 'timeFirstInsert' },
    { label: 'Anno', sortable: false },
    { label: 'Stagione', sortable: false },
  ]
  if (admin)
    columnsRimborsi = [
      ...columnsRimborsi,
      { label: 'Arbitro', sortable: true, sortingField: 'cognomenome' },
    ]
  columnsRimborsi = [
    ...columnsRimborsi,
    { label: 'Gare', sortable: false },
    { label: 'Totale', sortable: true, sortingField: 'totale' },
    { label: 'Stato', sortable: true, sortingField: 'isPaid' },
    { label: 'Opzioni', sortable: false },
  ]

  const [loadingRimborsi, setLoadingRimborsi] = useState(false)
  const [rimborsiN, setRimborsiN] = useState()
  const [allPaid, setAllPaid] = useState()
  const [openRimborso, setOpenRimborso] = useState(false)
  const [openPagato, setOpenPagato] = useState(false)
  const [deletingRimborso, setDeletingRimborso] = useState()
  const [openDeleteRimborso, setOpenDeleteRimborso] = useState(false)

  const { setFeedback } = useFeedback()

  const handleClickOpenRimborso = id => {
    dispatch({ type: SET_RIMBORSO, payload: rimborsi?.find(r => r.id === id) })
    setOpenRimborso(true)
  }

  const handleClickOpenPagato = id => {
    dispatch({ type: SET_RIMBORSO, payload: rimborsi?.find(r => r.id === id) })
    setOpenPagato(true)
  }

  const handleClickOpenDeleteRimborso = id => {
    setDeletingRimborso(rimborsi?.find(r => r.id === id))
    setOpenDeleteRimborso(true)
  }

  const handleCloseRimborso = () => {
    setOpenRimborso(false)
    dispatch({ type: SET_RIMBORSO_MODE })
    dispatch({ type: SET_RIMBORSO })
  }

  const handleClosePagato = () => {
    setOpenPagato(false)
    dispatch({ type: SET_RIMBORSO })
  }

  const handleCloseDeleteRimborso = () => {
    setOpenDeleteRimborso(false)
    setDeletingRimborso()
  }

  const confirmRimborso = async () => {
    if (rimborsoMode === 'select') await selectDesignazioni()
    if (rimborsoMode === 'add') await addRimborso()
    if (rimborsoMode === 'update') await updateRimborso()
    if (rimborsoMode === 'view') handleCloseRimborso()
  }

  const confirmDeleteRimborso = async () => {
    await deleteRimborso()
    handleCloseDeleteRimborso()
  }

  const totTotaleDovuto = () =>
    formatEuro(
      sumFun(rimborsiN?.filter(r => !r.isPaid)?.map(r => r.totale)) || 0
    )
  const totTotalePagato = () =>
    formatEuro(
      sumFun(rimborsiN?.filter(r => r.isPaid)?.map(r => r.totale)) || 0
    )

  // fetch rimborsi
  useEffect(() => {
    const fetchRimborsi = async () => {
      setLoadingRimborsi(true)
      try {
        const rimborsiData = await sendRequest(
          `rimborsi${
            admin
              ? selectedStagione !== availableStagioni[0]
                ? `/stagione/${selectedStagione}`
                : '/all'
              : `/arbitro/${infoUtente.id}`
          }`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!rimborsiData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i rimborsi.',
          })
        } else {
          dispatch({ type: SET_RIMBORSI, payload: rimborsiData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingRimborsi(false)
    }
    if (
      ((admin && selectedStagione) || (arbitro && infoUtente?.id)) &&
      codiceUtente
    ) {
      fetchRimborsi()
    } else {
      dispatch({ type: SET_RIMBORSI })
    }
  }, [
    admin,
    arbitro,
    availableStagioni,
    codiceUtente,
    dispatch,
    dummyUpdate,
    infoUtente,
    selectedStagione,
  ])

  // select designazioni
  const selectDesignazioni = async () => {
    if (selectedDesignazioni?.length) {
      dispatch({
        type: SET_RIMBORSO,
        payload: {
          ...rimborso,
          designazioni: selectedDesignazioni?.map(d => ({
            idDesignazione: d,
            diaria: 20,
          })),
        },
      })
      dispatch({ type: SET_RIMBORSO_MODE, payload: 'add' })
    } else {
      setFeedback('Nessuna gara selezionata!')
    }
  }

  // add rimborso
  const addRimborso = async () => {
    if (rimborso?.designazioni?.length) {
      try {
        await sendRequest(
          'rimborsi/add',
          'POST',
          JSON.stringify({
            codiceArbitro: codiceUtente,
            designazioni: rimborso?.designazioni,
            costoKm: rimborso?.costoKm,
            importoLordoAnnuoPGS: rimborso?.importoLordoAnnuoPGS,
            importoLordoAnnuoAltri: rimborso?.importoLordoAnnuoAltri,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseRimborso()
        setFeedback(phrases.phraseAddSuccess, false, titles.titleAddSuccess)
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        setFeedback(err.message)
      }
    }
  }

  // update rimborso
  const updateRimborso = async () => {
    if (
      rimborso?.id &&
      rimborso?.designazioni &&
      typeof rimborso?.importoLordoAnnuoPGS === 'number' &&
      typeof rimborso?.importoLordoAnnuoAltri === 'number'
    ) {
      try {
        await sendRequest(
          `rimborsi/${rimborso.id}`,
          'PATCH',
          JSON.stringify({
            designazioni: rimborso.designazioni,
            importoLordoAnnuoPGS: rimborso.importoLordoAnnuoPGS,
            importoLordoAnnuoAltri: rimborso.importoLordoAnnuoAltri,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseRimborso()
        setFeedback(
          phrases.phraseUpdateSuccess,
          false,
          titles.titleUpdateSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback(
        'Impossibile aggiornare il rimborso: informazioni non trovate!'
      )
    }
  }

  // update payment status
  const updatePagato = async () => {
    if (codiceUtente && rimborso?.id) {
      try {
        await sendRequest(
          `rimborsi/is-paid/${rimborso.id}`,
          'PATCH',
          JSON.stringify({ datePaid: rimborsoDatePaid }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleClosePagato()
        setFeedback(
          phrases.phrasePagatoSuccess,
          false,
          titles.titlePagatoSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback(
        'Impossibile aggiornare lo stato del pagamento: nessun rimborso selezionato!'
      )
    }
  }

  // delete rimborso
  const deleteRimborso = async () => {
    if (codiceUtente && deletingRimborso?.id) {
      try {
        await sendRequest(`rimborsi/${deletingRimborso.id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
        setFeedback(
          phrases.phraseDeleteSuccess,
          false,
          titles.titleDeleteSuccess
        )
      } catch (err) {
        setFeedback(err.message)
      }
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    }
  }

  // add "cognomenome" to rimborsi
  useEffect(() => {
    if (rimborsi && (!admin || arbitri))
      setRimborsiN(
        rimborsi
          ?.filter(r =>
            admin && selectedArbitro ? r.idArbitro === selectedArbitro : true
          )
          ?.filter(
            r =>
              selectedStagione === availableStagioni[0] ||
              r.stagione === selectedStagione
          )
          ?.map(r => {
            r = { ...r, isPaid: !!r.datePaid }

            if (admin) {
              const rArb = arbitri?.find(a => a.id === r?.idArbitro)
              const nArb = (rArb && `${rArb.cognome} ${rArb.nome}`) || (
                <i>Arbitro non trovato</i>
              )
              return { ...r, cognomenome: nArb }
            } else {
              return r
            }
          })
          ?.reverse()
      )
  }, [
    admin,
    arbitri,
    availableStagioni,
    rimborsi,
    selectedArbitro,
    selectedStagione,
  ])

  // update allPaid
  useEffect(() => {
    if (rimborsiN?.length) setAllPaid(rimborsiN.every(r => r.isPaid))
  }, [rimborsiN])

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Rimborsi</h2>
        </center>
      </Grid>
      &nbsp;
      <SelectionBar
        includeStagione={true}
        includeCampionato={false}
        includeCategoria={false}
        includeGirone={false}
        includeTeam={false}
        includeArbitro={admin}
      />
      &nbsp;
      {admin && rimborsiN && (
        <Grid container item xs={12} alignItems='center'>
          <Grid item xs={6}>
            <center>
              Totale da rimborsare: <b>{totTotaleDovuto()}</b>
            </center>
          </Grid>
          <Grid item xs={6}>
            <center>
              Totale rimborsato: <b>{totTotalePagato()}</b>
            </center>
          </Grid>
        </Grid>
      )}
      {arbitro && (
        <Grid item xs={12}>
          <center>
            <Button
              onClick={() => {
                dispatch({ type: SET_RIMBORSO_MODE, payload: 'select' })
                handleClickOpenRimborso()
              }}
            >
              Nuovo rimborso
            </Button>
          </center>
        </Grid>
      )}
      {(loadingRimborsi && <GridCircularProgress />) ||
        (!rimborsi && (
          <Grid item xs={12}>
            <center>
              <h4>Elenco dei rimborsi non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (rimborsiN?.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Nessun rimborso trovato.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <Grid item xs={12} paddingX={3}>
              Cliccando su ciascuna riga, si possono vedere i dettagli e
              modificare i dati inseriti (se non è ancora avvenuto il
              pagamento).
              <br />
              Cliccando sulla freccia verde a destra, si scarica il file Excel
              del relativo rimborso spese.
              {admin && (
                <>
                  <br />
                  Cliccando sulla €, si può aggiornare lo stato del pagamento.
                </>
              )}
              <br />
              Cliccando sul cestino rosso a destra, si elimina il rimborso dalla
              piattaforma (se non è ancora avvenuto il pagamento).
            </Grid>
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rimborsiN}
                  setTable={t => dispatch({ type: SET_RIMBORSI, payload: t })}
                  columns={columnsRimborsi}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {rimborsiN?.map(r => {
                    const cellProps = {
                      align: 'center',
                      sx: { whiteSpace: 'nowrap' },
                    }

                    return (
                      <TableRow
                        key={r.id}
                        sx={{
                          cursor: 'pointer',
                          ':hover': { backgroundColor: colours.greyVeryLight },
                        }}
                        onClick={() => {
                          dispatch({
                            type: SET_RIMBORSO_MODE,
                            payload: r.isPaid ? 'view' : 'update',
                          })
                          handleClickOpenRimborso(r.id)
                        }}
                      >
                        <TableCell {...cellProps}>
                          {fromDateTimeToString(
                            new Date(r?.timeFirstInsert),
                            true
                          )}
                        </TableCell>
                        <TableCell {...cellProps}>{r?.annoFiscale}</TableCell>
                        <TableCell {...cellProps}>{r?.stagione}</TableCell>
                        {admin && (
                          <TableCell {...cellProps}>{r?.cognomenome}</TableCell>
                        )}
                        <TableCell {...cellProps}>
                          {r?.designazioni?.length}&nbsp;
                          {r?.designazioni?.length === 1 ? 'gara' : 'gare'}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {formatEuro(r?.totale)}
                        </TableCell>
                        <TableCell {...cellProps}>
                          <Chip
                            sx={{ cursor: 'pointer' }}
                            variant={r.isPaid ? 'outlined' : 'filled'}
                            label={r.isPaid ? 'pagato' : 'da pagare'}
                            color={r.isPaid ? 'success' : 'warning'}
                          />
                        </TableCell>
                        <TableCell {...cellProps}>
                          <Grid container item>
                            <Grid item xs>
                              <IconButton
                                style={{
                                  color: backendR?.isCallable
                                    ? colours.greenExcel
                                    : colours.greyVeryLight,
                                }}
                                disabled={!backendR?.isCallable}
                                onClick={e => {
                                  e.stopPropagation()
                                  runAlgorithm({
                                    algorithm: '13_GenerateExcelRimborso',
                                    selectedRimborso: r.id,
                                    triggerDownload: true,
                                    exactlyOne: true,
                                    type: 'XLSX',
                                    codiceUtente,
                                    setFeedback,
                                  })
                                }}
                              >
                                <Tooltip title='Scarica Excel'>
                                  <DownloadIcon />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                            {admin && (
                              <Grid item xs>
                                <IconButton
                                  style={{
                                    color: r.isPaid
                                      ? colours.greenExcel
                                      : colours.greyVeryLight,
                                  }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    handleClickOpenPagato(r.id)
                                  }}
                                >
                                  <Tooltip title='Aggiorna pagamento'>
                                    <EuroIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            )}
                            {!allPaid && (
                              <Grid item xs>
                                {!r.isPaid && (
                                  <IconButton
                                    style={{ color: colours.red }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      handleClickOpenDeleteRimborso(r.id)
                                    }}
                                  >
                                    <Tooltip title='Elimina'>
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      <DialogMini
        open={openRimborso}
        handleClose={handleCloseRimborso}
        title={titles.titleRimborso}
        textUndo={(rimborsoMode === 'view' && 'Chiudi') || 'Annulla'}
        textConfirm={rimborsoMode !== 'view' && 'Conferma'}
        triggerFunction={confirmRimborso}
        dialogRimborso={true}
      />
      <DialogMini
        open={openPagato}
        handleClose={handleClosePagato}
        title={titles.titlePagato}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={updatePagato}
        dialogRimborsoPagato={true}
      />
      <DialogMini
        open={openDeleteRimborso}
        handleClose={handleCloseDeleteRimborso}
        title={titles.titleDelete}
        textContent={phrases.phraseDelete}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmDeleteRimborso}
        colourBackground={colours.red}
        colourHover={colours.redDark}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  arbitro: state.home.arbitro,
  codiceUtente: state.home.codiceUtente,
  infoUtente: state.home.infoUtente,
  dummyUpdate: state.home.dummyUpdate,
  backendR: state.home.backendR,
  availableStagioni: state.home.availableStagioni,
  selectedStagione: state.home.selectedStagione,
  selectedArbitro: state.home.selectedArbitro,
  selectedDesignazioni: state.home.selectedDesignazioni,
  rimborsi: state.rimborsi.rimborsi,
  sortingColumn: state.rimborsi.sortingColumn,
  sortingAscending: state.rimborsi.sortingAscending,
  rimborsoMode: state.rimborsi.rimborsoMode,
  rimborso: state.rimborsi.rimborso,
  rimborsoDatePaid: state.rimborsi.rimborsoDatePaid,
  arbitri: state.arbitri.arbitri,
})

const ConnectedRimborsi = connect(mapStateToProps)(Rimborsi)

export default ConnectedRimborsi
