import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'

import Button from '../components/Button'
import DialogMini from '../components/DialogMini'
import InputField from '../components/InputField'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { errorNaturale, errorNaturale0 } from '../hooks/error-functions'
import {
  colours,
  titlesImpostazioni as titles,
  phrasesImpostazioni as phrases,
} from '../settings/settings'
import { SET_IMPOSTAZIONI } from '../container/home/types'

const Impostazioni = ({ codiceUtente, impostazioni }) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Impostazioni'

  const { setFeedback } = useFeedback()

  // state to manage the Impostazioni mode
  const [editingGironi, setEditingGironi] = useState(false)
  const [editingCalendari, setEditingCalendari] = useState(false)

  // parameters
  const [completeInputs, setCompleteInputs] = useState()
  const [completeInputsGironi, setCompleteInputsGironi] = useState(false)
  const [completeInputsCalendari, setCompleteInputsCalendari] = useState(false)

  const [impostazioniTemp, setImpostazioniTemp] = useState(impostazioni)

  useEffect(() => {
    setImpostazioniTemp(impostazioni)
  }, [impostazioni])

  const [openUndo, setOpenUndo] = useState(false)
  const [openCheck, setOpenCheck] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openZone, setOpenZone] = useState(false)
  const [openDomeniche, setOpenDomeniche] = useState(false)
  const [openAccoppiamenti, setOpenAccoppiamenti] = useState(false)
  const [openCampionati, setOpenCampionati] = useState(false)
  const [openCategorie, setOpenCategorie] = useState(false)
  const [openTabelloni, setOpenTabelloni] = useState(false)
  const [openTabellone, setOpenTabellone] = useState(false)

  const finishEditing = () => {
    setEditingGironi(false)
    setEditingCalendari(false)
  }

  const handleClickOpenUndo = () => setOpenUndo(true)
  const handleClickOpenCheck = () => setOpenCheck(true)
  const handleClickOpenSuccess = () => setOpenSuccess(true)

  const handleClickOpenZone = () => setOpenZone(true)
  const handleClickOpenDomeniche = () => setOpenDomeniche(true)
  const handleClickOpenAccoppiamenti = () => setOpenAccoppiamenti(true)
  const handleClickOpenCampionati = () => setOpenCampionati(true)
  const handleClickOpenCategorie = () => setOpenCategorie(true)
  const handleClickOpenTabelloni = () => setOpenTabelloni(true)
  const handleClickOpenTabellone = () => setOpenTabellone(true)

  const handleCloseUndo = () => {
    setImpostazioniTemp(impostazioni)
    setEditingGironi(false)
    setEditingCalendari(false)
    setOpenUndo(false)
  }

  const handleCloseCheck = () => {
    setOpenCheck(false)
  }

  const handleCloseSuccess = () => {
    finishEditing()
    setOpenSuccess(false)
  }

  const handleCloseZone = () => setOpenZone(false)
  const handleCloseDomeniche = () => setOpenDomeniche(false)
  const handleCloseAccoppiamenti = () => setOpenAccoppiamenti(false)
  const handleCloseCampionati = () => setOpenCampionati(false)
  const handleCloseCategorie = () => setOpenCategorie(false)
  const handleCloseTabelloni = () => setOpenTabelloni(false)
  const handleCloseTabellone = () => setOpenTabellone(false)

  // check inputs completeness
  useEffect(() => {
    // Gironi
    if (
      !errorNaturale(impostazioniTemp?.TENTATIVI) &&
      typeof impostazioniTemp?.SeparateSocietyTeams === 'boolean' &&
      !errorNaturale0(impostazioniTemp?.WeightZone) &&
      !errorNaturale0(impostazioniTemp?.WeightAbb) &&
      !errorNaturale0(impostazioniTemp?.WeightAlt) &&
      !errorNaturale0(impostazioniTemp?.WeightSep) &&
      !errorNaturale0(impostazioniTemp?.WeightOrd) &&
      !errorNaturale0(impostazioniTemp?.WeightPre)
    ) {
      setCompleteInputsGironi(true)
      if (editingGironi) setCompleteInputs(true)
    } else {
      setCompleteInputsGironi(false)
      if (editingGironi) setCompleteInputs(false)
    }

    // Calendari
    if (
      typeof impostazioniTemp?.OrdinaPartiteCronologicamentePerGiornata ===
        'boolean' &&
      !errorNaturale(impostazioniTemp?.PrimoNumeroGara) &&
      !errorNaturale(impostazioniTemp?.PrimoNumeroGaraPropaganda)
    ) {
      setCompleteInputsCalendari(true)
      if (editingCalendari) setCompleteInputs(true)
    } else {
      setCompleteInputsCalendari(false)
      if (editingCalendari) setCompleteInputs(false)
    }
  }, [editingCalendari, editingGironi, impostazioniTemp])

  // update function
  const updateImpostazioni = async () => {
    try {
      await sendRequest(
        'impostazioni/update',
        'PUT',
        JSON.stringify(impostazioniTemp),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      dispatch({ type: SET_IMPOSTAZIONI, payload: impostazioniTemp })
      handleClickOpenSuccess()
    } catch (err) {
      setFeedback(err.message)
    }
  }

  const confirmImpostazione = async () => {
    await updateImpostazioni()
    handleCloseCheck()
  }

  const GridTitle = ({ title }) => (
    <Grid item xs={12}>
      <center>
        <h3>{title}</h3>
      </center>
    </Grid>
  )

  // this needs to be a function to avoid re-renders at every "onChange"
  const GridInputField = (
    editing,
    label,
    field,
    errorFunc,
    errorText,
    type
  ) => (
    <>
      <Grid item xs={8} lg={3}>
        <center>
          <p>{label}:</p>
        </center>
      </Grid>
      <Grid item xs={4} lg={3}>
        {(editing && (
          <center>
            <InputField
              id={field}
              value={impostazioniTemp?.[field]}
              onChange={i =>
                setImpostazioniTemp({
                  ...impostazioniTemp,
                  [field]: type === 'number' ? +i : i,
                })
              }
              errorFunc={errorFunc}
              errorText={errorText}
              helperText=' '
              fullWidth={true}
              type={type}
              mode='update'
            />
          </center>
        )) || (
          <center>
            <b>
              {!!impostazioni?.[field] || impostazioni?.[field] === 0
                ? impostazioni?.[field]
                : '?'}
            </b>
          </center>
        )}
      </Grid>
    </>
  )

  const GridCheckbox = ({ editing, label, field }) => (
    <>
      <Grid item xs={8} lg={3}>
        <center>
          <p>{label}:</p>
        </center>
      </Grid>
      <Grid item xs={4} lg={3}>
        {(editing && (
          <center>
            <Checkbox
              id={field}
              checked={impostazioniTemp?.[field]}
              onChange={event =>
                setImpostazioniTemp({
                  ...impostazioniTemp,
                  [field]: event.target.checked,
                })
              }
              color='default'
            />
          </center>
        )) || (
          <center>
            <b>
              {(impostazioni?.[field] === true && 'SÌ') ||
                (impostazioni?.[field] === false && 'NO') ||
                '?'}
            </b>
          </center>
        )}
      </Grid>
    </>
  )

  const GridTransparentButton = ({ onClick, title }) => (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <center>
        <Button
          textbold=''
          textcolour={colours.black}
          backgroundcolour='transparent'
          hovercolour={colours.greyVeryLight}
          bordercolour={colours.grey}
          onClick={onClick}
        >
          {title}
        </Button>
      </center>
    </Grid>
  )

  const GridButtons = ({ editing, setEditing, completeInputs }) => (
    <Grid container item xs={12} lg={2} paddingX={1} alignItems='center'>
      {(editing && (
        <>
          <Grid item xs={12} lg={12}>
            <center>
              {(!!completeInputs && (
                <h4 style={{ color: colours.blueDark }}>
                  {phrases.phraseComplete}
                </h4>
              )) || (
                <h4 style={{ color: colours.red }}>
                  {phrases.phraseIncomplete}
                </h4>
              )}
            </center>
          </Grid>
          <Grid item xs={6} lg={12}>
            <center>
              <Button dark={1} onClick={handleClickOpenCheck}>
                Salva &nbsp;
                <SaveIcon />
              </Button>
            </center>
          </Grid>
          <Grid item xs={6} lg={12}>
            <center>
              <Button error={1} onClick={handleClickOpenUndo}>
                Annulla &nbsp;
                <ClearIcon />
              </Button>
            </center>
          </Grid>
        </>
      )) || (
        <Grid item xs={12} lg={12}>
          <center>
            <Button
              onClick={() => {
                setEditingGironi(setEditing === setEditingGironi)
                setEditingCalendari(setEditing === setEditingCalendari)
              }}
            >
              Modifica
            </Button>
          </center>
        </Grid>
      )}
    </Grid>
  )

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Impostazioni</h2>
        </center>
      </Grid>
      {((!impostazioni || !impostazioniTemp) && (
        <Grid item xs={12}>
          <center>
            <h4 style={{ color: colours.red }}>Impostazioni non trovate!</h4>
          </center>
        </Grid>
      )) || (
        <Grid container item xs={12} spacing={3}>
          <GridTransparentButton
            onClick={handleClickOpenCampionati}
            title={titles.titleCampionati}
          />
          <GridTransparentButton
            onClick={handleClickOpenCategorie}
            title={titles.titleCategorie}
          />
          <GridTransparentButton
            onClick={handleClickOpenAccoppiamenti}
            title={titles.titleAccoppiamenti}
          />
          <GridTransparentButton
            onClick={handleClickOpenZone}
            title={titles.titleZone}
          />
          <GridTransparentButton
            onClick={handleClickOpenDomeniche}
            title={titles.titleDomeniche}
          />
          <GridTransparentButton
            onClick={handleClickOpenTabelloni}
            title={titles.titleTabelloni}
          />
          <GridTransparentButton
            onClick={handleClickOpenTabellone}
            title={titles.titleTabellone}
          />
          <Grid container item xs={12}>
            <GridTitle title='Impostazioni algoritmo Gironi' />
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={12}
                lg={10}
                alignItems='center'
                paddingX={1}
              >
                {GridInputField(
                  editingGironi,
                  'Numero di tentativi',
                  'TENTATIVI',
                  errorNaturale,
                  'Il numero deve essere positivo!',
                  'number'
                )}
                <GridCheckbox
                  editing={editingGironi}
                  label='Separazione delle squadre della stessa società'
                  field='SeparateSocietyTeams'
                />
                {GridInputField(
                  editingGironi,
                  'Peso delle zone',
                  'WeightZone',
                  errorNaturale0,
                  'Il peso non può essere negativo!',
                  'number'
                )}
                {GridInputField(
                  editingGironi,
                  'Peso degli abbinamenti',
                  'WeightAbb',
                  errorNaturale0,
                  'Il peso non può essere negativo!',
                  'number'
                )}
                {GridInputField(
                  editingGironi,
                  'Peso delle alternanze',
                  'WeightAlt',
                  errorNaturale0,
                  'Il peso non può essere negativo!',
                  'number'
                )}
                {GridInputField(
                  editingGironi,
                  'Peso delle separazioni',
                  'WeightSep',
                  errorNaturale0,
                  'Il peso non può essere negativo!',
                  'number'
                )}
                {GridInputField(
                  editingGironi,
                  'Peso delle posizioni',
                  'WeightOrd',
                  errorNaturale0,
                  'Il peso non può essere negativo!',
                  'number'
                )}
                {GridInputField(
                  editingGironi,
                  'Peso dei gironi precedenti',
                  'WeightPre',
                  errorNaturale0,
                  'Il peso non può essere negativo!',
                  'number'
                )}
              </Grid>
              <GridButtons
                editing={editingGironi}
                setEditing={setEditingGironi}
                completeInputs={completeInputsGironi}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <GridTitle title='Impostazioni algoritmo Calendari' />
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={12}
                lg={10}
                alignItems='center'
                paddingX={1}
              >
                {GridInputField(
                  editingCalendari,
                  'Primo numero gara',
                  'PrimoNumeroGara',
                  errorNaturale,
                  'Il numero deve essere positivo!',
                  'number'
                )}
                {GridInputField(
                  editingCalendari,
                  'Primo numero gara Propaganda',
                  'PrimoNumeroGaraPropaganda',
                  errorNaturale,
                  'Il numero deve essere positivo!',
                  'number'
                )}
                <GridCheckbox
                  editing={editingCalendari}
                  label='Ordina cronologicamente'
                  field='OrdinaPartiteCronologicamentePerGiornata'
                />
              </Grid>
              <GridButtons
                editing={editingCalendari}
                setEditing={setEditingCalendari}
                completeInputs={completeInputsCalendari}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      &nbsp;
      <DialogMini
        open={openUndo}
        handleClose={handleCloseUndo}
        title={titles.titleUndo}
        textContent={phrases.phraseUndo}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseUndo}
        colourBackground={colours.red}
        colourHover={colours.redDark}
      />
      <DialogMini
        open={openCheck}
        handleClose={handleCloseCheck}
        title={titles.titleCheck}
        textContent={
          (!!completeInputs && phrases.phraseComplete) ||
          phrases.phraseIncomplete
        }
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmImpostazione}
      />
      <DialogMini
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title={
          (completeInputs && titles.titleComplete) || titles.titleIncomplete
        }
        textContent={
          (completeInputs && phrases.phraseCompleteSuccess) ||
          phrases.phraseIncompleteSuccess
        }
        textConfirm='Ok'
        triggerFunction={handleCloseSuccess}
      />
      <DialogMini
        open={openZone}
        handleClose={handleCloseZone}
        title={titles.titleZone}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseZone}
        dialogZone={true}
      />
      <DialogMini
        open={openDomeniche}
        handleClose={handleCloseDomeniche}
        title={titles.titleDomeniche}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseDomeniche}
        dialogDomeniche={true}
      />
      <DialogMini
        open={openAccoppiamenti}
        handleClose={handleCloseAccoppiamenti}
        title={titles.titleAccoppiamenti}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseAccoppiamenti}
        dialogAccoppiamenti={true}
      />
      <DialogMini
        open={openCampionati}
        handleClose={handleCloseCampionati}
        title={titles.titleCampionati}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseCampionati}
        dialogCampionati={true}
      />
      <DialogMini
        open={openCategorie}
        handleClose={handleCloseCategorie}
        title={titles.titleCategorie}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseCategorie}
        dialogCategorie={true}
      />
      <DialogMini
        open={openTabelloni}
        handleClose={handleCloseTabelloni}
        title={titles.titleTabelloni}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseTabelloni}
        dialogTabelloni={true}
      />
      <DialogMini
        open={openTabellone}
        handleClose={handleCloseTabellone}
        title={titles.titleTabellone}
        textUndo='Annulla'
        dialogTabellone={true}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  impostazioni: state.home.impostazioni,
})

const ConnectedImpostazioni = connect(mapStateToProps)(Impostazioni)

export default ConnectedImpostazioni
