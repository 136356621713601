import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import DatePicker from './DatePicker'
import { SET_RIMBORSO_DATE_PAID } from '../container/rimborsi/types'

const DialogRimborsoPagato = ({ rimborso, rimborsoDatePaid }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: SET_RIMBORSO_DATE_PAID, payload: rimborso?.datePaid })
  }, [dispatch, rimborso])

  return (
    (!rimborso && (
      <Grid item xs={12}>
        Nessun rimborso selezionato.
      </Grid>
    )) || (
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12}>
          Il presente modulo permette di aggiornare la data del pagamento del
          rimborso. Una volta pagato, il rimborso non potrà più essere
          modificato nè eliminato.
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <DatePicker
            label='Data pagamento'
            value={rimborsoDatePaid}
            onChange={i =>
              dispatch({ type: SET_RIMBORSO_DATE_PAID, payload: i })
            }
          />
        </Grid>
      </Grid>
    )
  )
}

const mapStateToProps = state => ({
  rimborso: state.rimborsi.rimborso,
  rimborsoDatePaid: state.rimborsi.rimborsoDatePaid,
})

const ConnectedDialogRimborsoPagato =
  connect(mapStateToProps)(DialogRimborsoPagato)

export default ConnectedDialogRimborsoPagato
