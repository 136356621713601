import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { sendRequest } from './http-hook'
import { useInterval } from './loop-hook'
import { fromStringsToDateTime } from './utils-functions'
import {
  SET_AFFILIAZIONI_LIGHT,
  SET_ISCRIZIONI_LIGHT,
  SET_ARBITRI_LIGHT,
  SET_IMPOSTAZIONI,
  SET_ZONE,
  SET_CAMPIONATI,
  SET_CATEGORIE,
  SET_BACKEND_R,
  SET_ERROR,
  SET_LOADING_SPOSTAMENTI,
  SET_LOADING_ARBITRI,
  SET_LOADING_DESIGNAZIONI,
  SET_LOADING_ARBITRAGGI,
  SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE,
  SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI,
  SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI,
  SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI,
  SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI,
  SET_SELECTED_CAMPIONATO_SPOSTAMENTI_GRATUITI,
} from '../container/home/types'
import { SET_ARBITRI } from '../container/arbitri/types'
import { SET_SPOSTAMENTI } from '../container/spostamenti/types'
import { SET_DESIGNAZIONI } from '../container/designazioni/types'
import { SET_ARBITRAGGI } from '../container/arbitraggi/types'

export const useFetch = (
  auth,
  admin,
  societa,
  arbitro,
  designatore,
  idArbitro,
  codiceUtente,
  campionati,
  selectedStagione,
  selectedCampionato,
  backendR,
  dummyUpdate
) => {
  const dispatch = useDispatch()

  const fetchArbitriAll = (admin || designatore) && codiceUtente // serve in "Arbitri", "Designazioni" e "Rimborsi" a utenti "admin" e "designatore"
  const fetchArbitriLight = arbitro && codiceUtente // serve in "DialogDesignazione" a utenti "arbitro"

  const campionato = campionati?.find(c => c.codice === selectedCampionato)

  dispatch({
    type: SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE,
    payload: !!campionato?.iscrizioniAperte,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI,
    payload: !!campionato?.gironiProvvisori,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI,
    payload: !!campionato?.gironiPubblicati,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI,
    payload: !!campionato?.calendariProvvisori,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI,
    payload: !!campionato?.calendariPubblicati,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_SPOSTAMENTI_GRATUITI,
    payload:
      fromStringsToDateTime(campionato?.termineSpostamentiGratuiti, '23:59') >
      new Date(),
  })

  const getRequest = useCallback(
    async url =>
      await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
    [codiceUtente]
  )

  // fetch affiliazioni light
  useEffect(() => {
    const fetchAffiliazioniLight = async () => {
      try {
        const affiliazioniData = await getRequest('affiliazioni/light')

        if (!affiliazioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le società "light".',
          })
        } else {
          dispatch({ type: SET_AFFILIAZIONI_LIGHT, payload: affiliazioniData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if ((admin || arbitro || designatore) && codiceUtente) {
      fetchAffiliazioniLight()
    } else {
      dispatch({ type: SET_AFFILIAZIONI_LIGHT })
    }
  }, [
    admin,
    arbitro,
    codiceUtente,
    designatore,
    dispatch,
    dummyUpdate,
    getRequest,
  ])

  // fetch iscrizioni light
  useEffect(() => {
    const fetchIscrizioniLight = async () => {
      try {
        const iscrizioniLightData = await sendRequest(
          `iscrizioni/campionato/${selectedCampionato}/light`
        )

        if (!iscrizioniLightData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le iscrizioni "light".',
          })
        } else {
          dispatch({ type: SET_ISCRIZIONI_LIGHT, payload: iscrizioniLightData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedCampionato) {
      fetchIscrizioniLight()
    } else {
      dispatch({ type: SET_ISCRIZIONI_LIGHT })
    }
  }, [dispatch, selectedCampionato])

  // fetch arbitri
  useEffect(() => {
    const fetchArbitri = async () => {
      dispatch({ type: SET_LOADING_ARBITRI, payload: true })
      try {
        const arbitriData = await getRequest('arbitri/all')

        if (!arbitriData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare gli arbitri.',
          })
        } else {
          dispatch({ type: SET_ARBITRI, payload: arbitriData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_ARBITRI })
    }
    if (fetchArbitriAll) {
      fetchArbitri()
    } else {
      dispatch({ type: SET_ARBITRI })
    }
  }, [codiceUtente, dispatch, dummyUpdate, fetchArbitriAll, getRequest])

  // fetch arbitri "light"
  useEffect(() => {
    const fetchArbitri = async () => {
      dispatch({ type: SET_LOADING_ARBITRI, payload: true })
      try {
        const arbitriLightData = await sendRequest('arbitri/light')

        if (!arbitriLightData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare gli arbitri "light".',
          })
        } else {
          dispatch({ type: SET_ARBITRI_LIGHT, payload: arbitriLightData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_ARBITRI })
    }
    if (fetchArbitriLight) {
      fetchArbitri()
    } else {
      dispatch({ type: SET_ARBITRI_LIGHT })
    }
  }, [dispatch, fetchArbitriLight])

  // fetch impostazioni
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest('impostazioni/all')

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le impostazioni.',
          })
        } else {
          dispatch({ type: SET_IMPOSTAZIONI, payload: data[0] })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (admin) fetch()
  }, [admin, dispatch, dummyUpdate])

  // fetch zone
  useEffect(() => {
    const fetchZone = async () => {
      try {
        const zoneData = await getRequest('zone/all')

        if (!zoneData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le zone.',
          })
        } else {
          dispatch({
            type: SET_ZONE,
            payload: zoneData?.sort((a, b) => (a.zona > b.zona ? 1 : -1)),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (admin && codiceUtente) {
      fetchZone()
    } else {
      dispatch({ type: SET_ZONE })
    }
  }, [admin, codiceUtente, dispatch, dummyUpdate, getRequest])

  // fetch campionati
  useEffect(() => {
    const fetchCampionati = async () => {
      try {
        const campionatiData = await sendRequest('campionati/all')

        if (!campionatiData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i campionati.',
          })
        } else {
          dispatch({
            type: SET_CAMPIONATI,
            payload: campionatiData?.sort((a, b) => b.indice - a.indice),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    fetchCampionati()
  }, [auth, dispatch, dummyUpdate])

  // fetch categorie
  useEffect(() => {
    const fetchCategorie = async () => {
      try {
        const categorieData = await sendRequest('categorie/all')

        if (!categorieData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le categorie.',
          })
        } else {
          dispatch({
            type: SET_CATEGORIE,
            payload: categorieData?.sort((a, b) => (a.nome > b.nome ? 1 : -1)),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    fetchCategorie()
  }, [auth, dispatch, dummyUpdate])

  // fetch spostamenti
  useEffect(() => {
    const fetchSpostamenti = async () => {
      dispatch({ type: SET_LOADING_SPOSTAMENTI, payload: true })
      try {
        const spostamentiData = await getRequest(
          `spostamenti/campionato/${selectedCampionato}${
            admin ? '' : `/societa/${codiceUtente}`
          }`
        )

        if (!spostamentiData) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'elenco degli spostamenti.",
          })
        } else {
          dispatch({
            type: SET_SPOSTAMENTI,
            payload: spostamentiData.reverse(),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_SPOSTAMENTI })
    }
    if ((admin || societa) && codiceUtente && selectedCampionato) {
      fetchSpostamenti()
    } else {
      dispatch({ type: SET_SPOSTAMENTI })
    }
  }, [
    admin,
    codiceUtente,
    dispatch,
    dummyUpdate,
    getRequest,
    selectedCampionato,
    societa,
  ])

  // fetch designazioni
  useEffect(() => {
    const fetchDesignazioni = async () => {
      dispatch({ type: SET_LOADING_DESIGNAZIONI, payload: true })
      try {
        const fStagione =
          selectedStagione !== 'Tutte' // "maximum update depth exceeded" with availableStagioni[0]
            ? `/stagione/${selectedStagione}`
            : ''
        const designazioniData = await getRequest(
          `designazioni${
            admin || designatore
              ? '/all' // get all to correctly check overlaps
              : `${fStagione}/arbitro/${idArbitro}`
          }`
        )

        if (!designazioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le designazioni.',
          })
        } else {
          dispatch({
            type: SET_DESIGNAZIONI,
            payload: designazioniData.reverse(),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_DESIGNAZIONI })
    }
    if (
      (admin || designatore || (arbitro && selectedStagione && idArbitro)) &&
      codiceUtente
    ) {
      fetchDesignazioni()
    } else {
      dispatch({ type: SET_DESIGNAZIONI })
    }
  }, [
    admin,
    arbitro,
    codiceUtente,
    designatore,
    dispatch,
    dummyUpdate,
    getRequest,
    idArbitro,
    selectedStagione,
  ])

  // fetch arbitraggi
  useEffect(() => {
    const fetchArbitraggi = async () => {
      dispatch({ type: SET_LOADING_ARBITRAGGI, payload: true })
      try {
        const arbitraggiData = await getRequest(
          `arbitraggi/campionato/${selectedCampionato}${
            admin || designatore ? '' : `/societa/${codiceUtente}`
          }`
        )

        if (!arbitraggiData) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'elenco degli arbitraggi.",
          })
        } else {
          dispatch({ type: SET_ARBITRAGGI, payload: arbitraggiData.reverse() })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_ARBITRAGGI })
    }
    if (
      (admin || societa || designatore) &&
      codiceUtente &&
      selectedCampionato
    ) {
      fetchArbitraggi()
    } else {
      dispatch({ type: SET_ARBITRAGGI })
    }
  }, [
    admin,
    codiceUtente,
    designatore,
    dispatch,
    dummyUpdate,
    getRequest,
    selectedCampionato,
    societa,
  ])

  // fetch backend R
  const fetchBackendR = async () => {
    try {
      const backendData = await getRequest('backendR/all')

      if (!backendData || !backendData.data) {
        dispatch({
          type: SET_ERROR,
          payload: 'Impossibile trovare il backend R.',
        })
      } else {
        // update "backendR" only if it has really changed, so to avoid
        // useless re-renders that damage the file upload input component
        if (
          !backendR ||
          Object.keys(backendData.data).some(
            k => backendData.data[k] !== backendR[k]
          )
        ) {
          dispatch({ type: SET_BACKEND_R, payload: backendData.data })
        }
      }
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
  }

  // loop backend R
  useInterval(fetchBackendR, backendR?.status === 'available' ? 10000 : 3000)
}
