import React from 'react'
import { CircularProgress, Grid } from '@mui/material'

const GridCircularProgress = ({ color, size }) => (
  <Grid item xs={12}>
    <center>
      <CircularProgress color={color} size={size} disableShrink={true} />
    </center>
  </Grid>
)

export default GridCircularProgress
